var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loader',{attrs:{"is-visible":_vm.isLoading}}),_c('div',{staticClass:"my-popups",staticStyle:{"line-height":"normal !important"}},[_c('div',{staticClass:"modal d-block "},[_c('div',{staticClass:"modal-dialog modal-dialog-centered ui-draggable"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header ui-draggable-handle  py-0"},[_c('div',{staticClass:"theme-primary partition-full"},[_c('span',{staticClass:"p-name text-white"},[_vm._v(_vm._s('Add ' + _vm.$t(_vm.submodulecode)))])]),_c('button',{staticClass:"close",attrs:{"type":"button"},on:{"click":function($event){return _vm.closepopup()}}},[_c('em',{staticClass:"fa fa-times",attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"border p-3 scroll w-100"},[(_vm.formdoesnotexsist == false)?_c('dynamic-form',{ref:"ManageForm",attrs:{"lang":"en","buttons":_vm.buttons,"schema":_vm.FormSchema},scopedSlots:_vm._u([{key:"tgslot-comment",fn:function(ref){
var data = ref.data;
return [_c('ckeditor',{class:{ 'is-invalid': data.error != '' },attrs:{"editor":_vm.editor},on:{"ready":_vm.onReady,"input":function($event){return _vm.updateCkeditorModel($event, data)}},model:{value:(data.fieldInfo.value),callback:function ($$v) {_vm.$set(data.fieldInfo, "value", $$v)},expression:"data.fieldInfo.value"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(data.error))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(data.fieldInfo.value),expression:"data.fieldInfo.value"}],attrs:{"type":"hidden"},domProps:{"value":(data.fieldInfo.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(data.fieldInfo, "value", $event.target.value)}}})]}},{key:"tgslot-unit_price",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"input-group"},[_c('span',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text",attrs:{"id":"spnOutOF"}},[_vm._v(_vm._s(_vm.Currency))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(data.fieldInfo.value),expression:"data.fieldInfo.value"}],staticClass:"form-control no-spinner",attrs:{"type":"number","name":"unitprice","min":"0","maxlength":5,"step":"any","pattern":"\\d+\\.?\\d?(?!\\d)"},domProps:{"value":(data.fieldInfo.value)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(data.fieldInfo, "value", $event.target.value)},_vm.limitLength],"keyup":function($event){return _vm.GetAssetCost($event, data.fieldInfo.value)}}})])]}},{key:"tgslot-asset_cost",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"input-group"},[_c('span',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text",attrs:{"id":"spnOutOF"}},[_vm._v(_vm._s(_vm.Currency))])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(data.fieldInfo.value),expression:"data.fieldInfo.value"}],staticClass:"form-control no-spinner",attrs:{"type":"number","min":0},domProps:{"value":(data.fieldInfo.value)},on:{"keyup":function($event){return _vm.GetUnitPrice(data.fieldInfo.value)},"input":function($event){if($event.target.composing){ return; }_vm.$set(data.fieldInfo, "value", $event.target.value)}}})])]}},{key:"tgslot-location_id",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"input-group custlocation"},[_c('tree-view',{class:{ 'form-control': true, },attrs:{"options":_vm.options,"placeholder":"Select Location","show-count":true},model:{value:(_vm.TreeValue),callback:function ($$v) {_vm.TreeValue=$$v},expression:"TreeValue"}}),(data.fieldInfo.config.showAddIcon)?_c('div',{staticClass:"ml-2 mt-1"},[_c('a',{staticClass:"round-icon-small btn-dark theme-primary",on:{"click":_vm.AddLocation}},[_c('i',{staticClass:"fa fa-plus text-white pt-7 font-18",attrs:{"alt":"+"}})])]):_vm._e()],1)]}}],null,false,902536046)}):_vm._e(),(_vm.formdoesnotexsist)?_c('div',{staticClass:"alert alert-danger py-0 text-center",attrs:{"role":"alert"}},[_vm._v(" Form does not exsist. ")]):_vm._e()],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }