<template>
    <div class="my-popups">
        <!-- The Modal -->
        <div class="modal time-track-slide cust_timer right fade show" id="DivTimerNew" style="display: block;"
            aria-modal="true" role="dialog">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content bg-white" id="DivTimerContent">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title text-white">{{ $t('Header_TimeTrack') }}</h4>
                        <!-- <button id="AddManually" title="Add Manually" type="button" class="addmanually_btn"><i
                                class="fa fa-clock-o m-0 " style="font-size:20px" aria-hidden="true"></i></button> -->
                        
                        <button id="closeTimer" type="button" style="background: none;color: #fff !important; right: 16px;"
                            class="close" @click="$emit('close')" data-dismiss="modal"><i class="fa fa-times"
                                aria-hidden="true"></i></button>
                    </div>
                    <!-- Modal body -->
                    
                    <div class="modal-body" id="divModalTimetrack">
                        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                        <form v-on:submit.prevent="handleSubmit(submit)" ref="form">
                            <div class="row defuse-navbar">
                                <div class="col-md-12 float-left p-0">
                                    <div class="listing">
                                        <div class="col-12 mb-2">
                                            <div class="mr-2 d-inline-block">
                                                <span class="legends_low colorboxsquare"></span>{{ $t('Working_hours') }}
                                            </div>
                                            <div class="mr-2 d-inline-block">
                                                <span class="legends_profile colorboxsquare"></span>{{ $t('Break') }}
                                            </div>
                                            <div class="mr-2 overlap-entry d-none">
                                                <span class="legends_Critical colorboxsquare"></span>{{ $t('OverlapEntry') }}
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-md-12 float-left">
                                        <div class="progress"> 
                                            <template v-for="item,Index in  percentShiftHours">
                                                
                                            <div :key="Index" class="progress-bar" role="progressbar"
                                                :style="'width:'+item.percentage+'%'" :class="item.type=='TimeTrack'? 'success':'danger' " :title="item.totalHours+'h'">

                                            </div>
                                            <div v-if="percentShiftHours.length == 0" class="progress-bar overtime" role="progressbar"
                                                style="width:0%" :title="item.totalHours+'h'"> 

                                            </div>
                                           
                                        </template>
                                             <div v-if="percentShiftHours.length == 0" class="progress-bar overtime" role="progressbar"
                                                style="width:0%"> 

                                            </div>
                                        </div>

                                        <div class="d-flex bd-highlight">
                                            <div class="p-2 flex-grow-1 bd-highlight">0h</div>
                                            <div class="p-2 bd-highlight" id="ShiftTime">{{  shiftHourShow }}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 float-left">
                                        <div class="form-group mt-4">
                                            <label class="typo__label">{{ $t('SelectProject') }}:</label>
                                            <multiselect :placeholder="$t('searchProject')" @input="taskByProject" v-model="trackerData.projectId"
                                                :options="getProjectsByUser" label="projectName" track-by="projectId" :show-labels="false">
                                                <span slot="noResult">{{ $t('No_Record_Found') }}</span>
                                            </multiselect>

                                        </div>
                                    </div>
                                    <div class="col-md-12 float-left">
                                        <div class="form-group">
                                            <label>{{ $t('SelectTask') }}</label>
                                            <div class="form-group tt_ddr" id="ddlTaskUnassigned" style="display: block;">
                                                <multiselect :placeholder="$t('searchTask')" v-model="trackerData.taskId" :options="getTasksFromProject"
                                                    label="firstAttribute" track-by="value" :show-labels="false" class="newchild_dropdown">
                                                    <template slot="option" slot-scope="props" :disabled="true">
                                            <!-- parentValue -->
                                            <div class="option__desc"><span class="option__title" :disabled="true" :class="props.option.parentValue > 0 ? 'child' : ''">{{
                                                props.option.firstAttribute }}</span>
                                                <label class="float-right text-light px-2 ml-1 taskcount" >{{
                                                    props.option.subTasksCount }}</label>
                                                <label class="float-right"> {{ props.option.thirdAttribute }}% </label>
                                            </div>
                                        </template>
                                        <span slot="noResult">{{ $t('No_Record_Found') }}</span>
                                                </multiselect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 float-left">
                                        <div class="col-md-6 col-sm-6 float-left p-0">
                                            <div class="form-group inline">
                                                <label>{{ $t('Billable') }}:</label>
                                                <div class="form-group">
                                                    <asp-checkbox-toggle><label class="switch">
                                                            <input type="checkbox" id="IsbillableN"
                                                                v-model="trackerData.isBillable" name="Isbillable"
                                                                class="dynamic custom-switch" @change="handleChange"><span class="slider round">
                                                                <span class="slider-yes">Yes</span><span
                                                                    class="slider-no">No</span></span></label>
                                                    </asp-checkbox-toggle>

                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-6 col-sm-6 float-left">
                                            <div class="form-group inline">
                                                <label>{{ $t('Hours24')}}r:</label>
                                                <div class="form-group">
                                                    <asp-checkbox-toggle><label class="switch"><input type="checkbox"
                                                                v-on:change="timeChange" v-model="trackerData.format"
                                                                id="chkHourFormatN" name="format"
                                                                class="dynamic custom-switch"><span
                                                                class="slider round"><span
                                                                    class="slider-yes">Yes</span><span
                                                                    class="slider-no">No</span></span></label></asp-checkbox-toggle>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>

                                    <div class="col-md-12 float-left">
                                        <label for="FormControlTextarea1">{{ $t('Description') }}</label>
                                        <div class="form-group">
                                            <ValidationProvider :rules="trackerData.projectId ? '' : 'required'" v-slot="{ errors }" class="tt_textarea_span position-relative  ">
                                            <textarea  id="Taskdescription" name="Description"
                                                v-model="trackerData.trackDescription"
                                                style="border-color: #BDBCBD; resize:none" rows="10" cols="2000"
                                                class="timertxtarea form-control" :class="errors[0] ? 'is-invalid' : ''" v-on:keydown="textCounter"
                                                v-on:keyup="textCounter" v-on:mouseout="textCounter"
                                                :placeholder="$t('PleaseEnterDescription')" maxlength="2000" @change="descriptionChange"></textarea>
                                                <span class="text-danger font-weight-bold">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                            <!-- <Field name="txtEmailId" :rules="{'required':true}" label="email"
                                            v-slot="{field,errorMessage, meta}" >
                                            <textarea
                                            id="Taskdescription" name="Description"
                                                v-model="trackerData.trackDescription"
                                                style="border-color: #BDBCBD; resize:none" rows="10" cols="2000"
                                                class="timertxtarea form-control tt_textarea_span position-relative" :class="errors[0] ? 'is-invalid' : ''" v-on:keydown="textCounter"
                                                v-on:keyup="textCounter" v-on:mouseout="textCounter"
                                                :placeholder="$t('PleaseEnterDescription')" maxlength="2000" v-bind="field">                                      
                                            </textarea>
                                            <span v-if="meta.touched && !meta.valid" name="txtEmailId"
                                                class="invalid-feedback d-block">{{errorMessage}}</span>
                                        </Field> -->

                                            <small>
                                                <strong>
                                                    <span class="alert-warning px-2 mr-2"
                                                        id="txtCount">{{ state.textCountertlength }}</span>
                                                </strong>
                                                {{ $t('CharactersLeft') }}
                                            </small>
                                        </div>
                                    </div>

                                    <div class="col-md-12 float-left">
                                        <div id="TimerCanves1" class="DivTimer working-timer project-timer">
                                            {{ getTimerClock }}
                                        </div>
                                    </div>

                                   


                                    <div class="col-md-12 float-left" v-if="store.getTimer.trackType">

                                        


                                        <div v-if="store.getTimer.statusCode=='ACT' && store.getTimer.enableBreak!='yes'" class="col-md-6 float-left pl-0" @click="timerUpdateInfo('pause',store.getTimer.trackType)">
                                            <a class="btn btn-info py-3 mt-2 font-18 w-100 font-18 text-uppercase text-white"
                                                id="btnPause1" style="cursor: pointer;" title=""
                                                data-toggle-tooltip="tooltip" data-original-title="Pause">Pause</a>
                                        </div>
                                        <div v-if="store.getTimer.statusCode=='PAUS' && store.getTimer.enableBreak!='yes'" class="col-md-6 float-left pl-0" @click="timerUpdateInfo('resume',store.getTimer.trackType)">
                                            <a class="btn btn-info py-3 mt-2 font-18 w-100 font-18 text-uppercase text-white"
                                                id="btnPause1" style="cursor: pointer;" title=""
                                                data-toggle-tooltip="tooltip" data-original-title="Pause">Resume</a>
                                        </div>
                                        <div v-if="store.getTimer.trackType!='Break'" :class="store.getTimer.enableBreak!='yes' ? 'col-md-6 float-left pr-0':'float-left pl-0 pr-0 col-md-12' " @click="timerUpdateInfo('stop',store.getTimer.trackType)">
                                            <a class="btn btn-danger py-3 font-18 text-uppercase mt-2 w-100 text-white btn-lg stoptimer"
                                                href="javascript:;" title="" id="btnStop1" data-toggle-tooltip="tooltip"
                                                data-original-title="Stop"><span>{{$t('Stop')}}</span></a>
                                        </div>
                                       
                                    </div>

                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3 float-left"  v-if="!store.getTimer.trackType">
                                        <button class="btn btn-primary-light font-18  text-uppercase text-white starttimer w-100"
                                            id="btnStart" style="cursor: pointer" data-toggle-tooltip="tooltip"
                                            data-original-title="Start"  type="submit">{{'Start'}}</button>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3 float-left"  v-if="store.getTimer.trackType=='TimeTrack'">
                                        <button class="btn btn-primary-light font-18  text-uppercase text-white starttimer py-3 w-100"
                                            id="btnStart" style="cursor: pointer" data-toggle-tooltip="tooltip"
                                            data-original-title="Start"  type="submit">{{  'Update'}}</button>
                                    </div>
                                    
                                    <div v-if="store.getTimer.trackType=='Break'" class="yourbreak position-absolute col-md-12 p-0">
                                    <div class="yourbreak-in">
                                        <div class="cup"></div>
                                        <div class="col-md-12 text-center mt-2"><h4 class="font-weight-bold">You are on Break</h4></div>
                                        <div class="col-md-12 text-center mt-2">Please click on <span class="text-danger font-weight-bold">"Stop Break"</span> to resume your work.</div>
                                    </div>
                                    <div class="yourbreak-down"></div>
                                </div>
                                </div>
                                <div class="col-md-12  choose-break" v-if="!state.breakTimerOn" :class="store.getTimer.enableBreak=='yes' ? '' : 'd-none'">
                                    <div class="" @click="state.breakTimerOn =!state.breakTimerOn">
                                        <a class="start-break-clr py-3 mt-2 font-18 d-block text-uppercase text-center text-white startbreak"
                                            id="ChooseBreak" title="" style="cursor: pointer" data-toggle-tooltip="tooltip"
                                            data-original-title="Start Break">
                                            <i class="fa fa-coffee pr-2"></i><span>{{$t('Break')}}</span>
                                        </a>
                                    </div>
                                </div>
                                <BreakTime v-if="state.breakTimerOn" v-on:breakTopTimerClose="breakTimeClose" v-bind:userId="getUserInformation.id" v-bind:trackDataPouse="trackDataPouse">
                                </BreakTime>
                            </div>
                        </form>
                    </ValidationObserver>
                    </div>
                    <!-- Modal Footer -->
                    <!-- <div class="modal-footer p-0" id="divModalFootertimetracked">
                        <a href="#" class="btn font-18 d-block w-100 py-3 px-0 m-0 text-uppercase text-white view-timesheet"
                            id="ViewTimesheet" title="" style="cursor: pointer" data-toggle-tooltip="tooltip"
                            data-original-title="View Timesheet">
                            <span>{{ $t('ViewTimesheetDetails') }}</span>
                        </a>
                    </div> -->
                </div>
            </div>
        </div>

        <!-- <div id="DivTimerEntryAttchment" class="modal">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title ml-5 text-white">Time Track</h4>
                        <input id="AddManuallyHidden" type="hidden" value="Add Manually">
                        <button type="button" data-dismiss="modal" class="close"><i aria-hidden="true"
                                class="fa fa-times"></i></button>
                    </div>
                    <div id="divModalTimetrack" class="modal-body">
                        <form>
                            <div class="row defuse-navbar">
                                <div class="col-md-12 p-0">
                                    <div class="heading-border actionbtn px-1">
                                        <h5 class="h5"><span>{{ $t('ATTACHMENT_PATH') }}</span></h5>
                                    </div>
                                </div>
                                <div class="col-12 mt-2">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-10 custom-file pr-0">
                                                <input type="file" name="FileSrc" id="flTimeFile" multiple="multiple"
                                                    class="form-control " tabindex="-1"
                                                    style="position: absolute; clip: rect(0px, 0px, 0px, 0px);">
                                                <div class="bootstrap-filestyle input-group"><input type="text"
                                                        class="form-control disabled" disabled="">
                                                    <div class="input-group-append" style="cursor:pointer;"><span
                                                            class="group-span-filestyle input-group-btn input-group-text bg-white"
                                                            tabindex="0">
                                                            <label title="Click here to select a file." for="flTimeFile"
                                                                class="mb-0 font-weight-normal ">Choose File</label></span>
                                                        <span class="input-group-text border-0 bg-transparent p-0 pl-2"><a
                                                                href="javascript:;"
                                                                class="round-icon-small btn-danger-light clsattclear"
                                                                title="Clear">
                                                                <i class="fa fa-undo"></i></a><a href="javascript:;"
                                                                class="round-icon-small btn-danger-light clsattremove d-none"
                                                                title="Remove"><i class="fa fa-times">

                                                                </i></a></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2 p-0">
                                                <a href="javascript:;" title="" id="btnTimerattachmentSave "
                                                    data-toggle-tooltip="tooltip" data-original-title="Add Attachment"
                                                    class="text-default round-icon-small btn-success-light"
                                                    style="margin-top: 3px;"><i class="fa fa-floppy-o"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="listing col-12">
                                    <div class="row custom-box-width"></div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div id="divModalFootertimetracked" class="modal-footer"></div>
                </div>
            </div>
        </div> -->
        <Loader :isVisible="getLoadingStatus"></Loader>
       
    
    </div>
</template>

<script>
import { ref, reactive, onMounted } from "@vue/composition-api";
import Multiselect from 'vue-multiselect'
import { userTimerInfo } from "../../stores/TimeTracker";
import {   storeToRefs } from "pinia";
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import Loader from "../Loader.vue"
import BreakTime from "./BreakTime.vue"
import moment from "moment";
import {  getSecondsFromDate ,dateTimeConverter,dateAndTimeZone,dateTimeFromat,timeToSeconds} from "@/assets/common/vue-common.js"
import {
    ValidationProvider,
    extend,
    ValidationObserver
} from 'vee-validate';
extend('required', {
    validate(value) {
        return {
            required: true,
            valid: ['', null, undefined].indexOf(value) === -1
        };
    },
    message: '{_field_} is required',
    computesRequired: true
});
export default {
    components: {
        Multiselect,
        VueTimepicker,
        Loader,
        BreakTime,
         ValidationProvider,
         ValidationObserver
    },
    props:{
        isEdit:{
    type:Boolean
},
trackDataPouse:{
    type:Object
}
    },
    setup(props,context) {
        const store = userTimerInfo();
        const state = ref({
            menualTime: false,
            breakTimer: "00:00:00",
            breakTimerOn: false,
            textCountertlength: 2000,
            submitted: false,
            startTimerShow: false,
            timer: "00:00:00",
            error:"",
            isEdit:false
        })
        const {
            getProjectDetails,
            getProjectsByUser,
            getTasksFromProject,
            getLoadingStatus,
            getUserInformation,
            getTimerRunResponse,
            getTimerClock,
            timerInfo,
            GetTrack
        } = storeToRefs(store)
 
        const trackerData = reactive({
            projectId: null,
            trackDescription: "",
            taskId: null,
            isBillable: false,
            format: false,
            date: null,
            trackId:0,
            startTime: dateAndTimeZone(moment(),dateTimeFromat),
            stopTime: dateAndTimeZone(moment(),dateTimeFromat),
            companyId:null,
            currentDate: dateAndTimeZone(moment(),dateTimeFromat),
            trackAgent: "web",
            timerStatus: "start",
            CreatedBy:getUserInformation.value.id,
            linkedTrackId:null,
            TotalHours: 0,
            timeformat: "12",
            TagIds: null,
            isForcedUpdate: null,
            type: "TimeTrack",
            BreakDate: null,
            BreakName: null,
            BreakId: 0,
            BreakStartTime: 0,
            Shift_ID: 0,
            SelectedDate: "",
            Break_Shift:""
        })
        const formData = ref({})
        Object.assign(formData.value,trackerData)
        onMounted(async() => {
            
            //trackerData.trackDescription =""
            state.value.isEdit = props.isEdit
            store.$state.tasks = []
            store.$state.timerRunResponse = ''
            state.value.error = ''
            //store.GetTrackHours()
            await store.getProjects()
            if(props.isEdit){
            Object.assign(trackerData, timerInfo.value)
            
            }
           
            if(timerInfo.value.projectId){   
                trackerData.projectId= timerInfo.value.projectId ? getProjectsByUser.value.find((data)=>data.projectId==timerInfo.value.projectId) :null
                console.log("checkk",trackerData.projectId)
                await store.loadTasks(timerInfo.value.projectId);
                trackerData.taskId= timerInfo.value.taskId ? getTasksFromProject.value.find((data)=>data.value==timerInfo.value.taskId) :null
            }else{
                trackerData.projectId = null
                trackerData.taskId =null
            }
            textCounter()
        })


        async function  submit()  {
            console.log("update")
            var vm = this;
            state.value.submitted = true
            
                    if(state.value.isEdit || timerInfo.value.trackType=="TimeTrack"){
                        
                        trackerData.timerStatus = "update"
                        trackerData.dateSet= dateTimeConverter(trackerData.dateSet)
                        trackerData.dateStart= dateTimeConverter(trackerData.dateStart)
                        trackerData.dateStop= dateTimeConverter(trackerData.dateStop)
                        trackerData.parentStartDate= dateTimeConverter(trackerData.parentStartDate)
                        trackerData.trackStartDate= dateTimeConverter(trackerData.trackStartDate)
                    }
                    trackerData.startTime= dateAndTimeZone(moment(),dateTimeFromat)
                    trackerData.stopTime= dateAndTimeZone(moment(),dateTimeFromat)
                    trackerData.projectId = trackerData?.projectId ?trackerData?.projectId?.projectId : 0
                    trackerData.taskId = trackerData?.taskId ? trackerData?.taskId?.value : 0
                    trackerData.isBillable = trackerData?.isBillable ? 1 : 0
                    trackerData.timeformat = trackerData?.format ? 24 : 12
                    trackerData.createdBy = getUserInformation.value.id
                    trackerData.totalHours = 0
                    //trackerData.Shift_ID = timerInfo.shift_ID
                    await store.submitProjectInfo(trackerData, this);
                    state.value.startTimerShow = state.value.startTimerShow;
                    if(getTimerRunResponse.value?.trackId!=0 && getTimerRunResponse.value?.trackId){
                        if(trackerData.timerStatus!="update"){
                        store.startTimer();
                        }
                        //breakTimer for timer and breack show in header
                        this.$emit("openTimer",{"breakTimer":false});
                    }else{
                        state.value.error =getTimerRunResponse.value?.userMsg;
                    }
                    await store.getTimerInfo();
                    vm.projectId()
                    vm.taskId()
                    // eslint-disable-next-line
                  
              
        }
        // Description text length counter
        function textCounter() {
            state.value.textCountertlength = trackerData.trackDescription ?  2000 - trackerData.trackDescription.length : 2000
        }

        function clearAll(props) {
            console.log(props);
        }

        function taskByProject() {
            store.$state.tasks = []
            trackerData.taskId = ''
            if (trackerData.projectId) {
                store.loadTasks(trackerData.projectId?.projectId)
            } else {
                //getTasksFromProject = []
                console.log("check", store.$state)
            }
            
        }

        return {
            trackerData,
            submit,
            textCounter,
            clearAll,
            getProjectsByUser,
            taskByProject,
            getTasksFromProject,
            getLoadingStatus,
            state,
            getTimerClock,
            getUserInformation,
            store,
            formData


        }
    },
    data(){
        return{
            percentShiftHours:[],
            shiftHourShow:"0h 0m"
        }
    },
    created(){
               this.fetchData()
    },
    methods:{
        async fetchData(){
            await this.store.getTimerInfo()
            this.getProgress()
               this.store.getBreakTime();
               if(!this.store.getTimer.statusCode){
                    this.store.stopTimer()
                    this.store.setTimerClock(0)
                    this.store.breakStopTimer()
                    this.store.breakSetTimerClock(0)
               }
               if (this.store.getTimer.trackType=='Break') {
                let seconds = getSecondsFromDate({
                        startDate: this.store.getTimer.dateStart,
                        endDate: dateAndTimeZone(moment(),dateTimeFromat)
                    }, 'seconds')
                this.store.$state.breakTimerCountInSec = timeToSeconds(this.store.getTimer.remainingBreakDuration) -seconds
                this.store.breakStartTimer()
                this.state.breakTimerOn = true
                this.$emit("openTimer",{"breakTimer":true});
                }
                if(this.store.getTimer.statusCode=='PAUS') {
                    this.store.stopTimer()
                }
                // if(this.store.getTimer.statusCode=='ACT'){
                //     this.store.$state.timerCountInSec = parseInt(this.store.getTimer.resumeHours)
                //     this.store.startTimer()
                // }
        },
        async getProgress(){
          await this.store.GetTrackHours()
        let shift =this.store.GetTrack.shiftHours ? this.store.GetTrack.shiftHours.split(".") : ''
        shift ? this.shiftHourShow =  shift[0]+'h '+shift[1]+'m' :''
         this.store.GetTrack.trackhours.map((item)=>{
        this.percentShiftHours.push({
            percentage:this.getPercentage(this.store.GetTrack.shiftHours,item.totalHours),
            type:item.trackType,
            totalHours:(item.totalHours / 3600 ).toFixed(2)
        })
     })

    },
    getPercentage(shiftTime,currentTime){
        let secondsInADay = 0
        if(shiftTime){
           const currentShift =shiftTime.split(".")
           const currentShiftHours = currentShift[0] * 60 * 60 
           const currentShiftMinuts = currentShift[1] * 60 
           //const currentShiftSeconds = parseInt(currentShift[2])
           secondsInADay = currentShiftHours+currentShiftMinuts
        }
    //  const now = new Date(currentTime);
    //  const hours = now.getHours() * 60 * 60;
    //  const minutes = now.getMinutes() * 60;
    //  const seconds = now.getSeconds();
     //const totalSeconds = hours + minutes + seconds;
     var percentSeconds = 100 * currentTime/secondsInADay;
     return percentSeconds
    },
       async timerUpdateInfo(status,type){
        this.trackerData.trackId = this.store.getTimer?.trackId
        this.trackerData.CreatedBy = this.store.getUserInformation.id
        this.trackerData.createdBy = this.store.getUserInformation.id
        this.trackerData.timerStatus = status,
        this.trackerData.type = type,
        this.trackerData.trackType = type
        this.trackerData.projectId = this.store.getTimer.projectId  ? this.store.getTimer.projectId : this.trackerData.projectId == null ? 0 : this.trackerData.projectId ? this.trackerData.projectId.projectId : this.trackerData.projectId
            this.trackerData.taskId = this.store.getTimer.taskId ? this.store.getTimer.taskId : this.trackerData.taskId == null ? 0 : this.trackerData.taskId ? this.trackerData.taskId.value : this.trackerData.taskId
            this.trackerData.stopTime = dateAndTimeZone(moment(),dateTimeFromat)
        if(status=='pause'&& type=='TimeTrack'){ 
            this.trackerData.totalHours = this.store.$state.timerCountInSec
            await this.store.submitProjectInfo(this.trackerData, this)
            if(this.store.getTimerRunResponse.status==0){
            await this.store.getTimerInfo();
            this.store.stopTimer()
            }

            
        }
        else if(status=='stop' && type=='TimeTrack'){
            this.trackerData.stopTime = dateAndTimeZone(moment(),dateTimeFromat)
            this.trackerData.totalHours = this.store.$state.timerCountInSec
            await this.store.submitProjectInfo(this.trackerData, this)
            if(this.store.getTimerRunResponse.status==0){
            await this.store.getTimerInfo();
            this.trackerData.trackDescription = ''
            this.store.stopTimer()
            this.store.$state.timerCountInSec = 0
            this.store.$state.timerClock = "00:00:00"
            this.$refs.form.reset()
            Object.assign(this.trackerData, this.formData)
            }
        }else if(status=='resume' && type=='TimeTrack'){
            this.trackerData.startTime = dateAndTimeZone(moment(),dateTimeFromat)
            this.trackerData.stopTime = dateAndTimeZone(moment(),dateTimeFromat)
            await this.store.submitProjectInfo(this.trackerData, this)
            if(this.store.getTimerRunResponse.status==0){
            await this.store.getTimerInfo();
            this.store.$state.timerCountInSec = parseInt(this.store.getTimer.resumeHours) 
            this.store.startTimer() 
            }
        }
        else if(type=='Break'){
            this.trackerData.BreakStartTime = this.store.getTimer.dateStart
            this.trackerData.BreakDate= this.store.getTimer.dateStart
            this.trackerData.BreakStopTime = dateAndTimeZone(moment(),dateTimeFromat)
            let seconds = getSecondsFromDate({
                startDate: this.store.getTimer?.dateStart,
                endDate:  dateAndTimeZone(moment(),dateTimeFromat)
            }, 'seconds')
            this.trackerData.totalHours = seconds
            await this.store.submitProjectInfo(this.trackerData, this)
            if(this.store.getTimerRunResponse.status==0){
            await this.store.getTimerInfo();
            this.store.breakStopTimer()
            if(this.store.timerInfo.statusCode=="PAUS" && this.store.timerInfo.trackType=='TimeTrack'){
              this.timerUpdateInfo('resume','TimeTrack')
            }
        }
        }
        this.projectId()
        this.taskId()
        
        
        },
        breakTimeClose(e){
            if(e=='resume'){
                this.timerUpdateInfo('resume','TimeTrack')
            }
            this.state.breakTimerOn = false

        },
         //If project id get number from backend then set custom object for multydropdown
         projectId() {
            this.trackerData.projectId = this.store.timerInfo.projectId ? this.store.getProjectsByUser.find((data) => data.projectId == this.store.timerInfo.projectId) : null
        },
        //If task id get number from backend then set custom object for multydropdown
        taskId() {
            this.trackerData.taskId = this.store.timerInfo.taskId ? this.store.getTasksFromProject.find((data) => data.value == this.store.timerInfo.taskId) : null
        },
        descriptionChange() {
            if(this.trackerData.trackDescription.match(/^\s*$/)){
                this.trackerData.trackDescription = ''
            }
        },
        handleChange() {
            var vm=this;
        // Show confirmation dialog when checkbox changes
        if (vm.trackerData.isBillable) {
            vm.confirmR(vm.$t("AreYouSureNonBillableTask"), true, (vm.$t("Delete")), function () {
                vm.trackerData.isBillable = true;
            },
        function(){
            vm.trackerData.isBillable = false;
        });
           
        }
    }
    },
    

}
</script>

