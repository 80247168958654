<template>
    <div class="my-popups">
        <!-- The Modal --> 
        <div class="modal d-block" id="choosewizards">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">Wizards</h4>
                        <button type="button" class="close" data-dismiss="modal" @click="$emit('close')"><i class="fa fa-times" aria-hidden="true"></i></button>
                    </div>
                    <!-- Modal body   -->
                    <div class="modal-body">
                        <section class="tab">
                            <div class="tab-style">
                                <div class="tab_container">
                                    <ul class="nav nav-tabs all-tabs responsive-tabs cust">
                                        <li class="nav-item">
                                            <a class="nav-link active " data-toggle="tab" href="#bname">By Name</a>
                                        </li>
                                        <li class="nav-item d-none">
                                            <a class="nav-link" data-toggle="tab" href="#bmodule">By Module</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="tab-content">
                                <div id="bname" class="active tab-pane mb-0">
                                    <div class="container-fluid  p-0">
                                        <div class="w-100 float-left">
                                            <div class="row">
                                                <div class="col-12 p-0">
                                                    <ul class="shortcuts-menu">                                                     
                                                        <li v-if="current_Url != '/User/Index' && IsHasPermission('User', 'add')" class="">
                                                            <a data-toggle="modal" id="adduserwizards" href="javascript:;" data-dismiss="modal" class="short-menu-item" @click="ShowAddWizard('User')"><span class="left-icon-m"><i class="fa fa-users" aria-hidden="true"></i></span> <span class="short-item-name">{{$t('Add')}} {{$t('User')}}</span></a>
                                                        </li>      
                                                        <li v-if="current_Url != '/Project/Index' && IsHasPermission('project', 'add')" class="">
                                                            <a data-toggle="modal" href="javascript:;" class="short-menu-item projectAddWizard" @click="ShowAddWizard('Project')"><span class="left-icon-m"><i class="fa fa-folder-open-o" aria-hidden="true"></i></span> <span class="short-item-name">{{$t('AddProject')}}</span></a>
                                                        </li>   
                                                        <li v-if="current_Url != '/CRM/ManageLead' && current_Url != '/CRM/Index' && IsHasPermission('CRM', 'LeadDetails')" class="">
                                                            <a data-toggle="modal" href="javascript:;" id="addleadwizards" class="short-menu-item leadAddWizard" @click="ShowAddWizard('Lead')"><span class="left-icon-m"><i class="fa fa-user" aria-hidden="true"></i></span> <span class="short-item-name">{{$t('Add')}} {{$t('Lead')}}</span></a>
                                                        </li>
                                                        <li v-if="current_Url != '/Ticketing/Add' && IsHasPermission('Ticketing', 'Add')" class="">
                                                            <a data-toggle="modal" href="javascript:;" id="addticketwizards" class="short-menu-item ticketAddWizard" @click="ShowAddWizard('Ticket')"><span class="left-icon-m"><i class="fa fa-ticket" aria-hidden="true"></i></span> <span class="short-item-name">{{$t('AddTicket')}}</span></a>
                                                        </li>
                                                        <li v-if='(((current_Url.toLowerCase() == "/assetCatalog/add") || (current_Url.toLowerCase().includes("assetcatalog/update")) || (current_Url.toLowerCase() == "/asset/add") || (current_Url.toLowerCase().includes("/asset/update"))) == false)' class="">
                                                            <a data-toggle="modal" href="javascript:;" id="addAssetswizards" class="short-menu-item AssetsAddWizard" @click="ShowAddWizard('Product')"><span class="left-icon-m"><i class="fa fa-bars" aria-hidden="true"></i></span> <span class="short-item-name">{{$t('AddProduct')}}</span></a>
                                                        </li>
                                                        <li class="">
                                                            <a data-toggle="modal" href="javascript:;" id="addCampaignswizards" class="short-menu-item AssetsAddWizard" @click="ShowAddWizard('Campaign')"><span class="left-icon-m"><i class="fa fa-bars" aria-hidden="true"></i></span> <span class="short-item-name">{{$t('Add')}} {{ $t('Campaign') }}</span></a>
                                                        </li>                                                        
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
 import DataService from '../../services/DataService'
export default{
    data() {
        return {
            current_Url: window.location.pathname
        };
    },
    created() {
        console.log(this.current_Url);
    },
    methods: {
        ShowAddWizard(wizardType) {
            //wizardType- User,Project,Lead,Ticket,Product
            const customEvent = new CustomEvent('event_addwizard', {detail: {wizardType: wizardType}})
            window.dispatchEvent(customEvent);            
        },
        IsHasPermission: async function (controller, action) {
            await DataService.CheckPrivilege(`controller=${controller}&action=${action}`)
                .then((value) => {
                return value.data;
            }, (error) => {
                console.log(error);
                return false;
            });
        }
    },
}
</script>