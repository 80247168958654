<script>
import { storeToRefs } from "pinia";
import HeaderUser from "./HeaderCompany.vue";
import HeaderCL from "./HeaderCL.vue";
import { useloggedUserInfo } from "../../stores/NavUserInfo";
// const { GetUserInfo } = storeToRefs(useloggedUserInfo)
const defaultAppURL = process.env.VUE_APP_COMMUNCATION_LINK;
export default {  
  name: 'TALYGENHeader',
  components: {
    HeaderUser,
    HeaderCL
  },
  data() {
        return {
          chat_btnHideShow:true,
          chat_btnArrow:false,
          chatpopupImage:'https://talygenv.github.io/Vue-UI-Content/images/chatpopup.jpg',
          defaultAppURLVar:defaultAppURL
        }
  },
  setup() {
    const store = useloggedUserInfo()
    const { GetUserInfo } = storeToRefs(store)
    return {
      GetUserInfo
    }
  },
  created: function() {
    if(this.GetUserInfo.MenuType == "Left" && document.getElementsByName("body").length > 0){
        document.getElementsByName("body")[0].classList.add("custom_tlocation  menu-left-wrapper");
    } 
  },
  methods:{
    HideShowChatbtn()
    {
      if(this.chat_btnHideShow==true)
      {
          this.chat_btnHideShow=false;
          this.chat_btnArrow=true;
      }
      else if(this.chat_btnHideShow==false)
      {
          this.chat_btnHideShow=true;
          this.chat_btnArrow=false;
      }
      var interval = setInterval(() => {
        clearInterval(interval); // Clear the interval after executing once
        this.chat_btnHideShow = true;
        this.chat_btnArrow = false;
    }, 5000);
    }
  }
}
</script>
<template>

  <div class="wrapper" style="min-height: auto" v-if="$route.path!='/Communication/Index' && $route.path.toLowerCase() !='/company/initialsetup'">
       <!-- for live comment the below css , for local, QA uncomment the below css-->
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.2/css/bootstrap.min.css" />
    <link rel="stylesheet" href="https://talygenv.github.io/Vue-UI-Content/css/custom.css" />
    <link rel="stylesheet" href="https://talygenv.github.io/Vue-UI-Content/css/tree.css" />
    <link rel="stylesheet" href="https://talygenv.github.io/Vue-UI-Content/css/DropZone.css" />
    <link rel="stylesheet" href="https://talygenv.github.io/Vue-UI-Content/css/custom_cli.css" />
    <div class="chat_popup" :class="{'chat-btn-hide': chat_btnHideShow}">
            <a href="javascript:;" v-on:click="HideShowChatbtn" class="cht-collapse"><i class="fa fa-arrow-circle-left" :class="{'arrow-rotate':chat_btnArrow}" id="arrow-rotate"></i></a>
            <a :href="defaultAppURLVar" target="_blank" id="chat_popup"><img :src="chatpopupImage"></a>
      </div>
    <header v-if="GetUserInfo != null">
      <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top switch-menu navbar px-lg-0" >
        <span class="text-white d-none"> {{ GetUserInfo.Name }}</span>
        <HeaderUser v-if="GetUserInfo.UserType == 'CA' || GetUserInfo.UserType == 'PM' || GetUserInfo.UserType == 'NU'" />
        <HeaderCL v-if="GetUserInfo.UserType == 'CL'" />
      </nav>
    </header>
  </div>
</template>

<style>
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.dropdown-menu-right {
    right: 0;
    left: auto !important;
}

</style>
