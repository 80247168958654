import Oidc from 'oidc-client';
import { useloggedUserInfo } from "../stores/NavUserInfo";
import http from "../http-common";
const envType = process.env.VUE_APP_NODE_ENV;
class authService  {
    mgr = null;
    /* Old Code 
    mgr = new Oidc.UserManager({
           // authority: 'https://devlogin.talygen.devlabs.com',
            //authority: 'https://stagelogin.talygen.com',
           // authority: 'https://usiclogin.talygen.com',
            authority: 'https://qatestlogin.talygen.com',
            //authority: 'https://localhost:44369/',
            client_id: 'talygenspa',
            //redirect_uri:'http://localhost:9000/Content/Callback.html',
           // redirect_uri:'http://stagespa.talygen.com/Content/Callback.html',
            redirect_uri:'https://qatestnewui.talygen.com/Content/Callback.html',
           // redirect_uri:'https://usictestnewui.talygen.com/Content/Callback.html',
            response_type: 'id_token token',
            scope: 'openid profile',
            //post_logout_redirect_uri: 'http://localhost:9000',
            //post_logout_redirect_uri: 'http://stagespa.talygen.com',
            //post_logout_redirect_uri: 'https://usictestnewui.talygen.com',
             post_logout_redirect_uri: 'https://qatestnewui.talygen.com',
            userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
            automaticSilentRenew: true,
           // silent_redirect_uri: 'http://localhost:9000/Content/silent-renew.html',
           // silent_redirect_uri: 'http://stagespa.talygen.com/Content/silent-renew.html',
            silent_redirect_uri: 'https://qatestnewui.talygen.com/Content/silent-renew.html',
            //silent_redirect_uri: 'https://usictestnewui.talygen.com/Content/silent-renew.html',
            accessTokenExpiringNotificationTime: 10    
    });
     */
    constructor(){
        if (envType=='development')
        {
            this.mgr = new Oidc.UserManager({
                authority: 'https://devlogin.talygen.devlabs.com',
                // authority: 'https://stagelogin.talygen.com',
                client_id: 'talygenspa',
                redirect_uri:'http://localhost:9000/Content/Callback.html',
                response_type: 'id_token token',
                scope: 'openid profile',
                post_logout_redirect_uri: 'http://localhost:9000',
                userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
                automaticSilentRenew: true,
                silent_redirect_uri: 'http://localhost:9000/Content/silent-renew.html',
                accessTokenExpiringNotificationTime: 10
            });
        }
        else if (envType=='stage'){
                this.mgr = new Oidc.UserManager({
                    authority: 'https://stagelogin.talygen.com',
                    client_id: 'talygenspa',
                    redirect_uri:'https://stagespa.talygen.com/Content/Callback.html',
                    response_type: 'id_token token',
                    scope: 'openid profile',
                    post_logout_redirect_uri: 'https://stagespa.talygen.com',
                    userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
                    automaticSilentRenew: true,
                    silent_redirect_uri: 'https://stagespa.talygen.com/Content/silent-renew.html',
                    accessTokenExpiringNotificationTime: 10
            });    
        }
        else if (envType=='qa'){
            this.mgr = new Oidc.UserManager({
                authority: 'https://qatestlogin.talygen.com',
                client_id: 'talygenspa',
                redirect_uri:'https://qatestnewui.talygen.com/Content/Callback.html',
                response_type: 'id_token token',
                scope: 'openid profile',
                post_logout_redirect_uri: 'https://qatestnewui.talygen.com',
                userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
                automaticSilentRenew: true,
                silent_redirect_uri: 'https://qatestnewui.talygen.com/Content/silent-renew.html',
                accessTokenExpiringNotificationTime: 10
            });
        }
        else if (envType=='usictest'){
            this.mgr = new Oidc.UserManager({
                authority: 'https://usictestlogin.talygen.com',
                client_id: 'talygenspa',
                redirect_uri:'https://usictestnewui.talygen.com/Content/Callback.html',
                response_type: 'id_token token',
                scope: 'openid profile',
                post_logout_redirect_uri: 'https://usictestnewui.talygen.com',
                userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
                automaticSilentRenew: true,
                silent_redirect_uri: 'https://usictestnewui.talygen.com/Content/silent-renew.html',
                accessTokenExpiringNotificationTime: 10
            });
        }
        else{
            this.mgr = new Oidc.UserManager({
                authority: 'https://applogin.talygen.com',
                client_id: 'talygenspa',
                redirect_uri:'https://appone.talygen.com/Content/Callback.html',
                response_type: 'id_token token',
                scope: 'openid profile',
                post_logout_redirect_uri: 'https://appone.talygen.com',
                userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
                automaticSilentRenew: true,
                silent_redirect_uri: 'https://appone.talygen.com/Content/silent-renew.html',
                accessTokenExpiringNotificationTime: 10
            });
        }
        var ths = this;
        // this.mgr.events.addUserSignedOut(function () {
        //     console.log('NAVBAR_addUserSignedOut', this.mgr)
        //     ths.mgr.signoutRedirect().then(function (resp) {
        //         store.commit('set_user', null); //clear user details in vuex
        //     }).catch(function (err) {
        //         console.log(err)
        //     })
        // });
    }   
    signIn (returnToUrl) {
        returnToUrl ? this.mgr.signinRedirect({ state: returnToUrl })
            : this.mgr.signinRedirect();
    }
    async authenticate(returnPath) {
        const user = await this.getUser(); //see if the user details are in local storage
        if (!!user) {
            await useloggedUserInfo().setUser(user);
        } else {
            await this.signIn(returnPath);
        }
    }
    async logOut(){
        try {
            this.mgr.signoutRedirect().then(function (resp) {
                store.commit('set_user', null); //clear user details in vuex
            }).catch(function (err) {
                console.log(err)
            })
            this.mgr.clearStaleState();
        }catch(error){
            console.log(error);
        }
    }
    async getUser() {
        try {
            return await this.mgr.getUser();
        } catch (err) {
            console.log(err);
        }
    }
    async CheckPrivilege(privilegeName, showalert, doredirect) {
        //return (privilegeName.toLocaleLowerCase().includes("deal"))?false: true;
        try{
        if (privilegeName == "" || privilegeName.length == 0) return true;
        let haspermission = true;
        //let data1 = http.instance.get(scriptUrl, { privilegeName: privilegeName });
        if (data1)
            if (!data1.hasPermission) {
                haspermission = false;
                 if (showalert) alert(data1.message);
            } else {
              //  if (doredirect) window.location = hrefLink;
                haspermission = true;
            }
        return haspermission;
        }catch(e){ return true}
    }

}

// Oidc.Log.logger = console;
// Oidc.Log.level = Oidc.Log.INFO;

// mgr.events.addUserLoaded(async function (user) {
//   await store.dispatch('refreshUserInfo');
//   await store.dispatch('ensureUserIsKnown');
// });

// mgr.events.addAccessTokenExpiring(function () {
//   // console.log('AccessToken Expiring：', arguments);
// });

// mgr.events.addAccessTokenExpired(function () {
//   mgr.signoutRedirect().then(function (resp) {
//     store.commit('set_user', null); //clear user details in vuex
//   }).catch(function (err) {
//     console.log(err)
//   })
// });

// mgr.events.addSilentRenewError(function () {
//   console.error('Silent Renew Error：', arguments);
// });

// mgr.events.addUserSignedOut(function () {
//   mgr.signoutRedirect().then(function (resp) {
//     store.commit('set_user', null); //clear user details in vuex
//   }).catch(function (err) {
//     console.log(err)
//   })
// });

// class SecurityService {  

//   constructor(){
//     // console.log('Creating SecurityService instance')   
//   }

//   async getUser () {
//     let self = this;
//     return new Promise((resolve, reject) => {
//       mgr.getUser().then(function (user) {
//         if (user == null) {
//           return resolve(null)
//         } else {
//           return resolve(user)
//         }
//       }).catch(function (err) {
//         console.log(err)
//         return reject(err)
//       });
//     })
//   }

//   signIn (returnToUrl) {
//     returnToUrl ? mgr.signinRedirect({ state: returnToUrl })
//         : mgr.signinRedirect();
//   }

//   signOut () {
//     var self = this;
//     mgr.signoutRedirect().then(function (resp) {      
//       // console.log('signed out', resp);
//     }).catch(function (err) {
//       console.log(err)
//     })
//   }
// }

// let service = new SecurityService(); 

// export {service as default, mgr};

export default new authService();