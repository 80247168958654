<template>

    <div>
        <loader :is-visible="isLoading"></loader>
        <div class="my-popups" style="line-height: normal !important;">
            <div class="modal d-block ">
                <div class="modal-dialog modal-dialog-centered ui-draggable">
                    <div class="modal-content">
                        <div class="modal-header ui-draggable-handle  py-0">
                            <div class="theme-primary partition-full">
                                <span class="p-name text-white">{{ 'Add ' + $t(submodulecode) }}</span>
                            </div>
                            <button type="button" class="close" @click=closepopup()>
                                <em aria-hidden="true" class="fa fa-times"></em>
                            </button>
                        </div>
                        <div class="border p-3 scroll w-100">
                            <!-- <Form ref="myForm"> -->
                            <dynamic-form lang="en" ref="ManageForm" :buttons="buttons" :schema="FormSchema"
                                v-if="formdoesnotexsist == false">
                                <template v-slot:tgslot-comment="{ data }">
                                    <ckeditor :editor="editor" @ready="onReady" v-model="data.fieldInfo.value"
                                        @input="updateCkeditorModel($event, data)"
                                        :class="{ 'is-invalid': data.error != '' }">
                                    </ckeditor>
                                    <span class="invalid-feedback">{{ data.error }}</span>
                                    <input type="hidden" v-model="data.fieldInfo.value" />
                                </template>
                                <!-- for Asset Add Product start-->
                                <template v-slot:tgslot-unit_price="{ data }">
                                    <!-- <Field :rules="{ 'required': false,'max': 5 }" class="w-100"  name="Unit Price" v-slot="{ errors }">                                  -->
                                    <div class="input-group">
                                        <span class="input-group-append">
                                            <span class="input-group-text" id="spnOutOF">{{ Currency }}</span>
                                        </span>
                                        <input type="number" name="unitprice" min="0" :maxlength="5" step="any"
                                            class="form-control no-spinner" v-on:input="limitLength"
                                            v-on:keyup="GetAssetCost($event, data.fieldInfo.value)"
                                            pattern="\d+\.?\d?(?!\d)" v-model="data.fieldInfo.value">

                                    </div>
                                    <!-- <span v-if="errors[0]" name="unitprice" class="invalid-feedback d-block">{{errors[0]}}</span>
                                    </Field> -->

                                </template>
                                <template v-slot:tgslot-asset_cost="{ data }">

                                    <div class="input-group">
                                        <span class="input-group-append">
                                            <span class="input-group-text" id="spnOutOF">{{ Currency }}</span>
                                        </span>
                                        <input type="number" :min="0" class="form-control no-spinner"
                                            v-on:keyup="GetUnitPrice(data.fieldInfo.value)"
                                            v-model="data.fieldInfo.value">

                                    </div>
                                </template>
                                <template v-slot:tgslot-location_id="{ data }">
                                    <div class="input-group custlocation">
                                        <!-- <Field name="Location" :rules="{ 'required': true }" v-slot="{ errors }">  'is-invalid': errors[0]-->
                                        <tree-view v-model="TreeValue" :options="options" placeholder="Select Location"
                                            :show-count="true" v-bind:class="{ 'form-control': true, }">
                                        </tree-view>
                                        <!-- <span v-if="errors[0]" name="Location" class="invalid-feedback d-block">{{ errors[0] }}</span> -->
                                        <!-- </Field> -->
                                        <div class="ml-2 mt-1" v-if="data.fieldInfo.config.showAddIcon">
                                            <a v-on:click="AddLocation" class="round-icon-small btn-dark theme-primary">
                                                <i alt="+" class="fa fa-plus text-white pt-7 font-18"></i>
                                            </a>
                                        </div>
                                    </div>
                                </template>

                                <!-- for Asset Add Product end -->
                            </dynamic-form>
                            <!-- <div v-if="formdoesnotexsist" class="text center danger ">Form doesnt exsist </div> -->

                            <div v-if="formdoesnotexsist" class="alert alert-danger py-0 text-center" role="alert">
                                Form does not exsist.
                            </div>
                            <!-- </Form> -->

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataService from '../../services/DataService';
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
// import EN from '../../localization/en';
// import Vue from 'vue'
// import VueI18n from "vue-i18n";
// Vue.use(VueI18n);
export default {
    components: {
        'tree-view': Treeselect
    },
    props: {
        getvalues: {
            type: Object,
            required: true
        },
        submodulename: {
            type: String,
            required: true
        }
    },
    // i18n: {
    //     locale: "en",
    //     messages: {
    //         en: EN,
    //     },
    // },
    data() {
        return {
            formdoesnotexsist: false,
            editor: this.CLASSIC_EDITOR,
            isLoading: false,
            // Id:this.$route.params.id,
            UserName: null,
            UserId: null,
            FormSchema: [],
            allowedPerFileSizeInMb:5,
            modulename: '',
            submodulecode: '',
            TreeValue: null,
            FindalLocationArray: [],
            DisallowedFileType:[],
            options: [],
            fileMulitpleData: [],
            Currency: '',
            buttons: [{
                type: "submit",
                class: "btn btn-success",
                text: "<i class='fa fa-save pr-2'></i>Save",
                onClick: this.Submit
            },
            {
                type: "button",
                class: "btn btn-danger",
                text: "<i class='fa fa-close pr-2'></i>Cancel",
                onClick: this.closepopup,
                isVisible: true,
            },
            ],
            acceptArray: []


        }
    },
    async created() {

        var vm = this;
        vm.UserId = vm.GetUserInfo.ID;
        vm.UserName = vm.GetUserInfo.Name;
        vm.Currency = this.GetUserInfo.Currency;
        if (vm.getvalues.module_name === "Asset") {
            await vm.GetLocationByCompanyId();
            await vm.GetAllowFileType();
        }

        vm.getForm(vm.getvalues);
    },
    methods: {

        async GetAllowFileType() {
            var vm = this;
            await DataService.GetAllowFileType().then((response) => {

                if (response.data != null) {
                    const AllowedFileTypes = response.data.AllowedFileTypes.split(',');

                    AllowedFileTypes.forEach(fileType => {
                        // Remove dots from file types
                        const fileTypeWithoutDot = fileType.trim().replace(/\./g, ''); // Remove dots and trim whitespace

                        vm.acceptArray.push({
                            fileType: fileTypeWithoutDot,
                            iconClass: 'fa-file-image-o'
                        });
                    });
                }

            });

        },
        closepopup() {
            var vm = this;
            vm.$parent.closewizardpopup();
        },
        getForm: function (values) {
            var vm = this;
            vm.isLoading = true;
            vm.modulename = values.module_name;
            vm.submodulecode = values.sub_module_code
            if(vm.modulename=="" &&  vm.submodulecode=="Ticketing"){
                vm.modulename= "Ticketing"
            }
            var param = `id=&moduleName=${vm.modulename}&subModuleCode=${vm.submodulecode}&otherData=&langCode=en&CustomLayoutId=&usefor=QuickAdd`;
            DataService.GetFormCustomLayout(param).then(function (response) {
                if (response != null && response.data != null && response.data != "") {
                    vm.formdoesnotexsist = false;
                    var TicketCategoryMandatory, TicketProductMandatory;
                    if ((typeof(response.data.configData) !=undefined ) && response.data.configData !=null && response.data.configData.length > 0) {
                        var configResult = response.data.configData;
                        configResult.forEach(function (row) {
                            if (row.CORE_CONFIG_KEY == "TicketFileSize") {
                                vm.allowedPerFileSizeInMb = row.CONFIG_DATA_VALUE;

                            }
                            if (row.CORE_CONFIG_KEY == "TicketCategoryOptionMandatory") {
                                TicketCategoryMandatory = row.CONFIG_DATA_VALUE;
                            }
                            if (row.CORE_CONFIG_KEY == "TicketProductOptionMandatory") {
                                TicketProductMandatory = row.CONFIG_DATA_VALUE;
                            }
                            if (row.CORE_CONFIG_KEY == "TicketDisallowedFileTypes") {
                                vm.DisallowedFileType = row.CONFIG_DATA_VALUE;

                            }

                        });
                    }
                    var frmSchma = vm.$refs.ManageForm.ConvertCustomFieldIntoDynamicFormObj(response.data.data);
                    frmSchma.forEach((group, gi) => {
                        group.Data.forEach((field, fi) => {                       
                                if (field.astype == 'RadioField') {
                                    var xname = '';
                                    var xvalue = '';
                                    field.config.options.forEach((x, i) => {
                                        if (i == 0) {
                                            xvalue = x.value
                                        }
                                    })
                                    field.value = xvalue;
                                }
                            if (field.astype == 'FileField' && (values.sub_module_code.toUpperCase() != 'MANAGE_PRODUCT') ) {
                                if (field && field.config) {
                                 field.config.maxSize = vm.allowedPerFileSizeInMb;
                                }
                                if (field && field.config) {

                                    let fileTypeList = vm.AttachmentDisallowedFileType(vm.DisallowedFileType);
                                    if (fileTypeList != null) {
                                        field.config =
                                        {
                                            maxSize: vm.allowedPerFileSizeInMb,
                                            reject: fileTypeList,
                                            showAddIcon: false,
                                            onChange: vm.GetBase64String,
                                            onAddButtonClick: '',
                                            multiple: true
                                        }
                                    }

                                }
                            }

                            if (values.sub_module_code == 'Ticketing') {
                                if (field.name == "is_read") {
                                    field.value = 0;
                                }
                                if (field.name == "status_id") {
                                    if (field.config.options.length > 0) {
                                        field.value = 1111;
                                    }
                                }
                                if (field.name == "ticket_for" && vm.$route.params.id == "0") {
                                    field.value = "1";
                                }
                                if (field.name == "ticket_category_id") {
                                    if (TicketCategoryMandatory == "yes") {
                                        field.validationRules.required = true;
                                    }
                                    else {
                                        field.validationRules.required = false;
                                    }

                                }

                                if (field.name == "product_id") {
                                    if (TicketProductMandatory == "yes") {
                                        field.validationRules.required = true;
                                    } else {
                                        field.validationRules.required = false;
                                    }
                                }
                            }
                            if (values.sub_module_code.toUpperCase() == 'MANAGE_PRODUCT') {

                                if (field.name == "deployement_type" || field.name == "location_id" || field.name == "quantity" || field.name == "unit_price" || field.name == "asset_cost" ) {
                                    field.visibility = false;
                                    group.visibility = false;
                                }
                                if (field.name == "quantity") {
                                    //field.is_required = true;
                                    //field.validationRules = { 'required': true, max: 7 }
                                    // if(item.name=='quantity'){
                                    //     field.is_required = true; 
                                    //     field.validationRules= {'required':item.is_required, max: item.length}
                                    // }
                                }
                                if (field.name == "unit_price") {
                                    field.astype = 'SlotField';
                                   // field.is_required = false;
                                   // field.validationRules = { 'required': false, max: 5 }
                                    // if(item.name=='unit_price'){
                                    //     field.validationRules= {'required':item.is_required, max: item.length}
                                    // }
                                }
                                if (field.name == "asset_cost") {
                                    field.astype = 'SlotField';
                                    //field.validationRules= {'required':item.is_required, max: item.length}         

                                    //field.validationRules= "max:5"
                                }
                                if (field.name == "location_id") {
                                    //field.config.showAddIcon = true;
                                    //field.config.onAddButtonClick = vm.AddLocation;                              
                                    field.astype = 'SlotField';
                                    // field.is_required = false;

                                }

                                if (field.name == "deployement_type") {
                                    ////debugger;
                                    field.value = "1";
                                    //field.display_order = 2;
                                }
                                if (field.name == "type") {
                                    ////debugger;
                                    field.value = false;
                                    //field.display_order = 1
                                }

                                if (field.name == "create_unique_name_list") {
                                    field.value = false;
                                    //field.required
                                }
                                if (field.name == "asset_type_id") {
                                    if (vm.FromPage != 'AdvanceSettings') {
                                        // field.config.showAddIcon = true;
                                        //  field.config.onAddButtonClick = vm.OpenAddProductTypePopup;
                                    }
                                }
                                if (field.name == "tag_id") {
                                    field.mode = "tag"
                                    field.is_required = false;
                                    // field.config.showAddIcon = true;
                                    //field.config.onAddButtonClick = vm.OpenAddTagPopup;
                                }
                                if (field.name == "type") {
                                    field.value = false
                                    field.is_required = true;
                                }
                                if (field.name == "status_id") {
                                    field.value = '1001'
                                    field.visibility = true;
                                }

                                if (field.name == "attachment_path") {
                                    field.showLabel = true;
                                    let fileTypeList = "";//vm.AttachmentDisallowedFileType(vm.AllowedFileType);
                                    if (fileTypeList != null) {
                                        //debugger;
                                        field.config =
                                        {
                                            maxSize: 5,
                                            //accept: fileTypeList,
                                            onChange: vm.GetBase64StringAsset,
                                            onDeleteSelectedFiles: vm.onDeleteSelectedFiles,
                                            accept: vm.acceptArray,

                                            // accept: [{
                                            //     fileType: 'jpg', // Image types
                                            //     iconClass: 'fa-file-image-o' // Icon class for images
                                            // },
                                            // {
                                            //     fileType: 'jpeg', // Image types
                                            //     iconClass: 'fa-file-image-o' // Icon class for images
                                            // },
                                            // {
                                            //     fileType: 'png', // Image types
                                            //     iconClass: 'fa-file-image-o' // Icon class for images
                                            // },
                                            //     // Add more image, document, text, and video types as needed
                                            // ],



                                            showAddIcon: false,
                                            onAddButtonClick: '',
                                            multiple: false
                                        }
                                    }

                                }
                               
                            }
                            if (values.sub_module_code.toUpperCase() == 'CRM_LEADS') {
                                if (field.name == 'lead_date' && field.value == '') {
                                    field.value = new Date();
                                    field.config = { mode: 'date', format: "M/D/YYYY" }
                                }
                                if (field.name == 'status_id' && field.value == '') {
                                    field.value = field.config.options.filter(x => x.name.toLowerCase() == 'active')[0].value;
                                }
                                if (field.name == 'expected_close_date') {
                                    field.config = { mode: 'date', format: "M/D/YYYY", minDate: new Date() }
                                }
                            }
                            if (values.sub_module_code.toUpperCase() == 'CRM_DEALS') {
                                if (field.name == 'closing_date' && field.value == '') {
                                    field.value = new Date();
                                    field.config = { mode: 'date', format: "M/D/YYYY" }
                                }
                            }

                            field.config.onChange = vm.onChangeEventForFields;
                        })

                    });
                    vm.FormSchema = frmSchma
                    vm.isLoading = false;
                }
                else {
                    vm.isLoading = false;
                    vm.formdoesnotexsist = true;
                }
            });
        },

        onChangeEventForFields: function (x, y, z) {
            let vm = this;
            if (y.name == "type") {
                vm.FormSchema.forEach((group, gi) => {
                    group.Data.forEach((field, fi) => {
                        if (y.value) {

                            if (field.name == "deployement_type" || field.name == "location_id" || field.name == "quantity" || field.name == "unit_price" || field.name == "asset_cost") {
                                field.visibility = true;
                                group.visibility = true;
                            }
                            if (field.name == "location_id")
                            {
                                field.is_required=true;    
                            }
                        } else {
                            if (field.name == "deployement_type" || field.name == "location_id" || field.name == "quantity" || field.name == "unit_price" || field.name == "asset_cost") {
                                field.visibility = false;
                                group.visibility = false;
                            }
                            if (field.name == "location_id")
                            {
                                field.is_required=false;    
                            }
                        }
                    });
                });
            }
            if (y.name == "attachment_path") {
                vm.GetBase64StringAsset(y.value);
            }
            else if (y.name == "attachment") 
            {
                vm.GetBase64String(y.value);
            }
            if (y.name == "quantity") {
            
                var Quantity = parseFloat(vm.FormSchema[2].Data[1].value == "" ? 0 : vm.FormSchema[2].Data[1].value);
            var unitPrice = parseFloat(vm.FormSchema[2].Data[2].value == "" ? 0 : vm.FormSchema[2].Data[2].value);
            //var assetCost= vm.FormSchema[2].Data[3].value;
            
                ////debugger;
                if (parseFloat(y.value) > 0 && unitPrice > 0) {
                    var assetCost = parseFloat(y.value) * parseFloat(unitPrice);
                    vm.FormSchema[2].Data[3].value = assetCost.toFixed(3);
                } else {
                    vm.FormSchema[2].Data[3].value = "0.000";
                }
            }
            if (y.name == "on_behalf") {
                    let type = (y.value == "3") ? "USERS" : "CLIENTS";
                    debugger;
                    var url = `id=&moduleName=TICKETING&type=${type}&search=&refId=`;
                    DataService.GetDDLData(url).then(function (response) {
                        if (response.data) {
                            var json = response.data;
                            if (json != null) {
                                var labelKey = (y.value == "3") ? "Select User" : "Select Client";
                                vm.$refs.ManageForm.updateProperty("on_behalf_consumer_id", "value", "");
                                vm.$refs.ManageForm.updateProperty("on_behalf_consumer_id", "config.options", response.data.DATA);
                                vm.$refs.ManageForm.updateProperty("on_behalf_consumer_id", "label", vm.$t(((y.value == "3") ? "User" : "Client")));
                                vm.$refs.ManageForm.updateProperty("on_behalf_consumer_id", "placeholder", labelKey);
                                vm.$refs.ManageForm.UpdateKeyValue();
                            }
                        }
                    });
                } 

        },
        GetBase64StringAsset: function (evt) {
            try {
                // debugger;
                var vm = this;
                var f = evt[0]; // FileList object
                // var f = evt.target.files[0]; // FileList object
                var reader = new FileReader();
                let fileObj = {};
                // Closure to capture the file information.
                reader.onload = (function (theFile) {
                    return function (e) {
                        var binaryData = e.target.result;
                        //Converting Binary Data to base 64
                        var base64StringEncoded = window.btoa(binaryData);
                        //showing file converted to base64
                        var fileName = f.name;
                        vm.fileName = fileName;
                        var n = fileName.lastIndexOf(".");
                        var extensionName = fileName.substring(n + 1, fileName.length);
                        vm.fileExt += "." + extensionName;
                        var attachemnetMime = '{"ContentType":"' + f.type + '","Size":"' + f.size + '","Extension":"' + extensionName + '"}';
                        var fileSize = f.size;
                        vm.fileSize = fileSize;

                        fileObj.attachement_mime = attachemnetMime,
                            fileObj.fileName = fileName,
                            fileObj.base64String = base64StringEncoded,
                            fileObj.fileSize = f.size,
                            fileObj.contentType = f.type,
                            fileObj.ModuleName = 'Asset',
                            fileObj.SubModuleCode = 'Manage_Products'
                        vm.fileMulitpleData.push(fileObj);
                    };
                })(f);
                // Read in the image file as a data URL.
                reader.readAsBinaryString(f);

            }
            catch (ex) {
            }
        },

        GetAssetCost(event, value) {
            debugger;
            var vm = this;
            var Quantity = parseFloat(vm.FormSchema[2].Data[1].value == "" ? 0 : vm.FormSchema[2].Data[1].value);
            var unitPrice = parseFloat(value);
            if (value.length > 5) {
                vm.FormSchema[2].Data[2].value = value.slice(0, 5);
                vm.ShowAlert(vm.$t("The Unit Price field may not be greater than 5 characters"), "failure", vm.$t('Alert'));
            }
            if (unitPrice > 0 && Quantity > 0) {
                var assetCost = parseFloat(unitPrice) * parseFloat(Quantity);
                vm.FormSchema[2].Data[3].value = assetCost.toFixed(3);
            } else {
                vm.FormSchema[2].Data[3].value = "0.000";
            }
        },
        GetUnitPrice(val) {
            debugger;
            var vm = this;
            var Quantity = parseFloat(vm.FormSchema[2].Data[1].value == "" ? 0 : vm.FormSchema[2].Data[1].value);
            var unitPrice = parseFloat(vm.FormSchema[2].Data[2].value == "" ? 0 : vm.FormSchema[2].Data[2].value);
            if (Quantity > 0 && unitPrice > 0) {
                var assetCost = parseFloat(unitPrice) * parseFloat(Quantity);
                vm.FormSchema[2].Data[3].value = assetCost.toFixed(3);
            } else {
                vm.FormSchema[2].Data[3].value = "0.000";
            }
        },

        Submit() {
            var vm = this;
            if (vm.getvalues.sub_module_code.toUpperCase() == 'MANAGE_PRODUCT') {
                vm.$refs.ManageForm.validateForm.validate().then(result => {
                if (result) {
                vm.isLoading = true;
                var CustomFieldArray = vm.$refs.ManageForm.ConvertDynamicFormObjToCustomFieldObj(vm.FormSchema);
                CustomFieldArray.forEach((item, index) => {
                    if (item.field_name == 'type') {
                        if (item.field_value == true) {
                            item.field_value = 1;
                        } else {
                            item.field_value = 2;
                        }
                    }
                    if (item.field_name == 'create_unique_name_list') {
                        if (item.field_value == true) {
                            item.field_value = 1;
                        } else {
                            item.field_value = 0;
                        }
                    }
                    if (item.field_name == 'location_id') {
                        item.field_value = vm.TreeValue == null ? 0 : vm.TreeValue;
                    }
                    if (item.field_name == 'unit_price') {
                        if (vm.FormSchema[2].Data[2].value > 0) {
                            item.field_value = vm.FormSchema[2].Data[2].value;
                        } else {
                            item.field_value = 0;
                        }
                    }
                    if (item.field_name == 'asset_cost') {
                        item.field_value = vm.FormSchema[2].Data[3].value;
                    }
                    if (item.field_name === 'tag_id') {
                        const tagIdArray = item.field_value;
                        tagIdArray.forEach(tagObject => {
                            tagObject.tagId = tagObject.value; // Assuming 'value' holds the ID
                        });
                        const tagIdString = tagIdArray.map(tagObject => tagObject.tagId).join(',');
                        item.field_value = tagIdString;
                    }
                })

                var obj = {
                    data: CustomFieldArray,
                    moduleCode: "Asset",
                    subModuleCode: "Manage_Product",
                    attachmentData: vm.fileMulitpleData.length > 0 ? vm.fileMulitpleData : null
                }
                //debugger;
                DataService.IsDuplicateAssetCatalog(JSON.stringify(obj)).then(function (response) {
                    //debugger;
                    if (response.data.isSave) {
                        let newItem = {
                            field_name: 'item_code',
                            field_value: response.data.itemCode
                        };
                        obj.data.push(newItem)
                        DataService.SaveDynamicForm(JSON.stringify(obj)).then(function (response) {
                            if (response != null && response.data != null && response.data[0].Status == "Success") {
                                vm.ShowAlert(vm.$t("Product has been Saved Successfully"), "success", vm.$t('Alert'));
                                // vm.ShowAlert(vm.$t("ProductSave", [vm.$t("Product")]), "success", true, vm.$t("Alert"));                            
                                vm.closepopup();
                                //     if(btnType.name=='SaveAndNew'){
                                //     vm.close();
                                //     setTimeout(() => {
                                //         if (vm.popupCondition == '') {
                                //             vm.$parent.OpenModelAddProduct();
                                //         } else {
                                //             vm.$parent.showadvancesettingpopup(vm.popupCondition);
                                //         }                                    
                                //     }, 400); 
                                //     vm.isLoading=false;                      
                                // }
                                vm.isLoading = false;
                            } else {
                                vm.ShowAlert(vm.$t("ErrorWhileSaving", [vm.$t("Product")]), "danger", true, vm.$t("Alert"));
                                if (vm.popupCondition == 'setup') {
                                    vm.$parent.ShowAddProduct = false;
                                } else {
                                    //vm.$parent.FetchData();
                                    //vm.getForm();
                                }
                                vm.isLoading = false;
                            }
                        })
                    }
                    else {
                        vm.ShowAlert((vm.$t("AlreadyExistRec")), "danger", true, vm.$t("Alert"));
                        vm.isLoading = false;
                    }
                });
                    }
                }); 
            }
            else {
                vm.$refs.ManageForm.validateForm.validate().then(result => {
                    if (result) {
                        vm.isLoading = true;
                        var CustomFieldArray = vm.$refs.ManageForm.ConvertDynamicFormObjToCustomFieldObj(vm.FormSchema, true);
                        var obj = {
                            data: CustomFieldArray,
                            moduleCode: vm.modulename,
                            subModuleCode: vm.submodulecode,
                            attachmentData: vm.fileMulitpleData.length > 0 ? vm.fileMulitpleData : null
                        }
                        DataService.SaveDynamicForm(JSON.stringify(obj)).then(function (response) {

                            if (response.data[0].Status == "Success") {
                                vm.ShowAlert(vm.$t(vm.submodulecode) + "  " + vm.$t('dynamicformsavemsg'), "success", true, vm.$t("Alert"));
                                vm.closepopup();
                            }
                        })
                    }
                    vm.isLoading = false;
                })
            }
        },
        updateCkeditorModel: function (e, x) {
            x.fieldInfo.value = e;
        },
        onReady(editor) {
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        GetLocationByCompanyId: async function () {
            var vm = this;
            var url = `reqForm=assetassignment`
            await DataService.GetLocationByuserId(url).then(response => {
                vm.FindalLocationArray = [];
                if ((typeof (vm.$route.params.reqForm) != 'undefined') && (vm.$route.params.reqForm != 'pendingRequest' || vm.GetParentDetails == '' || vm.GetParentDetails == null)) {
                    vm.TreeValue = response.data.data[0].COMPANY_LOCATION_ID;
                } else {
                    vm.TreeValue = null;
                }
                response.data.data.forEach((value) => {
                    var CheckIfExist = vm.FindalLocationArray.filter(function (el) {
                        return el.COMPANY_LOCATION_ID == value.COMPANY_LOCATION_ID;
                    });
                    if (CheckIfExist.length == 0) {
                        if (value.PARENT_LOCATION_ID == null) {
                            let locationId = value.COMPANY_LOCATION_ID
                            let rslt = vm.getChildLocation(locationId, value, response.data.data)
                            vm.FindalLocationArray.push(rslt)
                        }
                    }
                });
                vm.options = vm.FindalLocationArray;
            });
        },
        getChildLocation: function (locationId, currentItem, data) {
            let vm = this;
            let treeArray = {};
            treeArray = {
                id: currentItem.COMPANY_LOCATION_ID,
                label: currentItem.LOCATION_NAME
            }
            let retrunArray = treeArray;
            var childArray = data.filter(function (el) {
                return el.PARENT_LOCATION_ID == locationId;
            });
            let finalChildArray = [];
            childArray.forEach((value) => {
                let ChildfolderId = value.COMPANY_LOCATION_ID
                var CheckIfExist1 = finalChildArray.filter(function (el) {
                    return el.COMPANY_LOCATION_ID == value.COMPANY_LOCATION_ID;
                });
                if (CheckIfExist1.length == 0) {
                    var CheckIfExist = data.filter(function (el) {
                        return el.PARENT_LOCATION_ID == ChildfolderId;
                    });
                    if (CheckIfExist != null && CheckIfExist.length > 0) {
                        let rslt = vm.getChildLocation(ChildfolderId, value, data);
                        finalChildArray.push(rslt);
                    } else {
                        treeArray = {
                            id: `${value.COMPANY_LOCATION_ID}`,
                            label: `${value.LOCATION_NAME}`
                        }
                        finalChildArray.push(treeArray);
                    }
                }
            });
            retrunArray.children = finalChildArray;
            return retrunArray;
        },
        GetBase64String: function (evt) {
            debugger

            try {
                var vm = this;
                //var f = evt[0]; // FileList object
                var f = evt.target.files[0]; // FileList object
                var reader = new FileReader();
                let fileObj = {};
                // Closure to capture the file information.
                reader.onload = (function (theFile) {
                    return function (e) {
                        var binaryData = e.target.result;
                        //Converting Binary Data to base 64
                        var base64StringEncoded = window.btoa(binaryData);
                        //showing file converted to base64
                        var fileName = f.name;
                        vm.fileName = fileName;
                        var n = fileName.lastIndexOf(".");
                        var extensionName = fileName.substring(n + 1, fileName.length);
                        vm.fileExt += "." + extensionName;
                        var attachemnetMime = '{"ContentType":"' + f.type + '","Size":"' + f.size + '","Extension":"' + extensionName + '"}';
                        var fileSize = f.size;
                        vm.fileSize = fileSize;

                        fileObj.attachement_mime = attachemnetMime,
                            fileObj.fileName = fileName,
                            fileObj.base64String = base64StringEncoded,
                            fileObj.fileSize = f.size,
                            fileObj.contentType = f.type,
                            fileObj.ModuleName = 'TICKETING',
                            fileObj.SubModuleCode = 'TICKETING'
                        vm.fileMulitpleData.push(fileObj);
                    };
                })(f);
                // Read in the image file as a data URL.
                reader.readAsBinaryString(f);

            }
            catch (ex) {
            }
        },
        // onChangeEventForFields: function (x, y, z) {
        //     let vm = this;
        //     if (y.name == "type") {
        //         vm.FormSchema.forEach((group, gi) => {
        //             ////
        //             group.Data.forEach((field, fi) => {
        //                 ////
        //                 if (y.value) {
        //                     // if (group.group_name == "DEPLOY PRODUCT" || group.group_name == "DEPLOY PRODUCT DETAILS") {
        //                     //     group.visibility = true;
        //                     //     if (field.name == "deployement_type" || field.name == "location_id" || field.name == "quantity" || field.name == "unit_price" || field.name == "asset_cost") {
        //                     //         field.visibility = true;
        //                     //     }
        //                     // }
        //                     if (field.name == "deployement_type" || field.name == "location_id" || field.name == "quantity" || field.name == "unit_price" || field.name == "asset_cost") {
        //                         field.visibility = true;
        //                         group.visibility = true;
        //                     }
        //                 } else {
        //                     // if (group.group_name == "DEPLOY PRODUCT" || group.group_name == "DEPLOY PRODUCT DETAILS") {
        //                     //     if (group.group_name == "DEPLOY PRODUCT DETAILS") {
        //                     //         group.visibility = false;
        //                     //     }
        //                     //     if (field.name == "deployement_type" || field.name == "location_id" || field.name == "quantity" || field.name == "unit_price" || field.name == "asset_cost") {
        //                     //         field.visibility = false;
        //                     //     }
        //                     // }
        //                     if (field.name == "deployement_type" || field.name == "location_id" || field.name == "quantity" || field.name == "unit_price" || field.name == "asset_cost") {
        //                         field.visibility = false;
        //                         group.visibility = false;
        //                     }
        //                 }
        //             });
        //         });
        //     }
        //     if (y.name == "attachment") {

        //         vm.GetBase64StringAsset(y.value);
        //     }
        //     var Quantity = parseFloat(vm.FormSchema[2].Data[1].value == "" ? 0 : vm.FormSchema[2].Data[1].value);
        //     var unitPrice = parseFloat(vm.FormSchema[2].Data[2].value == "" ? 0 : vm.FormSchema[2].Data[2].value);
        //     //var assetCost= vm.FormSchema[2].Data[3].value;
        //     if (y.name == "quantity") {
        //         ////
        //         if (parseFloat(y.value) > 0 && unitPrice > 0) {
        //             var assetCost = parseFloat(y.value) * parseFloat(unitPrice);
        //             vm.FormSchema[2].Data[3].value = assetCost.toFixed(3);
        //         } else {
        //             vm.FormSchema[2].Data[3].value = "0.000";
        //         }
        //     }

        // },
    }
}
</script>
