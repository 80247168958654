<template>
    <div class="my-popups">
        <div class="modal d-block ">
            <div class="modal-dialog modal-dialog-centered ui-draggable">
                <div class="modal-content">
                    <div class="modal-header ui-draggable-handle">
                        <button type="button" class="close" @click=closepopup()>
                            <em aria-hidden="true" class="fa fa-times"></em>
                        </button>
                        <h4 name="header" class="modal-title">
                            {{ $t('Bookmark') }}
                        </h4>
                    </div>
                    <div class="modal-body">
                       <dynamic-form lang="en" :buttons="buttons" ref="bookmark"  :schema="FormSchema"
                         v-on:OnSubmit="onSubmit" >
                       </dynamic-form>

                       <div class="w-100 float-left mt-4" >
                          <div class="heading-border">
                          <h5 class="h5">
                            <span>Bookmark Listing</span>
                          </h5>
                          </div>
                       </div> 

                <div class="table-responsive table-fix-header mb-3">
                  <table id="example" class="table table-bordered mb-0 mt-0" style="width:100%"
                    aria-label="ShareUserRole listing">
                    <thead class="thead-bg">
                    <tr>
                        <!-- <th style="width:50px !important;"  v-on:change="onChangechk()"   class="text-center"><input
                            id="chkAll" class="chkitems" value="" type="checkbox"></th> -->
                        <th class="text-center" title="Url">
                            <span>Url</span>
                        </th>
                        <th class="text-center" title="Bookmark Title">
                            <span>Bookmark Title</span>
                        </th>
                        <th class="text-center" title="Created by">
                            <span>Created by</span>
                        </th>
                        <th class="text-center" titlr="created At">
                            <span>Created At</span>
                        </th>
                        <th class="text-center" title="Status">
                            <span>Status</span>
                        </th>
                        <!-- <th class="text-center">
                            <span>Edit</span>
                        </th> -->
                        <th class="text-center">
                            <span>Action</span> 
                        </th> 
                    </tr>
                </thead>
                    <tbody>
                      <tr v-for="(item, index) in bookmarklistingdata.data" :key="index"  >
                        
                        <td class="text-center" :title="item.url">
                            <span class="text-center">
                                {{ item.url == null ? '-' : item.url }}
                            </span>
                        </td>
                        <td class="text-center" :title="item.bookmark_title">
                            <span class="text-center">
                                {{ item.bookmark_title }}
                            </span>
                        </td>
                        <td class="text-center" :title="item.created_by">
                            <span class="text-center">
                                {{ item.created_by }}
                            </span>
                        </td>
                        <td class="text-center" :title="item.created_at">
                            <span class="text-center">
                                {{ $options.filters.formatDate(item.created_at) }}                            </span>
                        </td>
                        <td class="text-center" :title="item.status_id == 1001 ? 'Active' : 'InActive' ">
                            <span class="text-center">
                                {{  item.status_id == 1001 ? 'Active' : 'InActive' }}
                            </span>
                        </td>
                        <td class="text-center single-action">
                            <a href="#" @click="Deletebookmarkrecord(item.bookmark_id)">
                                <em class="fa fa-trash text-danger"></em>
                            </a>
                        </td>
                        <!-- <td class="text-right pr-3 single-action">
                          <a href="javascript:;" :title="$t('Delete')"   
                            v-on:click="shareroleListingDelete(item)">
                            <em class="fa fa-trash text-danger ml-4" v-if="TotalRecords>0"></em>
                          </a>
                        </td>  -->
                        
                      </tr>
                     <!-- <tr v-if="NoRecordfound==true">
                        <td class="text-center text-danger no-record">No Record(s) Found.</td>
                      </tr>  -->
                    </tbody>
                  </table>
                </div>
                <Pager v-bind:numberofrecords="TotalRecords" v-on:change="pageSizecommon" prev-text="Prev"
                       first-text="First" last-text="Last" next-text="Next" v-on:SetCurrentPage="pagerMethod">
                </Pager>
                </div>
            </div>
          </div>
        </div>
    </div>
</template>
<script>


// import Multiselect from  'vue-multiselect'
import Pager from '../../components/common/Pager.vue'

import DataService from '../../services/DataService';

export default {
components:{Pager},
    data() {
        return {
            FormSchema: [
            {
                    layoutType: "double",
                    Data: [
                        {
                            astype: "TextField",
                            label: 'Url',
                            name: "Url",
                            value: '',
                            placeholder: "",
                            config: {

                            },
                            disabled:true,
                            validationRules: "required",
                        },
                        {
                            astype: "TextField",
                            label: 'Title',
                            name: "Title",
                            value: '',
                            validationRules: { "required": true },
                            config: {
                               
                            },
                            validationRules: "required",
                        
                        },

                    ]
                }
            ],
            buttons: [{
            type: "submit",
            class: "btn btn-success",
            text: "<i class='fa fa-save pr-2'></i>Save",
            },
            {
            type: "button",
            class: "btn btn-danger",
            text: "<i class='fa fa-close pr-2'></i>Cancel",
            onClick: this.closepopup
            }],
            bookmarklistingdata:[],
            is_paged: 1,
            sortBy: null,
            sortExp: null,
            PageSize: 10,
            PageNumber: 1,
            TotalRecords: 0,
            }
    },
    created() {
        this.FormSchema[0].Data[0].value=window.location.href;
        this.Getbookmarklisting();
    },
    methods:{
        closepopup: function () {
           this.$parent.bookmark = false;
        },
        onSubmit : function () {
            var vm = this;
            var params =`bookmarkid=0&url=${vm.FormSchema[0].Data[0].value}&title=${vm.FormSchema[0].Data[1].value}`;
            DataService.AddBookMark(params).then(function (response) {
                if(response.data ==1){
                    vm.ShowAlert("bookmark has been save successfully", "success", true, vm.$t("Alert"));
                    vm.Getbookmarklisting();
                }
            })
        },
        Getbookmarklisting : function() {
            var vm = this;
            var params = `sortBy=${vm.sortBy}&sortExp=${vm.sortExp}&is_page=${vm.is_paged}&pageSize=${vm.PageSize}&pageNum=${vm.PageNumber}`;
            DataService.GetBookmarklisting(params).then(function (response) {
                if(response.data.length >0 || response.data != undefined ){
                    vm.bookmarklistingdata=JSON.parse(response.data[0])
                    vm.TotalRecords=vm.bookmarklistingdata.data[0].TOTAL_RECORDS;
                }
                
            })
        },
        pagerMethod: function (s) {     
            var vm = this;
            vm.PageSize = s.PageSize;
            vm.PageNumber=s.PageNumber;
            vm.Getbookmarklisting();
        },
        Deletebookmarkrecord: function (data) {
            var vm = this;
            if (data != null) {
                vm.confirmR(vm.$t("ConfirmDelete"), true, (vm.$t("Delete") + "  " + vm.$t("Records")), function () {
                    var postJSON = {
                        ids: data,
                        moduleName: "",
                        subModuleCode: "",
                        refCode: "DELETE_BOOKMARK_DATA"
                    };

                    var objectPostString = JSON.stringify(postJSON);
                    DataService.CommonCommonDelete(objectPostString).then(function (response) {
                        if (response.status == 200) {
                            $('.chkItems:checkbox:checked,#chkAll_0').prop("checked", false).removeAttr('checked');
                            $("#DeleteMultiple").addClass("disabled");
                         vm.ShowAlert(vm.$t('DeletedSuccess', [vm.$t('Record')]), "success", true, vm.$t("Alert"));
                         vm.Getbookmarklisting();
                        }
                        else {
                             vm.ShowAlert(vm.$t('DeletedError', [vm.$t('Record')]), "Success", true, vm.$t("Alert"));
                        }
                    },);
                });
            }
            else {
                vm.ShowAlert(vm.$t("Selectanyrecordtodelete"));
            }
           
        },
        
    }
}
</script>