<template>
    <div class="container-fluid p-0">
        <link rel="stylesheet" v-if="SelectedThemeType == 'Colored'"
        href="https://talygenv.github.io/Vue-UI-Content/css/colored.css" />
    <link rel="stylesheet" v-if="SelectedThemeType == 'Dark'"
        href="https://talygenv.github.io/Vue-UI-Content/css/dark.css" />
    <link rel="stylesheet" v-if="SelectedThemeType == 'Light'"
        href="https://talygenv.github.io/Vue-UI-Content/css/default.css" />
    <link rel="stylesheet"
        v-if="SelectedThemeType != 'Colored' && SelectedThemeType != 'Dark' && SelectedThemeType != 'Light'"
        href="https://talygenv.github.io/Vue-UI-Content/css/light.css" />
    <link rel="stylesheet" href='https://talygenv.github.io/Vue-UI-Content/css/flag-icon.min.css'/>
        <RouterLink to="" class="navbar-brand" checkPrivilege="Yes">
            <img :src="CompanyLogo" loading="lazy" alt="Image" id="imghdrCompanyLogo" class="img-fluid" />
        </RouterLink>
        <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
            data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul :class="'navbar-nav w-100 header-menu-for-left ' + (SelectedMenuType == 'Top'? 'd-none': '')">
                <!-- <li class="nav-item m-n-item ">
                    <a id="btnTourSampleData" @click="tourGuide(true)" class="nav-link" title="Tour Guide" href="javascript:;"><i class="fa fa-play m-0" aria-hidden="true"></i><span class="text-lmenu">@Resources.sampleData</span></a>
                </li> -->
                <!-- <li>
                    <a href="javascript:;" class="sidebar-toggle"><i class="fa fa-align-justify"></i></a>
                </li> -->
                <li class="myprof_newlogobase py-3">
                    <a class="navbar-brand ml-3 mr-1" href="javascript:;" checkprivilege="Yes">
                        <img :src="CompanyLogo" loading="lazy" id="imghdrCompanyLogo" class="img-fluid" alt="Logo">
                    </a>
                    <a href="javascript:;" class="sidebar-toggle mt-1">
                        <i class="fa fa-bars leftmenu_expand"></i>
                        <i class="leftmenu_collapse"></i>
                    </a>
                </li>
                <li class="nav-item  d-lg-none text-center leftsidebar left-menu-link mt-2">
                    <a class="nav-link unsetflex mb-4" href="javascript:;" id="navbarDropdownMenuLink" data-toggle="dropdown" data-bs-auto-close="outside"
                        aria-haspopup="true" aria-expanded="false" data-toggle-tooltip="tooltip"
                        data-original-title="My Profile">
                        <span class="my-image myimagemobile">
                            <img :src="Avatar" loading="lazy" alt="Image" onerror="this.src = '/Content/images/DefaultImages/avatar-icon.jpg'">
                        </span>
                        <span class="clearfix"></span>
                        <span class="myprof_new">
                            {{ GetUserInfo.Name.split(" ")[0] }} <i class="fa fa-angle-down font-16"></i><span class="clearfix"></span>
                            <small class="font-12">{{ (GetUserInfo.UserType == "CA"? $t("CompanyAdmin") : GetUserInfo.UserType == "PM" ? $t("ProjectManager") : GetUserInfo.UserType == "NU" ? $t("NormalUser") : GetUserInfo.UserType == "CL" ? $t("Client") : $t("NA")) }}</small>
                        </span>
                        <span class="clearfix"></span>
                        <!-- <span>{{ GetUserInfo.Name.split(" ")[0] }}</span> -->
                    </a>
                    <ul class="dropdown-menu dropdown-menu-right my-account" aria-labelledby="navbarDropdownMenuLink"
                        data-parent="#navbarDropdownMenuLink">
                        <li>
                            <a class="dropdown-item" :href="ProfileUrl" title="" data-toggle-tooltip="tooltip"
                                data-original-title="My Profile"><em class="fa fa-id-card"></em>{{ $t('myprofile') }}</a>
                        </li>
                        <li>
                            <input type="hidden" id="hdnUserLanguage" value="en">
                            <a class="dropdown-item dropdown-toggle" id="dropdownMenuButtonLanguage" data-toggle="dropdown"
                             aria-expanded="false" href="javascript:;"><em class="fa fa-globe"></em>{{Selectedlangvalue}}</a>
                            <ul id='selectedlang'  class="dropdown-menu selectlanguage" aria-labelledby="dropdownMenuButtonLanguage" style="height:150px !important; overflow-y: auto">
                                <li v-for="(lang, i) in Languages" :key="i + Math.random()">
                                    <a class="dropdown-item" href="javascript:;" @click="Updatemanudata('lang', lang)" :typefor="lang.LANGCODE">
                                        <span :class="'flag-icon flag-icon-squared mr-1 flag-icon-'+lang.LANG_ICON"></span>
                                        {{ lang.LANG_NAME }}
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a class="dropdown-item dropdown-toggle" id="dropdownMenuButtonMenuType" href="javascript:;"
                                data-toggle="dropdown" aria-expanded="false">
                                <em class="fa fa-bars"></em>{{$t('Menu')}} :<span id="spnMenuType">{{ SelectedMenuType }}</span>
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtonMenuType">
                                <li>
                                    <a class="dropdown-item sidebarCollapse" href="javascript:;"
                                        @click="Updatemanudata('menutype', 'T')">{{$t('Top')}}</a>
                                </li>
                                <li>
                                    <a class="dropdown-item sidebarCollapse" href="javascript:;"
                                        @click="Updatemanudata('menutype', 'L')">{{ $t('Left') }}</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a class="dropdown-item dropdown-toggle" href="javascript:;" id="dropdownMenuButtonTheme"
                                data-toggle="dropdown" aria-expanded="false">
                                <em class="fa fa-table"></em>Theme :
                                <span id="spnTheme" valuefor="CssGreen">{{ SelectedThemeType }}</span>
                            </a>
                            <ul class="dropdown-menu themeselect" aria-labelledby="dropdownMenuButtonTheme">
                                <li>
                                    <a class="dropdown-item" href="javascript:;" title=""
                                        @click="Updatemanudata('themetype', 'Colored')" valuefor="Colored"
                                        data-toggle-tooltip="tooltip" data-original-title="Colored">{{$t('Colored')}}</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="javascript:;" title=""
                                        @click="Updatemanudata('themetype', 'Dark')" valuefor="Dark"
                                        data-toggle-tooltip="tooltip" data-original-title="Dark">{{$t('Dark')}}</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="javascript:;" title=""
                                        @click="Updatemanudata('themetype', 'Light')" valuefor="Light"
                                        data-toggle-tooltip="tooltip" data-original-title="Light">{{$t('Light')}}</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="javascript:;" title=""
                                        @click="Updatemanudata('themetype', 'White')" valuefor="White"
                                        data-toggle-tooltip="tooltip" data-original-title="White">{{ $t('White')}}</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a class="dropdown-item" href="https://app.talygen.com/Notification/Index" title=""
                                data-toggle-tooltip="tooltip" data-original-title="Notifications"><em
                                    class="fa fa-bell"></em>{{ $t('Notifications')}}</a>
                        </li>
                        <li>
                            <a class="dropdown-item" href="https://app.talygen.com/Help/TalygenHelp" title=""
                                data-toggle-tooltip="tooltip" data-original-title="Help"><em
                                    class="fa fa-question"></em>{{$t('Help')}}</a>
                        </li>
                        <li>
                            <a class="dropdown-item" id="DashboardPolicy" href="javascript:;" title=""
                                data-toggle-tooltip="tooltip" data-original-title="Policy"><em
                                    class="fa fa-wpforms"></em>{{ $t('Policy') }}</a>
                        </li>
                        <li>
                            <!-- <RouterLink to="/logout" class="dropdown-item" checkPrivilege="Yes" data-original-title="Logout"><em class="fa fa-power-off"></em>Logout</RouterLink> -->
                            <a class="dropdown-item" id="DashboardPolicy" href="javascript:;" title=""
                                data-toggle-tooltip="tooltip" data-original-title="Policy" v-on:click="logoutFunc"><em
                                    class="fa fa-power-off"></em> {{$t('Logout')}}</a>
                        </li>
                    </ul>
                </li>
                <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle sidebaricon" title="" :href="ProfileUrl"
                        id="navbarDropdownMenuLink" data-toggle="dropdown" data-bs-auto-close="outside"
                        aria-haspopup="true" aria-expanded="false" data-toggle-tooltip="tooltip"
                        data-original-title="My Profile">
                        <span class="my-image d-none d-lg-block img-lmenu" style="line-height: normal">
                            <img :src="Avatar" alt="Image"
                                onerror="this.src = '/Content/images/DefaultImages/avatar-icon.jpg'" loading="lazy" />
                        </span>
                        <em class="fa fa-user i-lmenu"></em><span>{{ GetUserInfo.Name.split(" ")[0] }}</span>
                    </a>
                    
                </li> -->
                <li class="nav-item">
                    <RouterLink to="/User/Home" class="nav-link sidebaricon" checkPrivilege="Yes">
                        <em class="fa fa-home"></em>
                        <span>{{ $t("dashboard") }}</span>
                    </RouterLink>
                    <!-- <a class="nav-link sidebaricon" :href="'/User/Dashboard'" checkPrivilege="Yes">
                        <span>{{ $t("dashboard") }}</span>
                    </a> -->
                    <!-- defaultAppURLVar -->
                </li>
                <li class="nav-item dropdown position-unset">
                    <a class="nav-link dropdown-toggle sidebaricon position-relative" :title="$t('FullMenu')"
                        href="javascript:;" data-toggle="dropdown" id="navbarDropdownPortfolio" aria-haspopup="true"
                        aria-expanded="false">
                        <em class="fa fa-th-large i-lmenu"></em><span>{{ $t("FullMenu") }}</span>
                    </a>
                    <ul class="dropdown-menu my-account fullmenu-scroll">
                        <template v-for="(parent, parentIndex) in MenuDataGroupBy" >
                            <li v-for="(item, itemIndex) in parent.Menu" :key="parentIndex+ '_' + itemIndex + Math.random()"
                                :data-id="item.ModuleMasterId" :data-name="item.ModuleName"
                                :data-url="item.ShowDashboardLink == 0 ? '' : '/' + item.ControllerName + '/' + item.ActionName" class="menuitem dropdown-submenu">
                                <a :class="{ 'mainmenu menuitemsover cursor-pointer menudashboard openleftsubMenu': true }"
                                    href="javascript:;" :checkPrivilege="item.RoleApplicable"
                                    v-if="(item.ShowDashboardLink == 1)">
                                    <!-- <em :class="'fa ' + item.ModuleIcon" @click="GoToSubCategory($event, item.ModuleMasterId)"></em> -->
                                    {{ item.ModuleName }}
                                    <span class="fa  fa-angle-right" @click="GoToSubCategory($event, item.ModuleMasterId)"></span>
                                </a>
                                <a :class="{ 'mainmenu menuitemsover cursor-pointer menudashboard openleftsubMenu': true }"
                                    href="javascript:;" v-if="item.ShowDashboardLink == 0">
                                    <!-- <em :class="'fa ' + item.ModuleIcon" @click="GoToSubCategory($event, item.ModuleMasterId)"></em> -->
                                    {{ item.ModuleName }}
                                    <span class="fa  fa-angle-right" @click="GoToSubCategory($event, item.ModuleMasterId)"></span>
                                </a>
                                <ul :class="'fullmenu-submenu fullmenu-scroll submenu d-none w-100 clschild_' + item.ModuleMasterId" :data-p-id="item.ModuleMasterId" :data-p-name="item.ModuleName"
                                    :key="parentIndex + Math.random()">
                                    <template v-for="(url, urlIndex ) in item.UrlList">
                                        <template v-for="(urlItem, itemIndex) in url.URL">
                                            <li v-for="(uItem, itemIndex) in urlItem" :key="itemIndex + Math.random()" class="menuitem">
                                            <RouterLink :class="{ 'disabled': item.IS_DISABLED == 0 }"
                                                v-if="getRootLink(uItem) == 'CURRENT'"
                                                :to="'/' + uItem.CONTROLER_NAME + '/' + uItem.ACTION_NAME + '?reqFrom=' + item.ModuleMasterId">
                                                <em class="fa fa-circle"></em>{{ ($t(uItem.DISPLAY_NAME)) }}
                                            </RouterLink>
                                            <a v-else :class="{ 'disabled': uItem.IS_DISABLED == 0 }"
                                                :href="getRootLink(uItem) + '/' + uItem.CONTROLER_NAME + '/' + uItem.ACTION_NAME + '?reqhFrom=' + item.ModuleMasterId">
                                                <em class="fa fa-circle"></em>{{ ($t(uItem.DISPLAY_NAME)) }}
                                            </a>
                                        </li>
                                        </template>
                                    </template>
                                </ul>  
                            </li>
                        </template>
                    </ul>
                    <!-- <div v-bind:class="{ 'custom-width-menu': TotalCount <= 5 }"
                        class="dropdown-menu dropdown-menu-right full-menu-new overflow-visible mt-0" id="fullMenuUl"
                        aria-labelledby="navbarDropdownPortfolio" style="max-height:1000px;">
                        <div class="menu-search-panel">
                            <input type="text" name="search" v-model="searchMenu" @keyup="SeacrchMenuItem($event)"
                                class="menusearchitem" placeholder="Type to Search Menu items" />
                            <a v-if="searchMenu" class="clear-search" @click="handleCookEggs"
                                title="@Resources.Clear"><em class="fa fa-times"></em></a>
                        </div>
                        <div class="navigation-panel w-100 navigation-panel-full-menu">
                            <div class="nav-heading" style="display:none;">
                                <span><em class="fa fa-home cursor-pointer text-dark pr-2 ancbacktomenu"></em></span>
                                <span><em class="fa  fa-angle-right text-dark pr-2"></em></span>
                                <span id="spntext"><em class="fa fa-th-list d-none"></em></span>

                                <span class="back ml-auto"><span id="spnAdvancedSetup" v-if="UserType == 'CA'"><em
                                            class="fa fa-th-list d-none"></em> </span><a
                                        class="cursor-pointer ancbacktomenu" title="Back"><em
                                            class="fa fa-chevron-left"></em>{{ $t('Back') }}</a></span>
                            </div>
                            <perfect-scrollbar>
                                <div id="scrollbar" style="max-height:350px!important;">
                                    <template v-for="(parent, parentIndex1) in MenuDataList">
                                        <div class="w-100 float-left" :key="parentIndex1 + Math.random()">
                                            <template v-for="(url, urlIndex ) in parent.UrlList">
                                                <h4 :class="'hdrGroupName hdrGroupName_' + parent.ModuleMasterId + ' d-none'"
                                                    v-if="url.groupName != ''">{{ url.groupName }}</h4>
                                                <ul :class="'submenu d-none clschild_' + parent.ModuleMasterId"
                                                    :data-p-id="parent.ModuleMasterId" :data-p-name="parent.ModuleName"
                                                    v-for="(urlItem, urlItemIndex ) in url.URL"
                                                    :key="urlItemIndex + Math.random()">
                                                    <li v-for="(item, itemIndex) in urlItem"
                                                        :key="itemIndex + Math.random()" class="menuitem">
                                                        <RouterLink :class="{ 'disabled': item.IS_DISABLED == 0 }"
                                                            v-if="getRootLink(item) == 'CURRENT'"
                                                            :to="'/' + item.CONTROLER_NAME + '/' + item.ACTION_NAME + '?reqFrom=' + parent.ModuleMasterId">
                                                            <em class="fa fa-circle"></em>{{ ($t(item.DISPLAY_NAME)) }}
                                                        </RouterLink>
                                                        <a v-else :class="{ 'disabled': item.IS_DISABLED == 0 }"
                                                            :href="getRootLink(item) + '/' + item.CONTROLER_NAME + '/' + item.ACTION_NAME + '?reqhFrom=' + parent.ModuleMasterId">
                                                            <em class="fa fa-circle"></em>{{ ($t(item.DISPLAY_NAME)) }}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                            </perfect-scrollbar>
                        </div>
                    </div> -->
                </li>
                <li class="nav-item">
                    <RouterLink to="/User/Dashboard" class="nav-link sidebaricon" checkPrivilege="Yes">
                        <em class="fa fa-dashboard"></em>
                        <span>{{ $t("MyShortcuts") }}</span>
                    </RouterLink>
                    <!-- <a class="nav-link sidebaricon" :href="'/User/Dashboard'" checkPrivilege="Yes">
                        <span>{{ $t("dashboard") }}</span>
                    </a> -->
                    <!-- defaultAppURLVar -->
                </li>
                <li class="nav-item m-n-item " v-if="UserType == 'CA'">
                    <RouterLink class="nav-link" :to="'/AdvanceSetup/index'">
                        <em class="fa fa-cog"></em><span class="text-lmenu">{{ $t('AdvanceSetup') }}</span>
                    </RouterLink>
                </li>
                <li class="nav-item m-n-item ">
                    <a id="" @click="OpenIcon()" class="nav-link" href="javascript:;"><em class="fa fa-bolt m-0"
                            aria-hidden="true"></em><span class="text-lmenu">{{ $t('Quick Access') }}</span></a>

                    <FullMenuHeader v-if="IsHeaderShow" :MenuDataRecord="MenuDataRecord"></FullMenuHeader>
                </li>
                <!-- <li class="nav-item m-n-item ">
                    <a id="btnDeleteSampleData" @click="btnDeleteSample()" class="nav-link sampledata" title="Delete Sample Data" href="javascript:;"><i class="fa fa-trash m-0" aria-hidden="true"></i><span class="text-lmenu">@Resources.sampleData</span></a>
                </li> -->

                <!-- <li class="nav-item m-n-item wizardnew">
                    <a class="nav-link" data-toggle="modal" @click="showWizard=!showWizard" href="javascript:void(0)" title="Wizard"><span class="text-lmenu">{{$t('Wizard')}}</span></a>
                </li> -->
                <li class="nav-item m-n-item"
                    v-if="timerPermisssion == true && !store2.getTimer.trackType && !timeTrackPage">
                    <a class="nav-link" data-toggle="modal" @click="showTimetrackerModal = !showTimetrackerModal"
                        href="javascript:void(0)" :title="$t('Clock')"><em class="fa fa-clock-o m-0 "  aria-hidden="true"></em>
                        <span class="text-lmenu">{{ $t('Clock') }}</span></a>

                </li>

                <TimerStartOrStop v-else-if="timerPermisssion == true && !timeTrackPage && store2.getTimer.trackType"
                    v-on:startTimerClose="startTimer" v-bind:breakOrTimerOn="breakOrTimerOnEvent"></TimerStartOrStop>
                <li class="nav-item">
                    <a class="nav-link" :title="$t('FullScreen')" id="btnFullscreen" href="javascript:;"
                        @click="toggleFullscreen()">
                        <em class="zoom-screen" aria-hidden="true">
                            <img class="dark-full-screen" style="height: 1rem; width: 1rem" alt="Image"
                                src="/Content/images/iconfinder_fullscreen.png" />
                            <img class="light-full-screen" style="height: 1rem; width: 1rem; display: none" alt="Image"
                                src="/Content/images/iconfinder_fullscreen-light.png" />
                        </em>
                        <span class="text-lmenu">{{ $t('FullScreen') }}</span>
                    </a>
                </li>
                <!-- <li class="nav-item">
                    <a id="chat_popup" class="nav-link" :title="$t('Communication')" href="javascript:;">
                        <em class="fa fa-commenting-o actionicon_20">
                            <small id="popChatCount" class="notification_bell" style="display: none"></small>
                        </em><span class="bookmark text-lmenu">Communication</span>
                    </a>
                </li> -->
                <li class="nav-item">
                    <a id="popNotificationLink" class="nav-link" :title="$t('Notification')" href="javascript:;"
                        @click='slideToggleNotification($event)'>
                        <em class="fa fa-bell position-relative" style="font-size: 18px">
                            <small id="popNotificationCount"
                                class="noti-circle noti-green px-1 position-absolute d-none"
                                style="left: 20px; top: -4px"></small>
                        </em><span class="bookmark text-lmenu ml-1">{{ $t("Notification") }}</span>
                    </a>
                    <div id="divPopNotificationData" class="position-absolute border themecontrol d-none">
                        <Unreadnotification v-if="notifications" :notifications="notifications"></Unreadnotification>
                    </div>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link ancbookmark" :title="$t('Bookmark')" href="javascript:;" @click="OnShowBookMark">
                        <em class="fa fa-star"></em><span class="bookmark text-lmenu">Bookmark</span>
                    </a>
                </li> -->
                <li class="nav-item d-none">
                    <a class="nav-link" :title="$t('Socialize')" href="javascript:;">
                        <em class="fa fa-comments"></em>
                        <span class="socialize">{{$t('Socialize')}}</span>
                    </a>
                </li>
                
            </ul>
            <ul :class="'navbar-nav ml-auto header-menu-for-top ' + (SelectedMenuType == 'Left'? 'd-none': '')">
                <!-- <li class="nav-item m-n-item ">
                    <a id="btnTourSampleData" @click="tourGuide(true)" class="nav-link" title="Tour Guide" href="javascript:;"><i class="fa fa-play m-0" aria-hidden="true"></i><span class="text-lmenu">@Resources.sampleData</span></a>
                </li> -->
                <li>
                    <a href="javascript:;" class="sidebar-toggle"><i class="fa fa-align-justify"></i></a>
                </li>
                <li class="ifleftmenu">
                    <a class="navbar-brand" href="/" checkprivilege="Yes">
                        <img :src="CompanyLogo" loading="lazy" id="imghdrCompanyLogo" class="img-fluid" alt="Logo">
                    </a>
                </li>
                <li class="nav-item  d-lg-none text-center leftsidebar left-menu-link">
                    <a class="nav-link " href="/MyProfile/Index?companyid=270936">
                        <span class="my-image myimagemobile">
                            <img :src="Avatar" loading="lazy" alt="Image" onerror="this.src = '/Content/images/DefaultImages/avatar-icon.jpg'">
                        </span>
                        <span class="clearfix"></span>
                        <span>{{ GetUserInfo.Name.split(" ")[0] }}</span>
                    </a>
                </li>
                <li class="nav-item m-n-item " v-if="UserType == 'CA'">
                    <RouterLink class="nav-link" :to="'/AdvanceSetup/index'">
                        <em class="fa fa-cog"></em><span class="text-lmenu">{{ $t('AdvanceSetup') }}</span>
                    </RouterLink>
                </li>
                <li class="nav-item m-n-item ">
                    <a id="" @click="OpenIcon()" class="nav-link" href="javascript:;"><em class="fa fa-bolt m-0"
                            aria-hidden="true"></em><span class="text-lmenu">{{ $t('Quick Access') }}</span></a>

                    <FullMenuHeader v-if="IsHeaderShow" :MenuDataRecord="MenuDataRecord"></FullMenuHeader>
                </li>
                <!-- <li class="nav-item m-n-item ">
                    <a id="btnDeleteSampleData" @click="btnDeleteSample()" class="nav-link sampledata" title="Delete Sample Data" href="javascript:;"><i class="fa fa-trash m-0" aria-hidden="true"></i><span class="text-lmenu">@Resources.sampleData</span></a>
                </li> -->

                <!-- <li class="nav-item m-n-item wizardnew">
                    <a class="nav-link" data-toggle="modal" @click="showWizard=!showWizard" href="javascript:void(0)" title="Wizard"><span class="text-lmenu">{{$t('Wizard')}}</span></a>
                </li> -->
                <li class="nav-item m-n-item"
                    v-if="timerPermisssion == true && !store2.getTimer.trackType && !timeTrackPage">
                    <a class="nav-link" data-toggle="modal" @click="showTimetrackerModal = !showTimetrackerModal"
                        href="javascript:void(0)" :title="$t('Clock')"><em class="fa fa-clock-o m-0 "
                            style="font-size:20px" aria-hidden="true"></em><span
                            class="text-lmenu">{{ $t('Clock') }}</span></a>

                </li>

                <TimerStartOrStop v-else-if="timerPermisssion == true && !timeTrackPage && store2.getTimer.trackType"
                    v-on:startTimerClose="startTimer" v-bind:breakOrTimerOn="breakOrTimerOnEvent"></TimerStartOrStop>
                <li class="nav-item">
                    <a class="nav-link" :title="$t('FullScreen')" id="btnFullscreen" href="javascript:;"
                        @click="toggleFullscreen()">
                        <em class="zoom-screen" aria-hidden="true">
                            <img class="dark-full-screen" style="height: 1rem; width: 1rem" alt="Image"
                                src="/Content/images/iconfinder_fullscreen.png" />
                            <img class="light-full-screen" style="height: 1rem; width: 1rem; display: none" alt="Image"
                                src="/Content/images/iconfinder_fullscreen-light.png" />
                        </em>
                        <span class="text-lmenu">{{ $t('FullScreen') }}</span>
                    </a>
                </li>
                <!-- <li class="nav-item">
                    <a id="chat_popup" class="nav-link" :title="$t('Communication')" href="javascript:;">
                        <em class="fa fa-commenting-o actionicon_20">
                            <small id="popChatCount" class="notification_bell" style="display: none"></small>
                        </em><span class="bookmark text-lmenu">Communication</span>
                    </a>
                </li> -->
                <li class="nav-item">
                    <a id="popNotificationLink" class="nav-link" :title="$t('Notification')" href="javascript:;"
                        @click='slideToggleNotification($event)'>
                        <em class="fa fa-bell position-relative" style="font-size: 18px">
                            <small id="popNotificationCount"
                                class="noti-circle noti-green px-1 position-absolute d-none"
                                style="left: 20px; top: -4px"></small>
                        </em><span class="bookmark text-lmenu ml-1">{{ $t("Notification") }}</span>
                    </a>
                    <div id="divPopNotificationData" class="position-absolute border themecontrol d-none">
                        <Unreadnotification v-if="notifications" :notifications="notifications"></Unreadnotification>
                    </div>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link ancbookmark" :title="$t('Bookmark')" href="javascript:;" @click="OnShowBookMark">
                        <em class="fa fa-star"></em><span class="bookmark text-lmenu">Bookmark</span>
                    </a>
                </li> -->
                <li class="nav-item d-none">
                    <a class="nav-link" :title="$t('Socialize')" href="javascript:;">
                        <em class="fa fa-comments"></em>
                        <span class="socialize">{{$t('Socialize')}}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <RouterLink to="/User/Home" class="nav-link sidebaricon" checkPrivilege="Yes">
                        <em class="fa fa-dashboard"></em>
                        <span>{{ $t("dashboard") }}</span>
                    </RouterLink>
                    <!-- <a class="nav-link sidebaricon" :href="'/User/Dashboard'" checkPrivilege="Yes">
                        <span>{{ $t("dashboard") }}</span>
                    </a> -->
                    <!-- defaultAppURLVar -->
                </li>
                <li class="nav-item">
                    <RouterLink to="/User/Dashboard" class="nav-link sidebaricon" checkPrivilege="Yes">
                        <em class="fa fa-dashboard"></em>
                        <span>{{ $t("MyShortcuts") }}</span>
                    </RouterLink>
                    <!-- <a class="nav-link sidebaricon" :href="'/User/Dashboard'" checkPrivilege="Yes">
                        <span>{{ $t("dashboard") }}</span>
                    </a> -->
                    <!-- defaultAppURLVar -->
                </li>
                <li class="nav-item dropdown position-unset">
                    <a class="nav-link dropdown-toggle sidebaricon position-relative" :title="$t('FullMenu')"
                        href="javascript:;" data-toggle="dropdown" id="navbarDropdownPortfolio" aria-haspopup="true"
                        aria-expanded="false">
                        <em class="fa fa-th-large i-lmenu"></em><span>{{ $t("FullMenu") }}</span>
                    </a>
                    <div v-bind:class="{ 'custom-width-menu': TotalCount <= 5 }"
                        class="dropdown-menu dropdown-menu-right full-menu-new overflow-visible mt-0" id="fullMenuUl"
                        aria-labelledby="navbarDropdownPortfolio" style="max-height:1000px;">
                        <div class="menu-search-panel">
                            <input type="text" name="search" v-model="searchMenu" @keyup="SeacrchMenuItem($event)"
                                class="menusearchitem" placeholder="Type to Search Menu items" />
                            <a v-if="searchMenu" class="clear-search" @click="handleCookEggs"
                                title="@Resources.Clear"><em class="fa fa-times"></em></a>
                        </div>
                        <div class="navigation-panel w-100 navigation-panel-full-menu">
                            <div class="nav-heading" style="display:none;">
                                <span><em class="fa fa-home cursor-pointer text-dark pr-2 ancbacktomenu"></em></span>
                                <span><em class="fa  fa-angle-right text-dark pr-2"></em></span>
                                <span id="spntext"><em class="fa fa-th-list d-none"></em></span>

                                <span class="back ml-auto"><span id="spnAdvancedSetup" v-if="UserType == 'CA'"><em
                                            class="fa fa-th-list d-none"></em> </span><a
                                        class="cursor-pointer ancbacktomenu" title="Back"><em
                                            class="fa fa-chevron-left"></em>{{ $t('Back') }}</a></span>
                            </div>
                            <perfect-scrollbar>
                                <div id="scrollbar" style="max-height:350px!important;">
                                    <ul v-for="(parent, parentIndex) in MenuDataGroupBy"
                                        :key="parentIndex + Math.random()">
                                        <li v-for="(item, itemIndex) in parent.Menu" :key="itemIndex + Math.random()"
                                            :data-id="item.ModuleMasterId" :data-name="item.ModuleName"
                                            :data-url="item.ShowDashboardLink == 0 ? '' : '/' + item.ControllerName + '/' + item.ActionName">
                                            <a :class="{ 'mainmenu menuitemsover cursor-pointer menudashboard': true }"
                                                href="javascript:;" :checkPrivilege="item.RoleApplicable"
                                                v-if="(item.ShowDashboardLink == 1)">
                                                <em :class="'fa ' + item.ModuleIcon" @click="GoToSubCategory($event, item.ModuleMasterId)"></em>{{ item.ModuleName }}
                                            </a>
                                            <a :class="{ 'mainmenu menuitemsover cursor-pointer menudashboard': true }"
                                                href="javascript:;" v-if="item.ShowDashboardLink == 0">
                                                <em :class="'fa ' + item.ModuleIcon" @click="GoToSubCategory($event, item.ModuleMasterId)"></em>{{ item.ModuleName }}
                                            </a>
                                            <span class="fa  fa-angle-right gotosub"
                                                @click="GoToSubCategory($event, item.ModuleMasterId)"></span>
                                        </li>
                                    </ul>
                                    <template v-for="(parent, parentIndex1) in MenuDataList">
                                        <div class="w-100 float-left" :key="parentIndex1 + Math.random()">
                                            <template v-for="(url, urlIndex ) in parent.UrlList">
                                                <h4 :class="'hdrGroupName hdrGroupName_' + parent.ModuleMasterId + ' d-none'"
                                                    v-if="url.groupName != ''">{{ url.groupName }}</h4>
                                                <ul :class="'submenu d-none clschild_' + parent.ModuleMasterId"
                                                    :data-p-id="parent.ModuleMasterId" :data-p-name="parent.ModuleName"
                                                    v-for="(urlItem, urlItemIndex ) in url.URL"
                                                    :key="urlItemIndex + Math.random()">
                                                    <li v-for="(item, itemIndex) in urlItem"
                                                        :key="itemIndex + Math.random()" class="menuitem">
                                                        <RouterLink :class="{ 'disabled': item.IS_DISABLED == 0 }"
                                                            v-if="getRootLink(item) == 'CURRENT'"
                                                            :to="'/' + item.CONTROLER_NAME + '/' + item.ACTION_NAME + '?reqFrom=' + parent.ModuleMasterId">
                                                            <em class="fa fa-circle"></em>{{ ($t(item.DISPLAY_NAME)) }}
                                                        </RouterLink>
                                                        <a v-else :class="{ 'disabled': item.IS_DISABLED == 0 }"
                                                            :href="getRootLink(item) + '/' + item.CONTROLER_NAME + '/' + item.ACTION_NAME + '?reqhFrom=' + parent.ModuleMasterId">
                                                            <em class="fa fa-circle"></em>{{ ($t(item.DISPLAY_NAME)) }}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </template>
                                        </div>
                                    </template>
                                    <!-- <div class="w-100 float-left" v-for="(parent,parentIndex1) in MenuDataList" :key="parentIndex1+ Math.random()">
                                        <ul :class="'submenu d-none clschild_'+parent.ModuleMasterId" :data-p-id="parent.ModuleMasterId" :data-p-name="parent.ModuleName" v-for="(url,urlIndex ) in parent.UrlList" :key="urlIndex + Math.random()">
                                            <li v-for="(item,itemIndex) in url.URL" :key="itemIndex + Math.random()" class="menuitem">
                                                <RouterLink :class="{'disabled': item.IS_DISABLED == 0}" v-if="getRootLink(item) == 'CURRENT'" :to="'/'+item.CONTROLER_NAME+'/'+item.ACTION_NAME+'?reqFrom='+parent.ModuleMasterId">
                                                    <em class="fa fa-circle"></em>{{($t(item.DISPLAY_NAME))}}
                                                </RouterLink>
                                                <a v-else  :class="{'disabled': item.IS_DISABLED == 0}" :href="getRootLink(item) + '/' + item.CONTROLER_NAME+'/'+item.ACTION_NAME+'?reqhFrom='+parent.ModuleMasterId">
                                                    <em class="fa fa-circle"></em>{{($t(item.DISPLAY_NAME))}}
                                                </a>
                                            </li>
                                        </ul>
                                    </div> -->
                                </div>
                            </perfect-scrollbar>
                        </div>
                    </div>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle sidebaricon" title="" :href="ProfileUrl"
                        id="navbarDropdownMenuLink" data-toggle="dropdown" data-bs-auto-close="outside"
                        aria-haspopup="true" aria-expanded="false" data-toggle-tooltip="tooltip"
                        data-original-title="My Profile">
                        <span class="my-image d-none d-lg-block img-lmenu" style="line-height: normal">
                            <img :src="Avatar" alt="Image"
                                onerror="this.src = '/Content/images/DefaultImages/avatar-icon.jpg'" loading="lazy" />
                        </span>
                        <em class="fa fa-user i-lmenu"></em><span>{{ GetUserInfo.Name.split(" ")[0] }}</span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-right my-account" aria-labelledby="navbarDropdownMenuLink"
                        data-parent="#navbarDropdownMenuLink">
                        <li>
                            <a class="dropdown-item" :href="ProfileUrl" title="" data-toggle-tooltip="tooltip"
                                data-original-title="My Profile"><em class="fa fa-id-card"></em>{{ $t('myprofile') }}</a>
                        </li>
                        <li>
                            <input type="hidden" id="hdnUserLanguage" value="en">
                            <a class="dropdown-item dropdown-toggle" id="dropdownMenuButtonLanguage" data-toggle="dropdown"
                             aria-expanded="false" href="javascript:;"><em class="fa fa-globe"></em>{{Selectedlangvalue}}</a>
                            <ul id='selectedlang'  class="dropdown-menu selectlanguage" aria-labelledby="dropdownMenuButtonLanguage" style="height:150px !important; overflow-y: auto">
                                <li v-for="(lang, i) in Languages" :key="i + Math.random()">
                                    <a class="dropdown-item" href="javascript:;" @click="Updatemanudata('lang', lang)" :typefor="lang.LANGCODE">
                                        <span :class="'flag-icon flag-icon-squared mr-1 flag-icon-'+lang.LANG_ICON"></span>
                                        {{ lang.LANG_NAME }}
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a class="dropdown-item dropdown-toggle" id="dropdownMenuButtonMenuType" href="javascript:;"
                                data-toggle="dropdown" aria-expanded="false">
                                <em class="fa fa-bars"></em>{{$t('Menu')}} :<span id="spnMenuType">{{ SelectedMenuType }}</span>
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtonMenuType">
                                <li>
                                    <a class="dropdown-item sidebarCollapse" href="javascript:;"
                                        @click="Updatemanudata('menutype', 'T')">{{$t('Top')}}</a>
                                </li>
                                <li v-if="screenSize > 991">
                                    <a class="dropdown-item sidebarCollapse" href="javascript:;"
                                        @click="Updatemanudata('menutype', 'L')">{{ $t('Left') }}</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a class="dropdown-item dropdown-toggle" href="javascript:;" id="dropdownMenuButtonTheme"
                                data-toggle="dropdown" aria-expanded="false">
                                <em class="fa fa-table"></em>Theme :
                                <span id="spnTheme" valuefor="CssGreen">{{ SelectedThemeType }}</span>
                            </a>
                            <ul class="dropdown-menu themeselect" aria-labelledby="dropdownMenuButtonTheme">
                                <li>
                                    <a class="dropdown-item" href="javascript:;" title=""
                                        @click="Updatemanudata('themetype', 'Colored')" valuefor="Colored"
                                        data-toggle-tooltip="tooltip" data-original-title="Colored">{{$t('Colored')}}</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="javascript:;" title=""
                                        @click="Updatemanudata('themetype', 'Dark')" valuefor="Dark"
                                        data-toggle-tooltip="tooltip" data-original-title="Dark">{{$t('Dark')}}</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="javascript:;" title=""
                                        @click="Updatemanudata('themetype', 'Light')" valuefor="Light"
                                        data-toggle-tooltip="tooltip" data-original-title="Light">{{$t('Light')}}</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="javascript:;" title=""
                                        @click="Updatemanudata('themetype', 'White')" valuefor="White"
                                        data-toggle-tooltip="tooltip" data-original-title="White">{{ $t('White')}}</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a class="dropdown-item" href="https://app.talygen.com/Notification/Index" title=""
                                data-toggle-tooltip="tooltip" data-original-title="Notifications"><em
                                    class="fa fa-bell"></em>{{ $t('Notifications')}}</a>
                        </li>
                        <li>
                            <a class="dropdown-item" href="https://app.talygen.com/Help/TalygenHelp" title=""
                                data-toggle-tooltip="tooltip" data-original-title="Help"><em
                                    class="fa fa-question"></em>{{$t('Help')}}</a>
                        </li>
                        <li>
                            <a class="dropdown-item" id="DashboardPolicy" href="javascript:;" title=""
                                data-toggle-tooltip="tooltip" data-original-title="Policy"><em
                                    class="fa fa-wpforms"></em>{{ $t('Policy') }}</a>
                        </li>
                        <li>
                            <!-- <RouterLink to="/logout" class="dropdown-item" checkPrivilege="Yes" data-original-title="Logout"><em class="fa fa-power-off"></em>Logout</RouterLink> -->
                            <a class="dropdown-item" id="DashboardPolicy" href="javascript:;" title=""
                                data-toggle-tooltip="tooltip" data-original-title="Policy" v-on:click="logoutFunc"><em
                                    class="fa fa-power-off"></em> {{$t('Logout')}}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <input type="hidden" id="hdnSortExp" value="" />
        <input type="hidden" id="hdnSortBy" value="" />
        <input type="hidden" id="hdnChkMobile" value="no" />
        <input type="hidden" id="hdnischildsearch" value="0" />
        <!-- <input type="hidden" id="hdnChatUrl" value=" /mychat/" /> -->
        <!-- <TourGuide v-if="tourGuideShow" v-on:close="tourGuideShow=false"/> -->
        <Choosewizards v-if="showWizard" v-on:close="showWizard = false"></Choosewizards>
        <TimeTracker v-if="showTimetrackerModal" v-on:close="showTimetrackerModal=false" v-on:openTimer="timerStart"
            :start="true"></TimeTracker>

    </div>
</template>

<script>
// import '../../../Content/Responsive/flags/flag-icon.min.css';
import {
    storeToRefs
} from "pinia";
import $ from "jquery";
const currentAppRootURL = process.env.VUE_APP_CURRENT_SITE_ROOT_URL;
const defaultAppURL = process.env.VUE_APP_DEFAULT_APP_URL;
const fullMenuRedirectionApplied = process.env.VUE_APP_FULL_MENU_APP_REDIRECTION;
const currentUrl = window.location.pathname.substring(1);
import DataService from "../../services/DataService"
import Unreadnotification from "../Notification/Unreadnotification.vue";
import Choosewizards from "../Wizard/choosewizards.vue";
import TimeTracker from "../Tracker/TimeTrackerModal.vue";
import TimerStartOrStop from '../Tracker/TimerStartOrStop.vue';
import FullMenuHeader from "../Model/FullMenuHeader.vue";
import authService from "../../services/authservice";
//import TourGuide from "@/components/TourGuide/TourGuide.vue";
import {
    userTimerInfo
} from "../../stores/TimeTracker";

export default {
    components: {
        Unreadnotification,
        Choosewizards,
        TimeTracker,
        TimerStartOrStop,
        FullMenuHeader
        ///TourGuide
    },
    data() {
        return {
            MenuData: [],
            MenuDataRecord: [],
            MenuDataList: [],
            MaxLoop: 5,
            ModuleName: "",
            MenuDataGroupBy: [],
            MenuCountmodule: 0,
            TotalCount: 0,
            TotalMenuCount: 0,
            Languages: [],
            SelectedLanguage: "en",
            SelectedMenuType: "Top",
            notifications: null,
            showWizard: false,
            showBookmark: false,
            CompanyLogo: null,
            Avatar: null,
            ProfileUrl: null,
            UserType: null,
            showTimetrackerModal: false,
            startTimerShow: false,
            timeTrackPage: false,
            //tourGuideShow:false,
            breakOrTimerOnEvent: { "breakTimer": false },
            searchMenu: '',
            IsHeaderShow: false,
            timerPermisssion: false,
            localStorageData: [],
            localStorageLang: '',
            SelectedThemeType: '',
            menutypevalue: '',
            Selectedlangvalue:'English',
            defaultAppURLVar:defaultAppURL,
            userdata:[],
            screenSize: window.screen.width
        };
    },
    setup() {
        const store2 = userTimerInfo()
        const {
            getTimer
        } = storeToRefs(store2)
        return {
            getTimer,
            store2
        };
    },
    created: async function () {
        window.addEventListener("resize", this.resizeEventMethod);
        var menuSettings = null;
        //var localStorage = window.localStorage.TALGYEN_NAVBAR_SETTING;
        //if (localStorage == null || typeof (localStorage) == 'undefined') {
            menuSettings = {
                menuType: this.GetUserInfo.MenuType,
                lang:  this.SelectedLanguage,
                theme: this.GetUserInfo.Theme,
                langname:this.Selectedlangvalue
            }
        //    window.localStorage.setItem('TALGYEN_NAVBAR_SETTING', JSON.stringify(menuSettings));
        //} else {
       //     menuSettings = JSON.parse(localStorage);
        //}

        this.SelectedMenuType =  this.GetUserInfo.MenuType;
        this.SelectedThemeType = this.GetUserInfo.Theme;
        this.SelectedLanguage = this.GetUserInfo.lang;
        this.Selectedlangvalue = this.Selectedlangvalue;
        this.resizeEventMethod();
        // if(this.screenSize > 991){
        //     if (this.SelectedMenuType == 'Left') {
        //         $("body").addClass("menu-left-wrapper");
        //         $('li').removeClass("fixed-top");
        //         $('ul').removeClass("show");
        //     }
        //     else {
        //         $("body").removeClass("menu-left-wrapper").removeClass("wrapper-shrink");
        //         $('li.navbar').addClass("fixed-top");
        //         $('ul').removeClass("show");
        //     }
        // } else {
        //     this.SelectedMenuType = "Top";
        // }

        // this.localStorageLang = window.localStorage.languageSet;
        window.onbeforeunload = this.closeComponent;
        this.CompanyLogo = this.GetUserInfo.CompanyLogo;
        this.UserType = this.GetUserInfo.UserType;
        this.Avatar = this.GetUserInfo.Avatar;
       console.log("av",this.Avatar);
        if (!this.CompanyLogo || this.CompanyLogo.toLowerCase() == "company-logo.jpg") {
            this.CompanyLogo = '/Content/images/DefaultImages/company-logo.jpg';
        } else {
            await this.GetCompanyLogo(this.CompanyLogo);
        }
        
        this.ProfileUrl = defaultAppURL + '/MyProfile/Index?companyid=' + this.GetUserInfo.CompanyId;
        this.GetFULLMenuData();
        this.GetLanguageList(this.GetUserInfo);
        await this.store2.getTimerInfo();
        this.IsHasPermission('Timer','index');
        setTimeout(() => {
            this.GetTourGuidePermission();
        }, 2000);
        if(!(this.Avatar ==null || this.Avatar ==" " || this.Avatar ==""  || this.Avatar == undefined)){
            await this.GetUserImage(this.Avatar)
        }
        else{
             if(this.Gender=="M" ){
                this.Avatar ='/Content/images/DefaultImages/avatar-icon.jpg'
             }else{
                this.Avatar ='/Content/images/DefaultImages/avatar-icon.jpg'
             }
        }
    },
    watch: {
        '$route.path': {
            handler: async function (search) {
                this.timeTrackPage = false
                let url = `/Timer/Index`
                //console.log("check",search.toLowerCase(),url.toLowerCase())
                if (search.toLowerCase().localeCompare(url.toLowerCase()) != 0) {
                    this.GetTimer();
                    this.timeTrackPage = false
                } else {
                    // console.log("second",search)
                    this.timeTrackPage = true
                }
                if (this.$route.query.reload == 'reload') {
                    this.timeTrackPage = true
                    const query = Object.assign({}, this.$route.query);
                    delete query.reload;
                    await this.store2.getTimerInfo();
                    this.$router.push({ query: query })
                    this.timeTrackPage = false
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        resizeEventMethod: function(){
            this.SelectedMenuType =  this.GetUserInfo.MenuType;
            this.screenSize = window.screen.width;
            if(this.screenSize > 991){
                if (this.SelectedMenuType == 'Left') {
                    $("body").addClass("menu-left-wrapper");
                    $('li').removeClass("fixed-top");
                    $('ul').removeClass("show");
                }
                else {
                    $("body").removeClass("menu-left-wrapper").removeClass("wrapper-shrink");
                    $('li.navbar').addClass("fixed-top");
                    $('ul').removeClass("show");
                }
            } else {
                this.SelectedMenuType = "Top";
                $("body").removeClass("menu-left-wrapper").removeClass("wrapper-shrink");
                $('li.navbar').addClass("fixed-top");
                $('ul').removeClass("show");
            }
        },
        logoutFunc: async function () {
            await authService.logOut();
        },
        OpenIcon: function () {
            this.IsHeaderShow = true;
            //this.IsHeaderShow =! this.IsHeaderShow
        },
        async GetTourGuidePermission() {
            try {
                var vm = this;
                var parts = currentUrl.split('/'); 
                if (parts.length > 2) { 
                    parts.splice(2);
                }
                var cleanedUrl = parts.join('/');
                await this.IsHasPermission(parts[0], parts[1]);
                if (this.timerPermisssion) {
                    var params = `url=${cleanedUrl}`;
                    const result = await DataService.GetWalkThroughViewOrNot(params);
                    console.log(result.data.result.RESULT);
                    console.log('GetWalkThroughViewOrNot');
                    if (result.data.result.RESULT == '0' &&  currentUrl !== "unauthorized") {
                        vm.setTourGuideSteps(0, null, cleanedUrl);
                    }
                }   else {
                    console.log('You do not have permission to access this page.');
                }              
            } catch (error) {
                console.log('Error fetching menu data:', error);
            }
        },


        closeModalOnClickOutside(event) {
            const modal = this.$refs.modalRef;
            if (!modal.contains(event.target)) {
                this.closeModal();
            }
        },
        async GetTimer() {
            if (this.getTimer?.trackId) {
                this.startTimerShow = true
            } else {
                this.startTimerShow = false
            }



        },
        // tourGuide(event){
        //     this.tourGuideShow = event

        // },
        handleCookEggs(event) {

            event.stopPropagation(); // USED HERE!
            this.searchMenu = ''
        },
        closeComponent() {
            this.startTimerShow = false
        },
        timerStart(event) {
            //breakTimer for timer and breack show in header
            this.breakOrTimerOnEvent = { "breakTimer": event.breakTimer }
            this.startTimerShow = true
            this.showTimetrackerModal = false
        },
        startTimer(e) {
            this.startTimerShow = e?.isOpen
            this.showTimetrackerModal = e?.update
        },
        OnShowBookMark() {
            const customEvent = new CustomEvent('event_addbookmark')
            window.dispatchEvent(customEvent);
        },
        toggleFullscreen(elem) {
            elem = elem || document.documentElement;
            if (!document.fullscreenElement && !document.mozFullScreenElement &&
                !document.webkitFullscreenElement && !document.msFullscreenElement) {
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                }
            }
        },
        getRootLink: function (link) {
            var rtnValue = "";
            if (fullMenuRedirectionApplied == "true") {
                if (link) {
                    if (link.ROOT_URL != null && link.ROOT_URL.toLowerCase() == currentAppRootURL.toLowerCase()) {
                        rtnValue = "CURRENT";
                    } else if (link.ROOT_URL != null) {
                        rtnValue = link.ROOT_URL;
                    } else {
                        rtnValue = defaultAppURL;
                    }
                }
            } else {
                rtnValue = "CURRENT";
            }
            return rtnValue;
        },
        Updatemanudata(setting, type) {
            this.menutypevalue='';
            if (setting == 'menutype' && type == "L") {
                this.SelectedMenuType = "Left";
                this.menutypevalue= this.SelectedMenuType;
                //this.manutype = 'Left'
                $("body").addClass("menu-left-wrapper");
                $('li').removeClass("fixed-top");
                $('ul').removeClass("show");
            }
            else if (setting == 'menutype' && type == "T") {
                this.SelectedMenuType = "Top";
                this.menutypevalue= this.SelectedMenuType;
                //this.manutype = 'Top';   Colored Dark Default Light
                $("body").removeClass("menu-left-wrapper");
                $("body").removeClass("wrapper-shrink");
                $('li.navbar').addClass("fixed-top");
                $('ul').removeClass("show");
            }
            else if (setting == 'lang') {
                this.SelectedLanguage = type.LANGCODE;
                this.Selectedlangvalue=type.LANGNAME;
                this.menutypevalue=this.SelectedLanguage;
                //$('#selectedlang').removeClass("show");
            }
            else if (setting == 'themetype') {
                this.SelectedThemeType = type;
                this.menutypevalue=  this.SelectedThemeType;
                //$('li.vue-scroll').removeClass("show");
            }
            var menuSettings = null;
            var localStrge = JSON.parse(window.localStorage.TALGYEN_NAVBAR_SETTING);
            if(localStrge != null && typeof(localStrge) != 'undefined'){
                if(setting == 'themetype'){
                    localStrge.theme = this.SelectedThemeType;
                } else if(setting == 'menutype'){
                    localStrge.menuType = this.SelectedMenuType;
                } 
                else if(setting == 'lang'){
                    this.$i18n.locale = this.SelectedLanguage.toLowerCase();
                    localStrge.lang = this.SelectedLanguage;
                    localStrge.langname= this.Selectedlangvalue;
                }
                menuSettings = localStrge
            }

            localStorage.setItem('TALGYEN_NAVBAR_SETTING', JSON.stringify(menuSettings));
           // if(setting != 'lang'){
                var menutypevalue='';
                if(setting != 'lang'){
                    menutypevalue = this.menutypevalue
                }
                else{
                    menutypevalue = this.SelectedLanguage.toLowerCase();
                }
                var params = `menutype=${menutypevalue}&type=${setting}`
                DataService.updateuserinfo(params).then(function (response) {
                    debugger
                })
            // }
            // else {
            //     window.location.reload();
            // }
            authService.signIn(this.$route.path).then(() => {
              
            });
        },
        // SetLanguage(code) {
        //     //v-model="$i18n.locale"
        //     this.$i18n.locale = code;
        //     var chkGroup = $.grep(this.Languages, function (element) {
        //         return element.LANGCODE == code;
        //     });
        //     this.SelectedLanguage = chkGroup[0].LANGNAME;
        //     localStorage.setItem('languageSet', this.SelectedLanguage)
        // },
        async GetLanguageList(GetUserInfo) {
            var vm = this;
            vm.userdata=GetUserInfo;
            DataService.GetLanguageList().then((response) => {
                if (response.data.status == "Success") {
                    vm.Languages = response.data.result.DATA;
                    if(vm.Languages.length > 0){
                    vm.Languages.forEach(items => {
                        var longcode = items.LANGCODE.toLowerCase();
                        if(longcode === vm.userdata.Languagecode){
                            vm.Selectedlangvalue=items.LANGNAME;
                        }
                    })
                   }
                    vm.getUnReadNotifications();
                }
            });
        },
        async GetCompanyLogo(imageName) {
            var vm = this;
            var param = 'imagename=' + imageName;
            await DataService.GetCompanyLogo(param).then((response) => {
                vm.CompanyLogo = response.data;
            });
        },
        async GetFULLMenuData() {
            var vm = this;
            DataService.GetFullMenu().then((response) => {
                if (response.data.status == "Success") {
                    vm.MenuDataRecord = response;
                    //vm.MenuData = response.data.result.DATA;  
                    var dt = response.data.result.DATA; 
                    //dt.sort((a,b) => (vm.$t(a.DISPLAY_NAME) > vm.$t(b.DISPLAY_NAME)) ? 1 : ((vm.$t(b.DISPLAY_NAME) > vm.$t(a.DISPLAY_NAME)) ? -1 : 0)); 
                    vm.MenuData = dt;             
                    var MenuGroupData = [];
                    if (vm.MenuData != null && vm.MenuData.length > 0) {
                        var groupId = vm.MenuData[0].MODULE_MASTER_ID;
                        vm.MenuData.forEach(item => {
                            groupId = item.MODULE_MASTER_ID;
                            var chkGroup = $.grep(MenuGroupData, function (element) {
                                return element.ModuleMasterId == groupId;
                            });
                            if (chkGroup.length == 0) {
                                var returnedData = $.grep(vm.MenuData, function (element) {
                                    return element.MODULE_MASTER_ID == groupId;
                                });
                                var chkDashBoard = $.grep(returnedData, function (element) {
                                    return String(element.ACTION_NAME).toLowerCase() == "dashboard" || String(element.CONTROLER_NAME).toLowerCase() == "dashboard";
                                });
                                var urlListData = [];
                                returnedData.forEach(itmRData => {
                                    var groupName = itmRData.GROUP_NAME;
                                    var checkForgroupCode = $.grep(urlListData, function (element) {
                                        return element.groupName == groupName;
                                    });
                                    if (checkForgroupCode.length == 0) {
                                        var getAllUrls = $.grep(returnedData, function (element) {
                                            return element.GROUP_NAME == groupName;
                                        });
                                        let rowOne = [];
                                        let rowTwo = [];
                                        let rowThree = [];
                                        if (getAllUrls.length > 0) {
                                            getAllUrls.forEach((url, i) => {
                                                if ((i % 3) == 0) {
                                                    rowOne.push(url);
                                                } else if ((i % 3) == 1) {
                                                    rowTwo.push(url);
                                                } else if ((i % 3) == 2) {
                                                    rowThree.push(url);
                                                }
                                            });
                                        }
                                        var obj = {
                                            groupName: groupName,
                                            URL: []
                                        }
                                        obj.URL.push(rowOne);
                                        obj.URL.push(rowTwo);
                                        obj.URL.push(rowThree);
                                        //obj.URL.sort((a,b) => (a.groupName > b.groupName) ? 1 : ((b.groupName > a.groupName) ? -1 : 0))
                                        urlListData.push(obj)
                                    }
                                });
                                urlListData.sort((a,b) => (a.groupName > b.groupName) ? 1 : ((b.groupName > a.groupName) ? -1 : 0))
                                if (returnedData.length > 0) {
                                    var objGroup = {
                                        ModuleMasterId: groupId,
                                        ModuleName: returnedData[0].MODULE_HEADER_NAME,
                                        ModuleIcon: returnedData[0].ATTACHMENT_PATH == "" ? "fa-cog" : returnedData[0].ATTACHMENT_PATH,
                                        ShowDashboardLink: chkDashBoard.length > 0 ? 1 : 0,
                                        ControllerName: chkDashBoard.length > 0 ? chkDashBoard[0].CONTROLER_NAME : "",
                                        ActionName: chkDashBoard.length > 0 ? chkDashBoard[0].ACTION_NAME : "",
                                        RoleApplicable: chkDashBoard.length > 0 ? chkDashBoard[0].ROLE_APPLICABLE : "",
                                        IsDisabled: chkDashBoard.length > 0 ? chkDashBoard[0].IS_DISABLED : "0",
                                        UrlList: urlListData
                                    };
                                    MenuGroupData.push(objGroup);
                                }
                            }
                        });
                        this.TotalCount = MenuGroupData.length;
                        this.TotalMenuCount = Math.ceil(this.TotalCount / 3);
                        this.MenuCountmodule = this.TotalCount % 3;
                        this.MenuDataList = MenuGroupData;
                        var chkMenuCount = 1;
                        var count = 1;
                        this.MenuDataGroupBy = [];
                        //MenuGroupData.sort((a,b) => (a.ModuleName > b.ModuleName) ? 1 : ((b.ModuleName > a.ModuleName) ? -1 : 0)); 
                        MenuGroupData.forEach(item => {
                            var countMenu = vm.TotalMenuCount;
                            if ((vm.MenuCountmodule <= 2 && chkMenuCount == 1) || (vm.MenuCountmodule == 2 && chkMenuCount == 2)) {
                                countMenu = countMenu + 1;
                            }
                            var chkInsert = false;
                            if (count == countMenu) {
                                count = 0;
                                chkMenuCount += 1;
                                if (chkMenuCount <= 3) {
                                    chkInsert = true;
                                    var obj = {
                                        Menu: []
                                    };
                                    obj.Menu.push(item);
                                    vm.MenuDataGroupBy.push(obj);
                                }
                            }
                            if (!chkInsert) {
                                var length = vm.MenuDataGroupBy.length;
                                if (length == 0) {
                                    var obj2 = {
                                        Menu: []
                                    };
                                    obj2.Menu.push(item);
                                    vm.MenuDataGroupBy.push(obj2);
                                } else {
                                    vm.MenuDataGroupBy[length - 1].Menu.push(item);
                                }
                            }
                            count += 1;
                        });
                        setTimeout(function () {
                            vm.SetScrolbar("dark-thick", "", "navigation-panel-full-menu");
                        }, 1500);
                    }
                }
            });
        },
        GoToSubCategory(event, id) {
            var $this = $(event.target);
            $("#hdnischildsearch").val("1");
            ShowChild($this, false, id);
            $("#fullMenuUl").addClass("show");
        },
        SeacrchMenuItem(event) {
            var val = event.target.value;
            if (val != "") {
                $("a.clear-search").show();
            } else {
                $("a.clear-search").hide();
            }
            SearchMenuItem(val);
        },
        getUnReadNotifications() {
            var vm = this;
            DataService.UnreadItems("reqFrom=count").then(function (response) {
                vm.notifications = response.data;
            });
        },

        btnDeleteSample() {
            var vm = this;
            vm.confirmR(vm.$t("ConfirmSampleDataDelete"), true, (vm.$t("Delete")), function () {

                DataService.DeleteSampleData().then(function (response) {

                    if (response.data.msg == "success") {
                        vm.ShowAlert(vm.$t("SampleDataDeleted"), "success", true, vm.$t("Alert"));
                    } else {
                        vm.ShowAlert(vm.$t("Something_Wrong"), "failure", true, vm.$t("Alert"));
                    }
                })

            })
        },
        IsHasPermission: async function (controller, action) {
            const { data } = await DataService.CheckPrivilege(`controller=${controller}&action=${action}`)
            this.timerPermisssion = data
        },
        async GetUserImage(imageName) {
            var vm = this;
            var param = 'imagename=' + imageName;
            await DataService.GetUserImage(param).then((response) => {
                vm.Avatar = response.data;
            });
        },
    },
}

var timerOut;
var open_window = 0;
var chatlogwindow;
var isMobile;
var socket;
$(document).ready(function () {
    setTimeout(function(){
        $("ul.dropdown-menu[data-toggle='dropdown']").on("click", function (event) {
            event.preventDefault();
            event.stopPropagation();
            //method 2: remove show from all siblings of all your parents
            $(this).parents('.dropdown-submenu').siblings().find('.show').removeClass("show");
            $(this).siblings().toggleClass("show");
            //collapse all after nav is closed
            $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
                $('.dropdown-submenu .show').removeClass("show");
            });
        });
    }, 500);
    !function (a) {
        var b = /iPhone/i,
            c = /iPod/i,
            d = /iPad/i,
            e = /(?=.*\bAndroid\b)(?=.*\bMobile\b)/i,
            f = /Android/i,
            g = /(?=.*\bAndroid\b)(?=.*\bSD4930UR\b)/i,
            h = /(?=.*\bAndroid\b)(?=.*\b(?:KFOT|KFTT|KFJWI|KFJWA|KFSOWI|KFTHWI|KFTHWA|KFAPWI|KFAPWA|KFARWI|KFASWI|KFSAWI|KFSAWA)\b)/i,
            i = /IEMobile/i,
            j = /(?=.*\bWindows\b)(?=.*\bARM\b)/i,
            k = /BlackBerry/i,
            l = /BB10/i,
            m = /Opera Mini/i,
            n = /(CriOS|Chrome)(?=.*\bMobile\b)/i,
            o = /(?=.*\bFirefox\b)(?=.*\bMobile\b)/i,
            p = new RegExp("(?:Nexus 7|BNTV250|Kindle Fire|Silk|GT-P1000)", "i"),
            q = function (a, b) {
                return a.test(b)
            },
            r = function (a) {
                var r = a || navigator.userAgent,
                    s = r.split("[FBAN");
                return "undefined" != typeof s[1] && (r = s[0]), s = r.split("Twitter"), "undefined" != typeof s[1] && (r = s[0]), this.apple = {
                    phone: q(b, r),
                    ipod: q(c, r),
                    tablet: !q(b, r) && q(d, r),
                    device: q(b, r) || q(c, r) || q(d, r)
                }, this.amazon = {
                    phone: q(g, r),
                    tablet: !q(g, r) && q(h, r),
                    device: q(g, r) || q(h, r)
                }, this.android = {
                    phone: q(g, r) || q(e, r),
                    tablet: !q(g, r) && !q(e, r) && (q(h, r) || q(f, r)),
                    device: q(g, r) || q(h, r) || q(e, r) || q(f, r)
                }, this.windows = {
                    phone: q(i, r),
                    tablet: q(j, r),
                    device: q(i, r) || q(j, r)
                }, this.other = {
                    blackberry: q(k, r),
                    blackberry10: q(l, r),
                    opera: q(m, r),
                    firefox: q(o, r),
                    chrome: q(n, r),
                    device: q(k, r) || q(l, r) || q(m, r) || q(o, r) || q(n, r)
                }, this.seven_inch = q(p, r), this.any = this.apple.device || this.android.device || this.windows.device || this.other.device || this.seven_inch, this.phone = this.apple.phone || this.android.phone || this.windows.phone, this.tablet = this.apple.tablet || this.android.tablet || this.windows.tablet, "undefined" == typeof window ? this : void 0
            },
            s = function () {
                var a = new r;
                return a.Class = r, a
            };
        "undefined" != typeof module && module.exports && "undefined" == typeof window ? module.exports = r : "undefined" != typeof module && module.exports && "undefined" != typeof window ? module.exports = s() : "function" == typeof define && define.amd ? define("isMobile", [], a.isMobile = s()) : a.isMobile = s()
    }(this);
    isMobile = window.orientation > -1;

    function socketCall() {
        socket.emit('user notification', user);
        $("#popChatNotification").fadeIn(1000).fadeOut(1000);
    }

    $("body").on("click", ".videocall,.chat,.voicecall", function (event) {

        var clickID = $(event.currentTarget).attr('id');
        var ticketRaised = $("#hdnUserId").val();
        if (user.userId == ticketRaised) {
            $("#chat_popup").trigger("click");
        } else if (clickID == "call") {
            OpenWindow('https://stage.talygen.com/Communication/Index?reqhFrom=40348&call=1&tid=' + ticketRaised, 800, 1200, false);
        } else {
            OpenWindow('https://stage.talygen.com/Communication/Index?reqhFrom=40348&tid=' + ticketRaised, 800, 1200, false);
        }

    });

    var chkIsOpen = true;

    // $('#chat_popup').click(function () {

    //     OpenWindow('https://stage.talygen.com/Communication/Index?reqhFrom=40348', 800, 1200, false);

    // });
    //debugger;
    // $('#popChatLink').click(function () {
    //     debugger;
    //     $("#chat_popup").trigger("click");
    // });

    var count = 0;

    function getUserDetails() {
        var user = new Object();
        var mobileType = window.orientation > -1;
        var type = "web";
        if (mobileType) {
            type = "mobile";
        }
        user.type = type;
        user.userId = $("#UserId").val();
        user.name = $("#hdnName").val();
        user.userimgPath = $("#hdnloginUserImgPath").val();
        if (typeof user.userimgPath !== 'undefined') {
            if (user.userimgPath.length <= 0) {
                user.userimgPath = "/Content/images/DefaultImages/avatar-icon.jpg";
            }
        }
        user.companyId = $("#cid").val();
        user.Email = $("#hdnloggedinemailid").val();
        return user;
    }

    function bellDetails(count) {
        if (count > 0) {
            $("#popChatCount").show();
            notifyMeChat(count);
        }
        if (count == 0) {
            $("#popChatCount").hide();
        }
        if (count > 99) {
            count = 99;
        }
        $('#popChatCount').html(count);
    }
    var user = getUserDetails();
    //  var httpLink = $("#hdnChatUrl").val().replace("https:", "wss:").replace("http:", "ws:").replace("mychat/", "");
    // socket = io.connect(httpLink);
    // socket.emit('add user', user);
    // socket.emit('user total', user);

    //setInterval(socketCall, 500);
    //setTimeout(socketCall, 1000);

    // socket.on('broadcast', function (data) {
    //     if (data.userId == user.userId) {
    //         //  if(data.totalcnt > 0){
    //         //   count = data.totalcnt;
    //         //   }else{
    //         //   count++;}
    //         bellDetails(data.totalcnt);
    //     }
    // });

    // socket.on('totalResult', function (data) {
    //     if (data.userId == user.userId) {
    //         count = count + parseInt(data.cnt);
    //         bellDetails(count);
    //     }
    // });

    // socket.on('videoRemovePopUpReceiver', function (data) {
    //     $('.vca_mainwrapper').each(function () {
    //         var recId = $(this).attr('id');
    //         if (data.userId == recId) {
    //             $('#' + recId + ".vca_mainwrapper").css("display", "none").html('');
    //             $('#' + recId + ".vca_mainwrapper").remove();
    //         }
    //     });
    // });

    // socket.on('closePopUPCall', function (data) {
    //     if (data.id == user.userId) {
    //         clearTimeout(timerOut);
    //         $.notifyClose();
    //         $('.vca_mainwrapper').css("display", "none").html('');
    //     }
    // });

    // socket.on('tokBoxParticipant', function (data) {
    //     open_window = 0;
    //     if (user.userId == data.toUserId) {
    //         timerOut = setTimeout(function () {
    //             $.notifyClose();
    //             $('.vca_mainwrapper').css("display", "none").html('');
    //             socket.emit('missed video call toxBox', data);
    //         }, 30000);

    //         if (isMobile != true) {
    //             notifyMe(data, 'tokBox');
    //         }
    //         $.notify({
    //             // options
    //             icon: data.fromName,
    //             title: data.fromUserId,
    //             message: data.actRoomId + '~' + data.actRoom + '~' + data.data.sessionId + '~' + data.data.apiKey,
    //             url: data.fromUserPath,
    //             target: data.fromName
    //         },
    //             {
    //                 element: 'body', placement: { from: "top", align: "right" }, delay: 1, allow_dismiss: true, timer: 30000, offset: { x: 20, y: 350 },
    //                 template: '<div id="{1}" class="vca_mainwrapper">' +
    //                     '<div class="vca_callerinfobase">' +
    //                     '<div class="vca_callerdp"><img src="{3}" loading="lazy"></div>' +
    //                     '<div class="vca_callername">{4}</div>' +
    //                     '<div class="vca_calling">Calling...</div>' +
    //                     '<div class="vca_btnsbasemain">' +
    //                     '<div class="vca_btnsbase">' +
    //                     '<a data-path="{3}" id="joinVideoCall" data-room="{2}" data-contactname="{4}" data-receiverId="{1}" class="pickcall" href="javascript:;" onclick="VideoCallPickupMethod(this, ' + user.userId + ',\'tokBox\')"><img src="/Content/images/chat/acceptcall.png" /></a>' +
    //                     '<a class="dropcall" data-path="{3}" data-room="{2}" data-contactname="{4}" data-receiverId="{1}" id="joinVideoReject" data-notify="dismiss" href="javascript:;"><img src="/Content/images/chat/rejectcall.png" /></a>' +
    //                     '</div>' +
    //                     '</div>' +
    //                     '</div>' +
    //                     '</div>'
    //             });

    //         $("body").on("click", "#joinVideoReject", function (e) {
    //             clearTimeout(timerOut);
    //             // timerOut = null;
    //             $(this).hide();
    //             $('.vca_mainwrapper').css("display", "none").html('');

    //             var room = $(this).attr("data-room");
    //             var receiverId = $(this).attr("data-receiverId");
    //             if (open_window == 0) {
    //                 open_window++;
    //                 var newparam = new Object();
    //                 newparam.room = room;
    //                 newparam.receiver = receiverId;
    //                 newparam.contactname = user.name;
    //                 newparam.id = user.userId;
    //                 newparam.emaiId = user.Email;
    //                 socket.emit('video call reject', newparam);
    //             }
    //             $('#' + receiverId + ".vca_mainwrapper").remove();
    //             $.notifyClose();
    //         });

    //     }
    // });

    // socket.on('getvideoCallReceiver', function (data) {
    //     open_window = 0;
    //     timerOut = null;
    //     if (user.userId == data.toUserId) {
    //         timerOut = setTimeout(function () {
    //             $.notifyClose();
    //             $('.vca_mainwrapper').css("display", "none").html('');
    //             socket.emit('missed video call', data);
    //         }, 30000);

    //         if (isMobile != true) {
    //             notifyMe(data, "talygen");
    //         }
    //         $.notify({
    //             // options
    //             icon: data.fromName,
    //             title: data.fromUserId,
    //             message: data.roomId + '~' + data.actRoom + '~' + data.actRoomId,
    //             url: data.fromUserPath,
    //             target: data.fromName
    //         },
    //             {
    //                 element: 'body', placement: { from: "top", align: "right" }, delay: 1, allow_dismiss: true, timer: 30000, offset: { x: 20, y: 350 },
    //                 template: '<div id="{1}" class="vca_mainwrapper">' +
    //                     '<div class="vca_callerinfobase">' +
    //                     '<div class="vca_callerdp"><img src="{3}" loading="lazy"></div>' +
    //                     '<div class="vca_callername">{4}</div>' +
    //                     '<div class="vca_calling">Calling...</div>' +
    //                     '<div class="vca_btnsbasemain">' +
    //                     '<div class="vca_btnsbase">' +
    //                     '<a data-path="{3}" id="joinVideoCall" data-room="{2}" data-contactname="{4}" data-receiverId="{1}" class="pickcall" href="javascript:;" onclick="VideoCallPickupMethod(this, ' + user.userId + ',\'talygen\')"><img src="/Content/images/chat/acceptcall.png" /></a>' +
    //                     '<a class="dropcall" data-path="{3}" data-room="{2}" data-contactname="{4}" data-receiverId="{1}" id="joinVideoReject" data-notify="dismiss" href="javascript:;"><img src="/Content/images/chat/rejectcall.png" /></a>' +
    //                     '</div>' +
    //                     '</div>' +
    //                     '</div>' +
    //                     '</div>'
    //             });

    //         $("body").on("click", "#joinVideoReject", function (e) {
    //             clearTimeout(timerOut);
    //             // timerOut = null;
    //             $(this).hide();
    //             $('.vca_mainwrapper').css("display", "none").html('');

    //             var room = $(this).attr("data-room");
    //             var receiverId = $(this).attr("data-receiverId");
    //             if (open_window == 0) {
    //                 open_window++;
    //                 var newparam = new Object();
    //                 newparam.room = room;
    //                 newparam.receiver = receiverId;
    //                 newparam.contactname = user.name;
    //                 newparam.id = user.userId;
    //                 newparam.emaiId = user.Email;
    //                 socket.emit('video call reject', newparam);
    //             }
    //             $('#' + receiverId + ".vca_mainwrapper").remove();
    //             $.notifyClose();
    //         });

    //         //$("body").on("click", "#joinVideoCall", function (e) {
    //         //    clearTimeout(timerOut);
    //         //    //   timerOut = null;
    //         //    $(this).hide();
    //         //    $('.vca_mainwrapper').css("display", "none").html('');

    //         //    var room_accept = $(this).attr("data-room");
    //         //    var receiverId_accept = $(this).attr("data-receiverId");
    //         //    var contactname_accept = $(this).attr("data-contactname");

    //         //    var isMobile = window.orientation > -1;
    //         //    if (isMobile == true) {
    //         //        localStorage.removeItem('clientOpen');
    //         //    }

    //         //    var stateClient = localStorage.getItem('clientOpen');

    //         //    if (stateClient == "1") {
    //         //        var newparam = new Object();
    //         //        newparam.room = room_accept;
    //         //        newparam.receiver = receiverId_accept;
    //         //        newparam.contactname = contactname_accept;
    //         //        newparam.id = user.userId;
    //         //        socket.emit('add video on chat', newparam);
    //         //    } else {

    //         //        callWindow(room_accept, receiverId_accept, contactname_accept);
    //         //    }
    //         //    $('#' + receiverId_accept + ".vca_mainwrapper").remove();
    //         //    $.notifyClose();
    //         //    function callWindow(room, receiverId, contactname) {
    //         //        if (open_window == 0) {
    //         //            open_window++;
    //         //            var alink = "/Communication/Index?reqhFrom=40348&room=" + room + "&rid=" + receiverId + "&name=" + contactname;
    //         //            OpenWindow(alink, 800, 1200, false);
    //         //        }
    //         //    }
    //         //});
    //     }
    // });
    // socket.on('newNotification', function (data) {
    //     if (UserId.value == data.userId) {
    //         var allData = data.msg.split('^');
    //         if (allData.length > 0) {
    //             $.notify({
    //                 // options
    //                 icon: allData[0],
    //                 title: allData[3],
    //                 message: allData[1],
    //                 url: allData[2],
    //                 target: '_blank'

    //             },
    //                 {
    //                     element: 'body', placement: { from: "bottom", align: "right" }, delay: 8000, allow_dismiss: true,
    //                     template: '<div class="notification_panelbase"><button type="button" aria-hidden="true" class="close closebutton" data-notify="dismiss"><i class="fa fa-close text-danger"></i></button>' +
    //                         '<div class="notification_content">' +
    //                         '<div class="notification_headbase_left">' +
    //                         '<i class="fa fa-clock-o"></i>' +
    //                         '</div>' +
    //                         '<div class="notification_headbase_right">' +
    //                         '<div class="subjectbase">' +
    //                         '<span>{0}</span>' +
    //                         '</div>' +
    //                         '<div class="subjectbase">{1}</div>' +
    //                         '<div class="subjectbase">{3}</div>' +
    //                         '<div class="subjectbase">{2}</div></div></div></div>'
    //                 });
    //         }
    //     }
    // });
    // socket.on('IncomingCallFromAsteriskOut', function (data) {
    //     $("#hdnCallMediumType").val("PBX");
    //     var jsonObject = data.data;
    //     console.log("Incomming");
    //     $("#lbl_CallTime").text("Calling...");
    //     $("#hid_currentPhoneNumber").val(jsonObject.callFrom);
    //     $("#lbl_forPhoneNumber").text(((jsonObject.callerIdName != null) ? jsonObject.callerIdName + "/" : "") + jsonObject.callFrom);
    //     $("#hid_channelOfCall").val(jsonObject.Channel);
    //     $("#ForIvRCall").css("display", "block");
    //     $("#numericPadForCalling").css("display", "none");
    //     $("#btn_TransferCall").css("display", "none");
    //     $("#btn_PickCall").css("display", "none");
    //     $("#divForMakeACall").css("display", "none");
    //     $("#hid_currentCall_log_Id").val(jsonObject.logId);
    //     $("#txt_nameForCall").val(jsonObject.callerIdName);
    // });
    // socket.on('OutgoingCallFromIPphoneOut', function (data) {
    //     $("#hdnCallMediumType").val("PBX");
    //     var jsonObject = data.data;
    //     console.log(data.data);
    //     $("#hid_currentPhoneNumber").val(jsonObject.callTo);
    //     $("#lbl_forPhoneNumber").text(((jsonObject.callerIdName != null) ? jsonObject.callerIdName + "/" : "") + jsonObject.callTo);
    //     $("#hid_channelOfCall").val(jsonObject.Channel);
    //     $("#ForIvRCall").css("display", "block");
    //     $("#numericPadForCalling").css("display", "none");
    //     $("#btn_TransferCall").css("display", "none");
    //     $("#btn_PickCall").css("display", "none");
    //     $("#divForMakeACall").css("display", "none");
    //     $("#hid_currentCall_log_Id").val(jsonObject.logId);
    //     $("#txt_nameForCall").val(jsonObject.callerIdName);
    // });
    // socket.on('CallHangUpFromAsteriskOut', function (data) {
    //     $("#hdnCallMediumType").val("PBX");
    //     console.log("HangUp");
    //     var jsonObject = data.data;
    //     console.log(data.data);
    //     $("#hid_currentPhoneNumber").val("");
    //     if (jsonObject.logId != null && parseInt(jsonObject.logId) > 0) {
    //         $("#div_forCallPickOrReject").css("display", "none");
    //         $("#ForIvRCall").css("display", "none");
    //         $("#div_whenCallTransfer").css("display", "none");
    //         clearInterval(setintervalForTimer);
    //     }
    // });
    // socket.on('OriginateCallResponseOut', function (data) {
    //     $("#hdnCallMediumType").val("PBX");
    //     $("#hid_currentPhoneNumber").val(data.data.callTo);
    //     $("#lbl_forPhoneNumber").text(data.data.callTo);
    //     $("#ForIvRCall").css("display", "block");
    //     $("#numericPadForCalling").css("display", "none");
    //     $("#btn_TransferCall").css("display", "none");
    //     $("#btn_PickCall").css("display", "none");
    //     $("#hid_currentCall_log_Id").val(data.data.logId);
    //     $("#hid_channelOfCall").val(data.data.Channel);
    //     $("#lbl_forPhoneNumber").text(((data.data.callerIdName != null) ? data.data.callerIdName + " / " : "") + data.data.callTo);
    // });
    // socket.on('AfterCallCommentSubmitedOut', function (data) {
    //     $("#hdnCallMediumType").val("PBX");
    //     $("#hid_currentCall_log_Id").val("0");
    //     $("#hid_currentPhoneNumber").val("");
    //     $("#txt_dialNumber").val("");
    //     $("#ddl_modules").val("");
    //     $("#ddl_modulesTypes").val("");
    //     $("#lbl_CallTime").text("Calling...");
    //     $("#ForIvRCall").css("display", "none");
    //     $("#btn_TransferCall").css("display", "none");
    //     $("#btn_PickCall").css("display", "none");
    //     $("#txt_commentForCall").val("");
    //     $("#div_forCallPickOrReject").css("display", "block");
    //     $("#div_forSaveOrCancel").css("display", "none");
    //     $(".div_whenCallEnded").css("display", "none");
    //     $("#numericPadForCalling").css("display", "none");
    //     $("#divForMakeACall").css("display", "block");
    // });

    // socket.on('CallStartedOnExtensionOut', function (data) {
    //     $("#hdnCallMediumType").val("PBX");
    //     console.log("Call Start Up");
    //     clearInterval(setintervalForTimer);
    //     console.log(data);
    //     $("#lbl_CallTime").text("00:00:00");
    //     $("#btn_TransferCall").css("display", "none;");
    //     $("#btn_PickCall").css("display", "none");
    //     whenPickedStartCounting();
    // });

    // socket.on('nexmoCall', function (data) {
    //     $("#hdnCallMediumType").val("NEX");
    //     if (data.response.NexmoCallStatus == "started") {
    //         console.log("Incomming Call");
    //         $("#hid_currentPhoneNumber").val(data.response.callFrom);
    //         $("#lbl_forPhoneNumber").text(((data.response.callerIdName != null) ? data.response.callerIdName + "/" : "") + data.response.callFrom);
    //         $("#hid_channelOfCall").val(data.response.Channel);
    //         $("#ForIvRCall").css("display", "block");
    //         $("#numericPadForCalling").css("display", "none");
    //         $("#div_forCallPickOrReject").css("display", "none");
    //         $("#btn_TransferCall").css("display", "none");
    //         $("#btn_PickCall").css("display", "none");
    //         $("#divForMakeACall").css("display", "none");
    //         $("#hid_currentCall_log_Id").val(data.response.logId);
    //         $("#txt_nameForCall").val(data.response.callerIdName);
    //         $("#lbl_CallTime").text("Calling...");
    //     } else if (data.response.NexmoCallStatus == "answered") {
    //         console.log("Call Start Up");
    //         console.log(data);
    //         clearInterval(setintervalForTimer);
    //         $("#btn_TransferCall").css("display", "none");
    //         $("#btn_PickCall").css("display", "none");
    //         $("#div_forCallPickOrReject").css("display", "block");
    //         $("#lbl_CallTime").text("00:00:00");
    //         whenPickedStartCounting();
    //     } else if (data.response.NexmoCallStatus == "completed") {
    //         console.log("Hang Up");
    //         var jsonObject = data.response.logId;
    //         console.log(data.response.logId);
    //         $("#hid_currentPhoneNumber").val("");
    //         $("#div_forCallPickOrReject").css("display", "none");
    //         $("#ForIvRCall").css("display", "none");
    //         $("#div_whenCallTransfer").css("display", "none");
    //         clearInterval(setintervalForTimer);
    //     }
    // });
    // socket.on('VonageCall', function (data) {
    //     $("#hdnCallMediumType").val("VON");
    //     console.log("Call Event");
    //     if (data.response.VonageCallStatus == "RINGING") {
    //         console.log("Incomming Call");
    //         $("#hid_currentPhoneNumber").val(data.response.callFrom);
    //         $("#lbl_forPhoneNumber").text(((data.response.callerIdName != null && data.response.callerIdName != "") ? data.response.callerIdName + " / " : "") + data.response.callTo);
    //         //$("#hid_channelOfCall").val(data.response.Channel);
    //         $("#ForIvRCall").css("display", "block");
    //         $("#numericPadForCalling").css("display", "none");
    //         $("#div_forCallPickOrReject").css("display", "none");
    //         $("#btn_TransferCall").css("display", "none");
    //         if (data.response.CallType == "Incomming") {
    //             $("#btn_PickCall").css("display", "none");
    //         } else {
    //             $("#btn_PickCall").css("display", "none");
    //         }
    //         $("#divForMakeACall").css("display", "none");
    //         $("#hid_currentCall_log_Id").val(data.response.logId);
    //         $("#txt_nameForCall").val(data.response.callerIdName);
    //         $("#lbl_CallTime").text("Calling...");
    //     }
    //     else if (data.response.VonageCallStatus == "ACTIVE") {
    //         console.log("Call Start Up");
    //         clearInterval(setintervalForTimer);
    //         console.log(data);
    //         $("#btn_TransferCall").css("display", "none");
    //         $("#btn_PickCall").css("display", "none");
    //         $("#div_forCallPickOrReject").css("display", "block");
    //         $("#lbl_CallTime").text("00:00:00");
    //         whenPickedStartCounting();
    //     }
    //     else if (data.response.VonageCallStatus == "ANSWERED") {
    //         console.log("Hang Up");
    //         var jsonObject = data.response.logId;
    //         $("#hid_currentPhoneNumber").val("");
    //         $("#div_forCallPickOrReject").css("display", "none");
    //         $("#ForIvRCall").css("display", "none");
    //         $("#div_whenCallTransfer").css("display", "none");
    //         clearInterval(setintervalForTimer);
    //     }
    // });
    function replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    function notifyMeChat(data) {
        $.notifyClose();
        var vm = this;
        var body = "You have received a new Message (" + data + ")";
        var icon = '';
        var title = vm.$t("TALYGEN") + ":" + vm.$t("UnifiedCommunication");
        var duration = 10000;
        if (!("Notification" in window)) {
            alert("This browser does not support desktop notification");
        } else if (Notification.permission === "granted") {
            displayNotificationChat(body, icon, title, duration, data);
        } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(function (permission) {
                if (permission === "granted") {
                    displayNotificationChat(body, icon, title, duration, data);
                }
            });
        }
    }

    function notifyMe(data, type) {
        // Let's check if the browser supports notifications
        var body = "You have received a video call from " + replaceAll(data.fromName, "%20", " ");
        var vm = this;
        var icon = data.fromUserPath;
        var title = vm.$t("TALYGEN") + ":" + vm.$t("UnifiedCommunication");
        var linkAccept = "joinVideoCall";
        var duration = 50000;

        if (!("Notification" in window)) {
            alert("This browser does not support desktop notification");
        }
        // Let's check whether notification permissions have already been granted
        else if (Notification.permission === "granted") {
            // If it's okay let's create a notification
            // var notification = new Notification($(body));
            displayNotification(body, icon, title, linkAccept, duration, data, type);
        }

        // Otherwise, we need to ask the user for permission
        else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(function (permission) {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    displayNotification(body, icon, title, linkAccept, duration, data, type);
                }
            });
        }

        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them any more.
    }

    function displayNotification(body, icon, title, linkAccept, duration, data, type) {
        duration = duration || 10000; // Default duration is 5 seconds
        var open_window1 = 0;

        var options = {
            icon: icon,
            body: body
        };

        var n = new Notification(title, options);

        if (linkAccept) {
            console.log("html5 click");
        };
        n.onclick = function () {
            var isMobile = window.orientation > -1;
            if (isMobile == true) {
                localStorage.removeItem('clientOpen');
                chatlogwindow.close();
            }

            var stateClient = localStorage.getItem('clientOpen');

            if (stateClient == "1") {
                var newparam = new Object();
                if (type == "talygen") {
                    newparam.room = data.roomId + '~' + data.actRoom + '~' + data.actRoomId;
                    newparam.receiver = data.fromUserId;
                    newparam.contactname = data.fromName;
                    newparam.id = user.userId;
                    socket.emit('add video on chat', newparam);
                } else {
                    newparam.path = data.actRoomId + '~' + data.actRoom + '~' + data.data.sessionId + '~' + data.data.apiKey,
                        newparam.receiver = receiverId_accept;
                    newparam.contactname = contactname_accept;
                    newparam.id = user.userId;
                    socket.emit('add video on chat tokBox', newparam);
                }
                n.close();
                $('#' + data.fromUserId + ".vca_mainwrapper").remove();
            } else {
                if (type == "talygen")
                    callWindow(data.roomId + '~' + data.actRoom + '~' + data.actRoomId, data.fromUserId, data.fromName, type);
                else
                    callWindow(data.actRoomId + '~' + data.actRoom + '~' + data.data.sessionId + '~' + data.data.apiKey, data.fromUserId, data.fromName, type);

                n.close();
                $('#' + data.fromUserId + ".vca_mainwrapper").remove();
            }

            function callWindow(room, receiverId, contactname) {
                if (open_window1 == 0) {
                    open_window1++;
                    if (type == "talygen")
                        var alink1 = "/Communication/Index?reqhFrom=40348&room=" + room + "&rid=" + receiverId + "&name=" + contactname;
                    else
                        var alink1 = "/Communication/Index?reqhFrom=40348&path=" + room + "&rid=" + receiverId + "&name=" + contactname;

                    OpenWindow(alink1, 800, 1200, false);
                }
            }
        }

        setTimeout(n.close.bind(n), duration);
    }

    function displayNotificationChat(body, icon, title, duration, data) {
        duration = duration || 10000; // Default duration is 5 seconds
        var options = {
            body: body
        };
        var n = new Notification(title, options);
        setTimeout(n.close.bind(n), duration);
    }
});
$("body").on("click", ".openleftsubMenu", function (event) {
    event.stopImmediatePropagation();
    $("#hdnischildsearch").val("1");
    ShowChildForLeft($(this));
});
$("body").on("click", ".gotosub", function (event) {
    event.stopImmediatePropagation();
    $("#hdnischildsearch").val("1");
    ShowChild($(this));
});
$("body").on("click", ".menudashboard", function (event) {
    event.stopImmediatePropagation();
    $("#hdnischildsearch").val("1");
    ShowChild($(this));
});
function ShowChildForLeft(ctrl, isSearch, moduleId) {
    var id = ctrl.closest("li").attr("data-id");
    if (moduleId) {
        id = moduleId;
    }
    if (typeof (id) != 'undefined') {
        if (isSearch != true) {
            var dataUrl = ctrl.closest("li").attr("data-url") === undefined ? "javascript:;" : ctrl.closest("li").attr("data-url");
            var dataPrivilege = ctrl.closest("li").attr("data-privilege") === undefined ? "0" : ctrl.closest("li").attr("data-privilege");
            var dataUrlcheck = ctrl.closest("li").attr("data-url");
            if (dataUrl == '/File/Dashboard') {
                $("div.navigation-panel").find('.nav-heading').show().find("#spnAdvancedSetup").html('<a class="cursor-pointer ancbacktomenu mr-3 text-info indl" href="/AdvanceSetup/DocStorage" title="Advanced Setup" data-v-2103ca3e=""><em class="fa fa-cogs text-info indl" data-v-2103ca3e=""></em></a>');
            }
            else if (dataUrl == '/Asset/Dashboard' || dataUrl == '/Dashboard/Asset' ) {
                $("div.navigation-panel").find('.nav-heading').show().find("#spnAdvancedSetup").html('<a class="cursor-pointer ancbacktomenu mr-3 text-info indl" href="/AdvanceSetup/Asset" title="Advanced Setup" data-v-2103ca3e=""><em class="fa fa-cogs text-info indl" data-v-2103ca3e=""></em></a>');
            }
            else if (dataUrl == '/Ticketing/Dashboard' || dataUrl == '/Dashboard/Ticketing') {
                $("div.navigation-panel").find('.nav-heading').show().find("#spnAdvancedSetup").html('<a class="cursor-pointer ancbacktomenu mr-3 text-info indl" href="/AdvanceSetup/Ticketing" title="Advanced Setup" data-v-2103ca3e=""><em class="fa fa-cogs text-info indl" data-v-2103ca3e=""></em></a>');
            }
            else if (dataUrl == '/Dashboard/Timer' || dataUrl == '/Timer/Index') {
                $("div.navigation-panel").find('.nav-heading').show().find("#spnAdvancedSetup").html('<a class="cursor-pointer ancbacktomenu mr-3 text-info indl" href="/AdvanceSetup/Timer" title="Advanced Setup" data-v-2103ca3e=""><em class="fa fa-cogs text-info indl" data-v-2103ca3e=""></em></a>');
            }
            // else if(dataUrl=='/CRM/Dashboard' || dataUrl=='/Dashboard/CRM') //Comment for Live build
            // {
            //     $("div.navigation-panel").find('.nav-heading').show().find("#spnAdvancedSetup").html('<a class="cursor-pointer ancbacktomenu mr-3 text-info indl" href="/CRM/AdvancedSetup" title="Advanced Setup" data-v-2103ca3e=""><em class="fa fa-cogs text-info indl" data-v-2103ca3e=""></em></a>');
            // }
            else {
                $("div.navigation-panel").find('.nav-heading').show().find("#spnAdvancedSetup").html('<span></span>');
            }

            if (dataUrlcheck !== undefined) {
                $("div.navigation-panel").find('.nav-heading').show().find("#spntext").html('<span><em class="fa fa-th-list d-none"></em>' + ctrl.closest("li").attr("data-name") + '<a href="' + dataUrl + '" checkPrivilege="' + dataPrivilege + '" class="text-info indl">|<em class="fa fa-laptop"></em>Dashboard</a></span>');
            }

            if (dataUrlcheck !== undefined && dataUrlcheck != "") {
                $("div.navigation-panel").find('.nav-heading').show().find("#spntext").html('<span><em class="fa fa-th-list d-none"></em>' + ctrl.closest("li").attr("data-name") + '<a href="' + dataUrl + '" checkPrivilege="' + dataPrivilege + '" class="text-info indl">|<em class="fa fa-laptop"></em>Dashboard</a></span>');
            }
            else {

                $("div.navigation-panel").find('.nav-heading').show().find("#spntext").html('<span><em class="fa fa-th-list d-none"></em>' + ctrl.closest("li").attr("data-name") + '</span>');
            }
        }
        $("div.navigation-panel").find('a.mainmenu').closest("li").hide();
        
        var ifactiveExists = $(ctrl).closest("li").hasClass("active");
        $(ctrl).closest("li").closest("ul").find('ul.submenu').addClass('d-none').removeClass('d-flex');
        if (ifactiveExists == false) {
            $(ctrl).closest("li").find('ul.clschild_' + id).addClass('d-flex').removeClass("d-none");
            $(ctrl).closest("li").find('ul.clschild_' + id).find("li").show();
        }
        var topPosition = ((window.screen.height / 2)- 180)
        $(ctrl).closest("li").find('ul.clschild_' + id).css("top", topPosition);
        $(ctrl).closest("li").closest("ul").find('li.dropdown-submenu').removeClass('active');
        if (ifactiveExists == false) {
            $(ctrl).closest("li").addClass('active');
        }
    }
}
function ShowChild(ctrl, isSearch, moduleId) {
    var id = ctrl.closest("li").attr("data-id");
    if (moduleId) {
        id = moduleId;
    }
    if (typeof (id) != 'undefined') {
        if (isSearch != true) {
            var dataUrl = ctrl.closest("li").attr("data-url") === undefined ? "javascript:;" : ctrl.closest("li").attr("data-url");
            var dataPrivilege = ctrl.closest("li").attr("data-privilege") === undefined ? "0" : ctrl.closest("li").attr("data-privilege");
            var dataUrlcheck = ctrl.closest("li").attr("data-url");
            if (dataUrl == '/File/Dashboard') {
                $("div.navigation-panel").find('.nav-heading').show().find("#spnAdvancedSetup").html('<a class="cursor-pointer ancbacktomenu mr-3 text-info indl" href="/AdvanceSetup/DocStorage" title="Advanced Setup" data-v-2103ca3e=""><em class="fa fa-cogs text-info indl" data-v-2103ca3e=""></em></a>');
            }
            else if (dataUrl == '/Asset/Dashboard' || dataUrl == '/Dashboard/Asset' ) {
                $("div.navigation-panel").find('.nav-heading').show().find("#spnAdvancedSetup").html('<a class="cursor-pointer ancbacktomenu mr-3 text-info indl" href="/AdvanceSetup/Asset" title="Advanced Setup" data-v-2103ca3e=""><em class="fa fa-cogs text-info indl" data-v-2103ca3e=""></em></a>');
            }
            else if (dataUrl == '/Ticketing/Dashboard' || dataUrl == '/Dashboard/Ticketing') {
                $("div.navigation-panel").find('.nav-heading').show().find("#spnAdvancedSetup").html('<a class="cursor-pointer ancbacktomenu mr-3 text-info indl" href="/AdvanceSetup/Ticketing" title="Advanced Setup" data-v-2103ca3e=""><em class="fa fa-cogs text-info indl" data-v-2103ca3e=""></em></a>');
            }
            else if (dataUrl == '/Dashboard/Timer' || dataUrl == '/Timer/Index') {
                $("div.navigation-panel").find('.nav-heading').show().find("#spnAdvancedSetup").html('<a class="cursor-pointer ancbacktomenu mr-3 text-info indl" href="/AdvanceSetup/Timer" title="Advanced Setup" data-v-2103ca3e=""><em class="fa fa-cogs text-info indl" data-v-2103ca3e=""></em></a>');
            }
            // else if(dataUrl=='/CRM/Dashboard' || dataUrl=='/Dashboard/CRM') //Comment for Live build
            // {
            //     $("div.navigation-panel").find('.nav-heading').show().find("#spnAdvancedSetup").html('<a class="cursor-pointer ancbacktomenu mr-3 text-info indl" href="/CRM/AdvancedSetup" title="Advanced Setup" data-v-2103ca3e=""><em class="fa fa-cogs text-info indl" data-v-2103ca3e=""></em></a>');
            // }
            else {
                $("div.navigation-panel").find('.nav-heading').show().find("#spnAdvancedSetup").html('<span></span>');
            }

            if (dataUrlcheck !== undefined) {
                $("div.navigation-panel").find('.nav-heading').show().find("#spntext").html('<span><em class="fa fa-th-list d-none"></em>' + ctrl.closest("li").attr("data-name") + '<a href="' + dataUrl + '" checkPrivilege="' + dataPrivilege + '" class="text-info indl">|<em class="fa fa-laptop"></em>Dashboard</a></span>');
            }

            if (dataUrlcheck !== undefined && dataUrlcheck != "") {
                $("div.navigation-panel").find('.nav-heading').show().find("#spntext").html('<span><em class="fa fa-th-list d-none"></em>' + ctrl.closest("li").attr("data-name") + '<a href="' + dataUrl + '" checkPrivilege="' + dataPrivilege + '" class="text-info indl">|<em class="fa fa-laptop"></em>Dashboard</a></span>');
            }
            else {

                $("div.navigation-panel").find('.nav-heading').show().find("#spntext").html('<span><em class="fa fa-th-list d-none"></em>' + ctrl.closest("li").attr("data-name") + '</span>');
            }
        }
        $("div.navigation-panel").find('a.mainmenu').closest("li").hide();
        $("div.navigation-panel").find('ul.submenu').addClass('d-none').removeClass('d-flex');
        $("div.navigation-panel").find('ul.clschild_' + id).addClass('d-flex').removeClass("d-none");
        $("div.navigation-panel").find('ul.clschild_' + id).find("li").show();
        $("div.navigation-panel").find('.hdrGroupName').addClass('d-none');
        $("div.navigation-panel").find('.hdrGroupName_' + id).addClass('d-flex').removeClass("d-none");
    }
}
$("body").on("click", ".ancbacktomenu", function (event) {
    event.stopImmediatePropagation();
    $("#hdnischildsearch").val("0");
    $("div.navigation-panel").find('.nav-heading').hide();
    $("div.navigation-panel").find('.hdrGroupName').addClass('d-none').removeClass("d-flex");
    $("div.navigation-panel").find('ul.submenu').addClass('d-none').removeClass('d-flex');
    $("div.navigation-panel").find('a.mainmenu').closest("li").show();
    $("input.menusearchitem").val('');
    $("#scrollbar").parent().scrollTop(0);
    //SearchMenuItems($("input.menusearchitem").val());
    $("#fullMenuUl").find("ul:not(.submenu)").find("li.menuitem").show();
});



function OpenWindow(query, h, w, scroll) {
    if (isMobile == true) {
        localStorage.removeItem('clientOpen');
    }

    var screenWidth = window.innerWidth * 0.7;
    var screenHeight = window.innerHeight * 0.8;

    var l = (window.innerWidth - screenWidth) / 2;
    var t = (window.innerHeight - screenHeight) / 3;

    var winprops = 'height=' + screenHeight + ',width=' + screenWidth + ',top=' + t + ',resizable=0,status=no,menubar=no,toolbar=no,location=no,addressbar=no,left=' + l;
    if (scroll) winprops += ',scrollbars=1';

    var stateClient = localStorage.getItem('clientOpen'); //window.localStorage.getItem

    if (stateClient == "1") {
        console.log(" localStorage.removeItem('clientOpen');");
        if (window.navigator.userAgent.indexOf("Edge") > -1) // because edge does not have local object global
        {
            chatlogwindow = window.open(query, "_blank", winprops);
        }
    } else {
        if (isMobile == true) {
            chatlogwindow = window.open(query, "_self", winprops);
        } else {
            //   localStorage.setItem('clientOpen', '1');
            chatlogwindow = window.open(query, "_blank", winprops);
        }
    }
}

function SearchMenuItem(val) {
    $("#fullMenuUl ul.submenu").find("li.menuitem a").each(function () {
        val = String(val).toLowerCase();
        var text = String($(this).text()).toLowerCase();
        if (text.includes(val)) {
            $(this).closest("li.menuitem").show();
        } else {
            $(this).closest("li.menuitem").hide();
        }
        $("div.navigation-panel").find('a.mainmenu').closest("li").hide();
        var chk = false;
        $(this).closest('.submenu').find("li.menuitem").each(function () {
            if ($(this).css('display') != 'none') {
                chk = true;
            }
        });
        if (chk) {
            $(this).closest('.submenu').removeClass('d-none').addClass('d-flex');
        } else {
            $(this).closest('.submenu').addClass('d-none').removeClass('d-flex');
        }
    });
    if ($("input.menusearchitem").val() == '' && $("#hdnischildsearch").val("0")) {
        $(".ancbacktomenu").trigger('click');
    }
}


</script>

<style scoped>
.ps {
    max-height: 350px;
}
</style>