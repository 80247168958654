<template>
    <section>
    <div>
        <div class="my-popups" id="choosewizards">
            <div class="modal d-block ">
                <div class="modal-dialog modal-dialog-centered ui-draggable">
                    <div class="modal-content">
                        <div class="modal-header ui-draggable-handle  py-0">
                            <button type="button" class="close" @click=ClosePopup()>
                                <em aria-hidden="true" class="fa fa-times"></em>
                            </button>
                            <h4 name="header" class="modal-title py-3">
                                Wizard
                            </h4>
                        </div>
                        <div class="container-fluid padding-t_10 d-block">
                            <form class="row new_tab">
                                <div class="col-12 mb-4">      
                                 <ul class="shortcuts-menu">
                                    <li class="" v-for="(item, index) in Quicklistdata" :key="index">
                                        <a data-toggle="modal" v-on:click="getForm(item)" :key="item.sub_module_id"  id="adduserwizards"
                                         href="javascript:;" data-dismiss="modal" class="short-menu-item"><span class="left-icon-m">
                                            <i class="fa fa-plus" aria-hidden="true"></i>
                                        </span> <span class="short-item-name"> {{ ($t(item.sub_module_name))}}</span></a>
                                    </li>
                                </ul>
                                </div>
                            </form>     
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- <GetQuickAddLayoutForm v-if="IsManageForm" :getvalues="getvalues" :submodulename="submodulename"></GetQuickAddLayoutForm> -->
</section>
</template>
<script>
//import GetQuickAddLayoutForm from "../HelpAndGuide/GetQuickAddLayoutForm.vue";
// import GetQuickAddLayoutForm from "../Wizard/GetQuickAddLayoutForm.vue";
import DataService from '../../services/DataService';
// import EN from '../../localization/en';
export default {
    // props: {
    //     LayoutListingData: {
    //         type: Array,
    //         required: true
    //     }
    // },
     components: {
        // GetQuickAddLayoutForm,
    },
    // i18n: {
    //     locale: "en",
    //     messages: {
    //         en: EN,
    //     },
    // },
    data() {
        return {
            //isCollapsed: false,

            isLoading: false,
            Quicklistdata: [],
            // moduledata:[],
            // manageform:false,
            //getAlllayoutData:[],
            // selectedModuleId: null,
            // getvalues: {},
            // IsManageForm: false,
            // submodulename:'',
        }
    },
    created() {
        var vm = this;
        vm.getQuickAddData();
    },
    methods: {
        getForm(value) {
            var vm = this;
            // vm.getvalues = value;
            // vm.submodulename= value.sub_module_name;
            // vm.IsManageForm = true;  
            vm.$parent.openDynamicForm(value,value.sub_module_name);
        },
        getQuickAddData() {
            var vm = this;
            DataService.GetQuickAddDataListing().then((response) => {
                vm.Quicklistdata = response.data.data;
            })
        },
        ClosePopup:function(){             
        this.$parent.showWizard=false;  
        },
       
    }

}
</script>
<style>
#choosewizards .modal-header button{    position: absolute; top: 2px; right: 2px;}
#choosewizards .left-icon-m i {
    font-size: 35px;
}

/***************** wizards for-default-theme*******************/
#choosewizards .shortcuts-menu li:nth-child(9n+1) .left-icon-m{border-color:#216bca;}
#choosewizards .shortcuts-menu li:nth-child(9n+2) .left-icon-m{border-color:#63983e;}
#choosewizards .shortcuts-menu li:nth-child(9n+3) .left-icon-m{border-color:#575b6f;}
#choosewizards .shortcuts-menu li:nth-child(9n+4) .left-icon-m{border-color:#43a38a;}
#choosewizards .shortcuts-menu li:nth-child(9n+5) .left-icon-m{border-color:#cc7d38;}
#choosewizards .shortcuts-menu li:nth-child(9n+6) .left-icon-m{border-color:#735da5;}
#choosewizards .shortcuts-menu li:nth-child(9n+7) .left-icon-m{border-color:#7e6c5e;}
#choosewizards .shortcuts-menu li:nth-child(9n+8) .left-icon-m{border-color:#e3644b;}
#choosewizards .shortcuts-menu li:nth-child(9n+9) .left-icon-m{border-color:#dcad3a;}

#choosewizards .shortcuts-menu li:nth-child(9n+1) .left-icon-m i{color:#216bca;}
#choosewizards .shortcuts-menu li:nth-child(9n+2) .left-icon-m i{color:#63983e;}
#choosewizards .shortcuts-menu li:nth-child(9n+3) .left-icon-m i{color:#575b6f;}
#choosewizards .shortcuts-menu li:nth-child(9n+4) .left-icon-m i{color:#43a38a;}
#choosewizards .shortcuts-menu li:nth-child(9n+5) .left-icon-m i{color:#cc7d38;}
#choosewizards .shortcuts-menu li:nth-child(9n+6) .left-icon-m i{color:#735da5;}
#choosewizards .shortcuts-menu li:nth-child(9n+7) .left-icon-m i{color:#7e6c5e;}
#choosewizards .shortcuts-menu li:nth-child(9n+8) .left-icon-m i{color:#e3644b;}
#choosewizards .shortcuts-menu li:nth-child(9n+9) .left-icon-m i{color:#dcad3a;}
/***************** wizards for-default-theme*******************/

</style>