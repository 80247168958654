<script>
  import { storeToRefs } from "pinia";  
  import { useloggedUserInfo} from "./stores/NavUserInfo";
  const currentUrl = window.location.pathname;
  const defaultAppUrl = process.env.VUE_APP_DEFAULT_APP_URL
export default {
  data: function(){
    return {
      currentUrlVue : currentUrl
    }
  },
  setup(){     
    const store = useloggedUserInfo();
		const { GetUserInfo } = storeToRefs(store)
    setTimeout(() => {      
      // $("#divProgress1").css('display','none')
      // $("#talygenfooter").css('display','block')
      if(window.location.pathname=='/'){
        window.location.href='/User/Dashboard';
      }      
    }, 1000);
		return {GetUserInfo }
  }, 
}
</script>
<template>
    <section>
        <TALYGENHeader v-if="GetUserInfo != null && currentUrlVue.toString().toLowerCase() != '/Communication/Index'.toLowerCase() && currentUrlVue.toString().toLowerCase() != '/company/initialsetup'.toLowerCase()" />
    </section>    
</template>
<style>
/* @import "@/assets/base.css"; */
     
@media (min-width: 1024px) {
  body {
    display: flex;
    place-items: center;
  }
}
a{text-decoration:none;}
</style>
