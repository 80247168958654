<template lang="en">
<div class="col-12 p-0">
    <div class="col-md-12 p-0 break-section" v-if="!breakModule">
        <div class="col-md-12 mt-3">
            <h5 class="h5 heading-border">
                <span> {{ $t('BreakDetail')}}</span>
            </h5>
        </div>
        <div class="col-md-12 mt-3">
            
                <div class="hs_tag_general w-100 d-flex dynamic-multiselect" id="divModalTimetrack">
                    
                    <multiselect :disabled="store.getTimer.trackType=='Break' ? true :false" @input="singleBreak" :placeholder="$t('selectBreak')" :multiple="false" class="multiselect p-0 w-100" v-model="singleBreakSelect" :options="store.getBreakTimeList" label="name" track-by="value" :show-labels="false" id="exampleFormControlSelect1">
                        <template slot="option" slot-scope="props">
                                            <div class="option__desc"><span class="option__title">{{
                                                props.option.name }} ({{ props.option.breakDuration}}) <i
                                                :class="props.option.isPaid ? 'fa fa-dollar text-success' : 'fa fa-dollar text-muted'"></i></span> 
                                                  
                                              </div>
                                        </template>
                    </multiselect>
                    <span class="val_msg">{{ singleBreakError? $t('BreakRequired') : '' }}</span>
                    
                </div>
               
                        <div class="col-md-12 mt-4 p-0">
                            <div v-if="store.getTimer.trackType=='Break'" :class="store.$state.breakTimerCountInSec < 0 ? 'danger  col-md-12 p-0' : 'success col-md-12 p-0'">
                                <div id="BreakTimerCanves" class="DivTimer working-timer col-md-6 float-left">
                                    {{store.breakTimerClock}}
                                </div>
                                <div class="col-md-6 float-left pr-0" @click="stopBreak">
                                            <a class="btn btn-danger py-3 font-18 text-uppercase w-100 text-white btn-lg stoptimer"
                                                href="javascript:;" title="" id="btnStop1" data-toggle-tooltip="tooltip"
                                                data-original-title="Stop"><span>{{$t('StopBreak')}}</span></a>
                                </div>
                            </div>
                            <div class="col-md-12 p-0" v-else>
                                <div class="start-break" v-on:click="submitForm">
                                    <a class="start-break-clr py-3 mt-2 font-18 d-block text-uppercase text-center text-white startbreak" id="btnBreakStart" title="" style="cursor: pointer" data-toggle-tooltip="tooltip" data-original-title="Start Break" type="submit">
                                        <i class="fa fa-coffee pr-2"></i><span>{{ $t('StartBreak')}}</span>
                                    </a>
                                </div>
                            </div>
                            
                        </div>
                    
            
        </div>

    </div>

    <div class="my-popups" v-else>
    <div class="modal start-timer cust_timer d-block">
    <div class="modal-dialog modal-dialog-centered" >
        <div class="modal-content" id="DivTimerContent">
            <div class="modal-header">
                <h4 class="modal-title">{{ $t('ChooseBreak')}}</h4>
                <button type="button" class="close" style="background: none;color: #fff !important;" @click="$emit('close',{breakTimer:false,modalClose:false})"><em aria-hidden="true" class="fa fa-times"></em></button>
            </div>
            <div class="modal-body" id="divModalTimetrack">
              
               
                        <div class="col-12 p-0 float-left" id="divModalTimetrack" style="line-height:normal;">
            <!-- <dynamic-form lang="en" :buttons="buttons" ref="form" :schema="FormSchema" v-on:OnSubmit="submit()">
                <template v-slot:tgslot-button="{ data }">

                    </template>
            </dynamic-form> -->
            <label>{{ $t('AddBreak')}}</label>
                <div class="hs_tag_general w-100 d-flex dynamic-multiselect" id="divModalTimetrack">
                    <multiselect :disabled="store.getTimer.trackType=='Break' ? true :false" @input="singleBreak" :placeholder="$t('selectBreak')" :multiple="false" class="multiselect p-0 w-100" v-model="singleBreakSelect" :options="store.getBreakTimeList" label="name" track-by="value" :show-labels="false" id="exampleFormControlSelect1">
                        <template slot="option" slot-scope="props">
                                            <div class="option__desc"><span class="option__title">{{
                                                props.option.name }} ({{ props.option.breakDuration}}) <i
                                                :class="props.option.isPaid ? 'fa fa-dollar text-success' : 'fa fa-dollar text-muted'"></i> </span> 
                                                  
                                              </div>
                                        </template>
                    </multiselect>
                    <span class="val_msg">{{ singleBreakError? $t('BreakRequired') : '' }}</span>
                    
                </div>
                <div class="start-break col-12 float-left p-0 mt-2" v-on:click="submitForm">
                                    <a class="btn btn-primary text-white btn-lg starttimer w-100" id="btnBreakStart" title="" style="cursor: pointer" data-toggle-tooltip="tooltip" data-original-title="Start Break" type="submit">
                                        <i class="fa fa-coffee pr-2"></i><span>{{ $t('Start') }}</span>
                                    </a>

                        </div>
        </div>
            </div>


        </div>
    </div>
    </div>
</div>
<loader :is-visible="isLoading"></loader>
</div>
</template>

<style>

#divModalTimetrack #button label{display: none;}
#divModalTimetrack #button .form-group{margin:0px;}
</style>

<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
    onServerPrefetch
} from '@vue/composition-api'
import Multiselect from 'vue-multiselect'
import {
    userTimerInfo
} from "../../stores/TimeTracker";
import {
    storeToRefs
} from "pinia";
import moment from "moment"
import {
    timeToSeconds,getSecondsFromDate,dateAndTimeZone,dateTimeFromat
} from "@/assets/common/vue-common.js"

export default defineComponent({
    components: {
        Multiselect
    },
    props: {
        userId: {
            type: Number,
            default: "my title",
        },
        timerModule: {
            type: Boolean
        },
        trackDataPouse:{
          type:Object
        }
    },
    data() {
        return {
            singleBreakSelect:'',
            singleBreakError:false,
            isLoading:false
          
        }
    },
    setup(props, context) {
        //if true open components from header timer else open from add timetrack module
        const breakModule = context.attrs.timerModule
        const trackDataProps = context.attrs.trackDataPouse
        const store = userTimerInfo();
        const formResponse = ref()
        const duration = ref()
        const {
            getBreakTimeList,
            getTimerRunResponse,
            getTimer,
            getUserInformation
        } = storeToRefs(store)
        const trackerData = reactive({
            projectId: null,
            trackDescription: "",
            taskId: null,
            isBillable: false,
            format: false,
            date: null,
            trackId: 0,
            startTime: dateAndTimeZone(moment(),dateTimeFromat),
            stopTime: dateAndTimeZone(moment(),dateTimeFromat),
            companyId: null,
            currentDate: dateAndTimeZone(moment(),dateTimeFromat),
            trackAgent: "web",
            timerStatus: "start",
            CreatedBy: getUserInformation.value.id,
            linkedTrackId: null,
            TotalHours: 0,
            timeformat: "12",
            TagIds: null,
            isForcedUpdate: null,
            type: "Break",
            BreakDate: dateAndTimeZone(moment(),dateTimeFromat),
            BreakName: null,
            BreakId: 0,
            BreakStartTime: '',
            BreakStopTime: '',
            Shift_ID: 0,
            SelectedDate: dateAndTimeZone(moment(),dateTimeFromat),
            Break_Shift: ""
        })

        
        const breakTimer = reactive({
            breakTime: ''
        })
        const state = ref({
            submitted: false,
            error:''
        })
        onMounted(() => {
            
        })
        async function submit() {

           
            

        }

        return {
            breakTimer,
            state,
            getBreakTimeList,
            trackerData,
            getTimerRunResponse,
            submit,
            formResponse,
            store,
            duration,
            breakModule,
            getUserInformation

        }
    },

    props: {},
    created: function () {
        
        if (this.store.getTimer.trackType=='Break') {
            this.singleBreakSelect = this.store.getBreakTimeList.find((item)=>item.value==this.store.getTimer.breakId) 
               }
    },
    methods: {
        submitForm:async function () {
            this.singleBreak();
        if(!this.singleBreakError){
            this.isLoading = true
            console.log("this.selectBreak",this.singleBreakSelect)
            this.trackerData.BreakId =  this.singleBreakSelect.value
            this.trackerData.BreakName =  this.singleBreakSelect.name
            
                    const time = this.store.getBreakTimeList.find((data) => data.name == this.singleBreakSelect.name)
                    this.duration = timeToSeconds(time.remainingBreakDuration)
                    this.formResponse = this.singleBreakSelect.value
                    this.trackerData.Shift_ID = this.store.getTimer.shift_ID
                    
                    this.trackerData.trackDescription = this.singleBreakSelect.name
                    this.trackerData.isBillable = this.singleBreakSelect.isPaid
                    this.trackerData.type="Break"
                    await this.store.getBreaksDetails(`BreakId=${ this.trackerData.BreakId}&BreakDate=${dateAndTimeZone(moment(),dateTimeFromat)}`)
                    if(this.store.getbreakDetails.statusCode==0){
            if(this.store.getTimer.statusCode=="ACT"){
                this.store.getTimer.stopTime = dateAndTimeZone(moment(),dateTimeFromat)
                this.store.getTimer.totalHours = this.store.$state.timerCountInSec
                this.store.getTimer.timerStatus ='pause'
                this.store.getTimer.CreatedBy=this.getUserInformation.id
                this.store.getTimer.trackType='TimeTrack'
                this.store.getTimer.type='TimeTrack'
                this.store.getTimer.currentDate= dateAndTimeZone(moment(),dateTimeFromat)
                await this.store.submitProjectInfo(this.store.getTimer, this);
                if(this.store.getTimerRunResponse.status==0){
                this.store.stopTimer()
                }
            //await this.store.getTimerInfo();
            }
            
                    this.trackerData.BreakStartTime = dateAndTimeZone(moment(),dateTimeFromat)
                    this.trackerData.BreakStopTime = dateAndTimeZone(moment(),dateTimeFromat)
                    this.trackerData.BreakDate = dateAndTimeZone(moment(),dateTimeFromat)
            await this.store.submitProjectInfo(this.trackerData, this);
            if(this.store.getTimerRunResponse.status==0){
            await this.store.getTimerInfo();
            if (this.store.getTimer.trackType=='Break') {
                let seconds = getSecondsFromDate({
                        startDate: this.store.getTimer.dateStart,
                        endDate: dateAndTimeZone(moment(),dateTimeFromat)
                    }, 'seconds')
                this.store.$state.breakTimerCountInSec = timeToSeconds(this.store.getTimer.remainingBreakDuration) -seconds
                this.store.breakStartTimer()
                 //breakTimer for timer and breack show in header
                 if(this.breakModule){
                    this.$emit("close",{"breakTimer":true,"modalClose":false})
                 }
            } else {
                this.state.error = this.store.getTimerRunResponse?.userMsg;
            }
        }
        }else{
            this.ShowAlert(this.store.getbreakDetails.mSg, "danger", true, this.$t('Alert'));
        }
        this.isLoading = false
                }
            
        },
        singleBreak(){
            
            if(this.singleBreakSelect){
                this.singleBreakError = false
            }else{
                this.singleBreakError = true
            }
            
        },
        async stopBreak(){
            this.store.getTimer.BreakDate= dateAndTimeZone(moment(),dateTimeFromat)
            this.store.getTimer.BreakStartTime = this.store.getTimer.dateStart
            this.store.getTimer.BreakStopTime = dateAndTimeZone(moment(),dateTimeFromat)
            let seconds = getSecondsFromDate({
                startDate: this.store.getTimer?.dateStart,
                endDate: moment()
            }, 'seconds')
            this.store.getTimer.totalHours = seconds
            this.store.getTimer.timerStatus = 'stop'
            this.store.getTimer.type = 'Break'
            this.store.getTimer.createdBy=this.getUserInformation.id
            this.store.getTimer.currentDate= dateAndTimeZone(moment(),dateTimeFromat)
            await this.store.submitProjectInfo(this.store.getTimer, this)
            if(this.store.getTimerRunResponse.status==0){
            await this.store.getTimerInfo();
            this.store.breakStopTimer()
            this.store.$state.breakTimerCountInSec = 0
            this.store.$state.timerRunResponse = ''
            if(this.store.timerInfo.statusCode=="PAUS" && this.store.timerInfo.trackType=='TimeTrack'){
              this.$emit('breakTopTimerClose','resume')
            }else{
                this.$emit('breakTopTimerClose','stop')
            }
        }else{
            this.ShowAlert(this.store.getbreakDetails.mSg, "danger", true, this.$t('Alert'));
        }
        }

    }

})
</script>
