<template>
    <div>
        <li class="nav-item">
            <a class="nav-link timer-icon d-none forceshow" title="" id="btnTimer" data-toggle-tooltip="tooltip"
                data-original-title="Timer"><i class="fa fa-clock-o m-0" aria-hidden="true"></i><span
                    class="text-lmenu">Timer</span></a>
            <div id="divtimernewdata">

                <!--Record container starts-->

                <div class="timer_wrap show" id="divTopTimer" v-if="store.getTimer.trackType == 'Break'">
                    <div class="timer_desc text-left" style="min-width: 100px;">
                        <div class="desc_txt" id="projectNameTimerTop" :title="state.breakTimerShow"> {{
                    store.getTimer.breakName }}</div>
                        <div id="TimerCanves" class="DivTimer timer_txt">{{ breakTimerClock }}</div>
                    </div>
                    <div class="circle stop" @click="stopBreakTime"><a class="pauseTimer" href="javascript:;" title=""
                            id="btnStop" data-toggle-tooltip="tooltip" data-original-title="Stop"><i
                                class="fa fa-stop"></i></a></div>
                </div>
                <div class="timer_wrap show" id="divTopTimer" v-if="store.getTimer.trackType == 'TimeTrack'"
                    :class="store.getTimer.trackAgent == 'silentapp' ? 'tt_disabled' : ''">
                    <div class="timer_desc text-left" style="min-width: 100px;">
                        <div class="desc_txt" id="projectNameTimerTop"
                            :title="store.getTimer.projectId ? store.getTimer.projectName : store.getTimer.trackDescription"> {{
                    store.getTimer.projectId ? store.getTimer.projectName : store.getTimer.trackDescription }}</div>
                        <div id="TimerCanves" class="DivTimer timer_txt">{{ state.breakTimerShow ? state.breakTimer :
                    store.getTimerClock }}</div>
                    </div>
                    <!-- store.getTimer.trackAgent -->
                    <!-- :class="store.getTimer.enableBreak!='yes' ? '' : 'd-none'" -->
                    <div class="circle pause" :class="store.getTimer.enableBreak != 'yes' ? '' : 'd-none'"
                        @click="pauseTimer" v-if="store.getTimer.statusCode != 'PAUS'"><a class="pausetimer pause" :class="store.getTimer.trackAgent == 'silentapp' ? 'disabled ' : ''" title="Pause"
                            data-toggle-tooltip="tooltip"><i class="fa fa-pause"></i></a></div>
                    <div disabled class="circle pause" :class="store.getTimer.enableBreak != 'yes' ? '' : 'd-none'"
                        @click="resumeTimer" v-else><a class="pausetimer pause" :class="store.getTimer.trackAgent == 'silentapp' ? 'disabled ' : ''" title="Resume"
                            data-toggle-tooltip="tooltip"><i class="fa fa-play"></i></a></div>
                    <div disabled class="circle stop" @click="closeTimer"><a href="javascript:;" :class="store.getTimer.trackAgent == 'silentapp' ? 'disabled ' : ''" title="Stop"><i
                                class="fa fa-stop"></i></a></div>
                    <div :disabled="true" class="circle" @click="editTimerInfo({ modalClose: true })"><a
                            class="updatetimer text-success" :class="store.getTimer.trackAgent == 'silentapp' ? 'disabled ' : ''" href="javascript:;" title="Edit"><i
                                class="fa fa-pencil"></i></a></div>
                    <!-- <div class="circle"><a class="text-default" href="javascript:;" title="" id="btnTimerattachment" data-toggle-tooltip="tooltip" data-original-title="Add Attachment"><i class="fa fa-upload"></i></a></div> -->
                    <div :disabled="true" class="circle " :class="store.getTimer.enableBreak == 'yes' ? '' : 'd-none'"
                        @click="breakTimeClose({ breakTimer: false, modalClose: true })"><a class="pausetimer pause"
                            title="Break" data-toggle-tooltip="tooltip" data-original-title="Pause"><i
                                class="fa fa-clock-o text-blue"></i></a></div>

                </div>

            </div>
        </li>

        <Loader :isVisible="getLoadingStatus"></Loader>
        <TimeTracker v-if="state.showTimetrackerModal" v-on:close="state.showTimetrackerModal = false"
            v-on:openTimer="editTimerInfo" :start="true" :isEdit="true" v-bind:trackDataPouse="trackerData">
        </TimeTracker>
        <!-- Break time call from timer module then alsways timerModule=true -->
        <BreakTime v-if="state.breakTimerOn" v-on:close="breakTimeClose" v-bind:userId="store.getUserInformation.id"
            v-bind:timerModule="true" v-bind:trackDataPouse="trackerData">
        </BreakTime>
    </div>
</template>


<script>
import {
    defineComponent,
    onMounted,
    onUnmounted,
    ref,
    reactive
} from '@vue/composition-api'
import {
    userTimerInfo
} from "../../stores/TimeTracker";
import Loader from "../Loader.vue"
import {
    storeToRefs
} from "pinia";
import moment from "moment"
import {
    formatter,
    getSecondsFromDate,
    trackerDataFormCommen,
    timeToSeconds,
    dateAndTimeZone,
    dateTimeFromat
} from "@/assets/common/vue-common.js"
import TimeTracker from "./TimeTrackerModal.vue";
import BreakTime from "./BreakTime.vue"
export default defineComponent({
    components: {
        Loader,
        TimeTracker,
        BreakTime
    },
    props: {
        breakOrTimerOn: {
            type: Object
        }
    },
    mounted(){
            this.store.stopTimer()
            this.store.getBreakTime();
            if (this.breakOrTimerOn) {
                this.state.breakTimerShow = this.breakOrTimerOn.breakTimer
                this.state.breakTimerPlay = this.breakOrTimerOn.breakTimer
            }
            this.store.$state.timerCountInSec = 0
            this.store.setTimerClock(this.store.$state.timerCountInSec);
            //Check break timer and timer start or not if start then start timer
            if (this.store.getTimer?.statusCode) {
                if (this.store.getTimer?.trackType == 'Break') {
                    this.store.$state.breakNegative = true
                    let seconds = getSecondsFromDate({
                        startDate: this.store.getTimer?.dateStart,
                        endDate: dateAndTimeZone(moment(), dateTimeFromat)
                    }, 'seconds')

                    this.store.$state.breakTimerCountInSec = timeToSeconds(this.store.getTimer.remainingBreakDuration) - seconds
                    this.startBreakTime()
                } else {
                    let countResumeInSeconds = 0
                    this.store.$state.timerCountInSec = moment(new Date(this.store.getTimer?.dateStop), "DD/MM/YYYY HH:mm:ss").format("DD-MM-YYYY") != "01-01-1950" ?
                        countResumeInSeconds + parseInt(this.store.getTimer.resumeHours) :
                        getSecondsFromDate({
                            startDate: this.store.getTimer?.dateStart,
                            endDate: dateAndTimeZone(moment(), dateTimeFromat)
                        }, 'seconds')
                    Object.assign(this.trackerData, this.store.getTimer)
                    if (this.store.getTimer?.statusCode != "ACT") {

                        this.store.setTimerClock(this.store.$state.timerCountInSec);
                        this.trackerData.totalHours = this.store.$state.timerCountInSec
                        this.timerPlay = false
                    } else {
                        this.startTimer();

                    }
                }
            }
    },
    methods: {

        async submitTime(event) {
            this.trackerData.CreatedBy = this.getUserInformation.id
            this.trackerData.createdBy = this.getUserInformation.id
            this.trackerData.trackId = this.store.getTimer?.trackId
            this.trackerData.trackDescription = this.store.getTimer?.trackDescription
            this.trackerData.timerStatus = event.timer,
            this.trackerData.projectId = this.store.getTimer?.projectId
            this.trackerData.taskId = this.store.getTimer?.taskId
            await this.store.submitProjectInfo(this.trackerData, this)
            if (this.store.getTimerRunResponse.userMsg == "TimerStopped") {
                //Object.assign(trackerData, formData.value)
                await this.store.getTimerInfo();
                this.store.stopTimer()
                this.store.$state.timerCountInSec = 0
                this.store.setTimerClock("00:00:00")
            }

            if (this.store.getTimerRunResponse.status == 0) {
                if (event.timer == 'pause') {
                    this.timerPlay = false
                    this.store.stopTimer()
                }
                if (event.timer == 'resume') {
                    this.startTimer();
                }
                if (event.timer == 'stop') {
                    console.log("timer stop")
                    this.store.stopTimer()
                    this.store.$state.timerCountInSec = 0
                    this.store.setTimerClock("00:00:00")

                }

                await this.store.getTimerInfo();

            }

            //store.submitTimer(info);
        },
        startTimer() {
            this.store.startTimer(this.store.$state.timerCountInSec)
            this.timerPlay = true
        },
        resumeTimer() {
            this.trackerData.startTime = dateAndTimeZone(moment(), dateTimeFromat)
            this.trackerData.stopTime = dateAndTimeZone(moment(), dateTimeFromat)
            this.submitTime({
                timer: "resume"
            });

        },
         pauseTimer() {
            this.trackerData.stopTime = dateAndTimeZone(moment(), dateTimeFromat)
            this.trackerData.totalHours = this.store.$state.timerCountInSec
            this.submitTime({
                timer: "pause"
            })
        },
         /* Timer Stop and close timer components 
         */
        closeTimer() {
            /* Clear Interval from store */

            this.trackerData.stopTime = dateAndTimeZone(moment(), dateTimeFromat)
            this.trackerData.totalHours = this.store.$state.timerCountInSec
            this.submitTime({
                timer: "stop"
            })

        },
        /* Start break time */
         startBreakTime() {
            this.state.breakTimerShow = true
            this.state.breakTimerPlay = true
            this.store.breakStartTimer()
        },
        async  stopBreakTime() {
            this.trackerData.CreatedBy = this.getUserInformation.id
            this.trackerData.createdBy = this.getUserInformation.id
            this.trackerData.trackId = this.store.getTimer?.trackId
            this.trackerData.trackDescription = this.getTimer?.trackDescription
            this.trackerData.timerStatus = "stop",
            this.trackerData.type = "Break"
            this.trackerData.BreakStartTime = this.store.getTimer.dateStart
            this.trackerData.BreakStopTime = dateAndTimeZone(moment(), dateTimeFromat)
            let seconds = getSecondsFromDate({
                startDate: this.store.getTimer?.dateStart,
                endDate: dateAndTimeZone(moment(), dateTimeFromat)
            }, 'seconds')
            this.trackerData.totalHours = seconds
            await this.store.submitProjectInfo(this.trackerData, this)
            if (this.store.getTimerRunResponse.status == 0) {
                this.store.breakStopTimer()
                this.state.breakTimerShow = false
                this.store.$state.breakTimerCountInSec = 0
                this.store.$state.timerRunResponse = ''
                Object.assign(this.trackerData, this.formdata)
                await this.store.getTimerInfo();
                if (this.store.getTimer.statusCode == "PAUS" && this.store.getTimer.trackType == 'TimeTrack') {
                    this.store.$state.timerCountInSec = parseInt(this.store.getTimer.resumeHours)
                    Object.assign(this.trackerData, this.store.getTimer)
                    this.trackerData.type = "TimeTrack"

                    setTimeout(() => {
                        this.resumeTimer()
                    }, 1000)

                } else {
                    this.$emit("startTimerClose", {
                        isOpen: false,
                        update: false
                    })
                }
            }

        },
        /* End break time */
         editTimerInfo(event) {
            this.state.showTimetrackerModal = event.modalClose
            if (event.breakTimer) {
                this.state.breakTimerShow = true
            }
        },
      
         breakTimeClose(event) {
            this.state.breakTimerShow = event.breakTimer
            this.state.breakTimerOn = event.modalClose
        }




    },

    setup(props, context) {

        const store = userTimerInfo();
        const {
            getTimer,
            getLoadingStatus,
            getUserInformation,
            getTimerClock,
            getTimerRunResponse,
            breakTimerClock
        } = storeToRefs(store)
        const state = ref({
            breakTimerShow: false,
            breakTimerPlay: false,
            breakTimerInterval: '',
            breakTimeCount: 0,
            breakTimer: "00:00:00",
            showTimetrackerModal: false,
            breakTimerOn: false,
            error: ''

        })
        const timerPlay = ref(true)
        const trackerData = reactive({
            projectId: 0,
            trackDescription: "",
            taskId: 0,
            isBillable: false,
            format: false,
            date: null,
            trackId: 0,
            dateStart: '',
            startTime: dateAndTimeZone(moment(), dateTimeFromat),
            stopTime: dateAndTimeZone(moment(), dateTimeFromat),
            companyId: null,
            currentDate: dateAndTimeZone(moment(), dateTimeFromat),
            trackAgent: "web",
            timerStatus: "",
            CreatedBy: getUserInformation.value.id,
            createdBy: getUserInformation.value.id,
            linkedTrackId: null,
            totalHours: 0,
            timeformat: "12",
            TagIds: null,
            isForcedUpdate: null,
            type: "TimeTrack",
            BreakDate: dateAndTimeZone(moment(), dateTimeFromat),
            BreakName: null,
            BreakId: 0,
            BreakStartTime: 0,
            BreakStopTime: dateAndTimeZone(moment(), dateTimeFromat),
            Shift_ID: 0,
            SelectedDate: "",
            Break_Shift: "",
        })
        const formdata = ref({})
        Object.assign(formdata.value, trackerData)
        


       

       
      
        onUnmounted(() => {
            // submitTime(true)

        })
        

       
       

        // function resumeBreakTime() {

        //     startBreakTime()

        // }

        // function pauseBreakTime() {
        //     state.value.breakTimerPlay = false
        //     clearInterval(state.value.breakTimerInterval)

        // }

       

       
        return {
            timerPlay,
            getLoadingStatus,
            state,
            breakTimerClock,
            getUserInformation,
            trackerData,
            store

        }
    },
})
</script>


<style scoped>
.select2-container {
    z-index: 99999 !important;
}

.timetrackpadding {
    padding: 0 2px 0 10px;
}
</style>
