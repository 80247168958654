<template>
    <div>
     <div class="modal my-popups right fade show" style="display: block;" data-keyboard="false" data-backdrop="static" role="dialog" v-cloak>
         <loader :is-visible="isLoading"></loader>
         <div class="modal-dialog" role="document">
             <div class="modal-content">
                 <div class="modal-header">
                     <button v-on:click="$emit('closenotification')" type="button" class="close" data-dismiss="modal"><em
                             class="fa fa-times" aria-hidden="true"></em></button>
                     <h4 class="modal-title text-truncate" style="line-height:26px;" id="myModalLabel">Notification</h4>
                     <span class="float-right" id="notificationButtons"><a href="javascript:;" title="" id="MarkAllRead" 
                         class=" text-white p-action-btn-noti" v-on:click="MarkAsReadUnread('','','AllNotifications')">{{$t('MarkallasRead')}}</a></span>
                 </div>
                 <div class="modal-body">
                     <section class="tab mt-2">
                         <div class="tab-style">
                             <div id="tabsdata" class="tab_container mb-0">
                                 <ul class="nav nav-tabs all-tabs responsive-tabs cust">
                                     <li class="nav-item">
                                         <a id="st1" data-toggle="tab" href="#step1" class="nav-link active show" v-on:click="GetNotifications('all')">ALL </a>
                                     </li>
                                     <li class="nav-item">
                                         <a id="st2" data-toggle="tab" class="nav-link show" v-on:click="GetNotifications('unread')">UNREAD </a>
                                     </li>
                                 </ul>
                             </div>
 
                             <div class="tab-content set-box-height" style="max-height:750px">
                                 <div id="step1" class="tab-pane tab-border active show">
                                     <div class="container-fluid p-0">
                                         <form class="form-inline justify-flex-end">
 
                                             <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 float-left cstm-border-bottom pt-2 pb-4"
                                                 id="divsearch">
                                                 <div class="row">
                                                     <div class="col-sm-12 col-md-3">
                                                         <label class="form-label justify-content-start">Search With
                                                             keyword:</label>
                                                         <input type="text" placeholder="Search" id="keyword"
                                                             autocomplete="off" name="keyword" v-model="keyword" class="form-control w-100">
                                                     </div>
                                                     <div class="col-sm-12 col-md-3">
                                                         <label class="form-label justify-content-start">Module Name:</label>
                                                         <select class="form-control col-sm-12 col-md-12" v-model="moduleId" name="moduleId">
                                                             <option value="0">All Module</option>
                                                             <option v-for="(item, itemIndex) in moduleList" :key="itemIndex"
                                                                 v-bind:value="item.value">{{ item.text }}</option>
                                                         </select>
 
                                                     </div>
                                                     <div class="col-sm-12 col-md-3">
                                                         <label class="form-label justify-content-start">From({{$t('M/D/YYYY')}}):</label>
                                                         <div class="input-group">
                                                             <v-date-picker mode="date" :minute-increment="5"
                                                                 class="input-group" v-model="fromDate">
                                                                 <template
                                                                     v-slot="{ inputValue, inputEvents, togglePopover }">
                                                                     <input class="form-control" :value="inputValue"
                                                                         v-on="inputEvents" :placeholder="$t('M/D/YYYY')" />
                                                                     <div @click="togglePopover()"
                                                                         class="input-group-append"><span
                                                                             class="input-group-text"><em
                                                                                 class="fa fa-calendar"></em></span></div>
                                                                 </template>
                                                             </v-date-picker>
 
                                                         </div>
                                                     </div>
                                                     <div class="col-sm-12 col-md-3">
                                                         <label class="form-label justify-content-start">To({{$t('M/D/YYYY')}}):</label>
                                                         <div class="input-group">
                                                             <v-date-picker mode="date" :minute-increment="5"
                                                                 class="input-group" v-model="toDate">
                                                                 <template
                                                                     v-slot="{ inputValue, inputEvents, togglePopover }">
                                                                     <input class="form-control" :value="inputValue"
                                                                         v-on="inputEvents" :placeholder="$t('M/D/YYYY')" />
                                                                     <div @click="togglePopover()"
                                                                         class="input-group-append"><span
                                                                             class="input-group-text"><em
                                                                                 class="fa fa-calendar"></em></span></div>
                                                                 </template>
                                                             </v-date-picker>
 
                                                         </div>
                                                     </div>
 
 
 
                                                     <div class="col-12 mt-3">
                                                         <div class="row flex-row-reverse">
                                                             <div class="col-lg-6 text-right">
                                                                 <a id="search" title="" class="btn btn-success mr-1 px-4"
                                                                     data-toggle-tooltip="tooltip"
                                                                     data-original-title="Search" v-on:click="GetNotifications('all')">
                                                                     <em class="fa fa-search text-white"></em>
                                                                 </a>
                                                                 <a id="reset" title="" href="javascript:;"
                                                                     class="btn btn-danger clearsearchtext px-4"
                                                                     data-toggle-tooltip="tooltip"
                                                                     data-original-title="Clear" v-on:click="ResetNotification()">
                                                                     <em class="fa fa-refresh"></em>
                                                                 </a>
                                                             </div>
 
                                                         </div>
                                                     </div>
 
                                                 </div>
                                             </div>
 
 
                                         </form>
                                         <div class="inbox_list notify-list-new">
 
                                             <div class="count" v-if="inboxList.length>0">
                                                 <label>Total Records Found :<span id="searchcount" class="ml-2">{{totalRecords}}</span></label>
                                             </div>  
                                             <div class="listing_notify" v-for="(inbox,inboxIndex) in inboxList" v-bind:key="inboxIndex">
                                                 <h4 :class="{'font-cstm border-bottom mb-0 mt-4 text-light bg-secondary':true,'mt-4':inboxIndex>1}">{{$options.filters.formatDate(inbox.Value[0].PostedDate)}}</h4>
                                                 <div class="notify-container border-cstm pd-cstm pt-0 pb-0">
                                                     <div v-for="(item,itemIndex) in inbox.Value" :key="itemIndex" :class="{'list_rw list-view-more':true,'read bg-read':item.IsRead,'unread bg-unread':!item.IsRead}" :id="item.Id" :type="item.Type">
                                                         <div class="icon colored"><em :class="GetCssClassName(item)"></em></div>
                                                         <div class="detail cstm-width more-details">
                                                             <div v-if="!item.IsRead">
                                                                 <p v-if='item.Type == "LOCATION_LOW_INVENTORY_ALERT" || item.Type == "MIN_STOCK_ALERT"'
                                                                 :class="{'read bg-read':item.IsRead,'unread bg-unread':!item.IsRead}">
                                                                 <a href="javascript:;" class="pop" :id="item.Id" :type="item.Type">
                                                                     {{ item.Title }} {{$t('By')}} {{item.PostedBy}}
                                                                     <span class="timer" v-if="item.PostedDate!=null">
                                                                         {{$options.filters.formatDate(item.PostedDate)}}                                                                       
                                                                     </span>
                                                                 </a>
                                                                 <input type="hidden" Name="value" :value="item.Message" />
                                                             </p>
                                                             <p v-else  :class="{'heavy-font':true,'read bg-read':item.IsRead,'unread bg-unread':!item.IsRead}">
                                                             <p class="pop" :id="item.Id" :type="item.Type">
                                                                 {{ item.Title }} {{$t('By')}} {{item.PostedBy}}
                                                                 <span class="timer" v-if="item.PostedDate!=null">
                                                                     {{$options.filters.formatTime(item.PostedDate)}}
                                                                 </span>
                                                             </p>
                                                             </p>
                                                         </div>
                                                             <div v-else>
                                                                 <p v-if='item.Type == "LOCATION_LOW_INVENTORY_ALERT" || item.Type == "MIN_STOCK_ALERT"'
                                                                 :class="{'heavy-font':true,'read bg-read':item.IsRead,'unread bg-unread':!item.IsRead}">
                                                                 <a href="javascript:;" class="pop" :id="item.Id"
                                                                     :type="item.Type">
                                                                     {{ item.Title }}
                                                                     <span class="timer" v-if="item.PostedDate!=null">
                                                                         {{$options.filters.formatTime(item.PostedDate)}}                                                                       
                                                                     </span>
                                                                 </a>
                                                                 <input type="hidden" Name="value" :value="item.Message" />
                                                             </p>
                                                             <p v-else
                                                                 :class="{'read bg-read':item.IsRead,'unread bg-unread':!item.IsRead}">
                                                                 <p class="pop" :id="item.Id" :type="item.Type"> {{ item.Title }} {{$t('By')}} {{item.PostedBy}}
                                                                     <span class="timer" v-if="item.PostedDate!=null">
                                                                         {{$options.filters.formatTime(item.PostedDate)}}                                                                       
                                                                     </span>
                                                                 </p>
                                                                 <input type="hidden" Name="value" :value="item.Message" />
                                                             </p>
                                                             </div>
                                                             <p v-html="item.Message"></p>
                                                         </div>
                                                         <div class="mobile-action more-action-set">
                                                             <span class="actions mobileaction">
                                                                 <em class="fa fa-ellipsis-h action_icon set-icon-top mt-0" aria-hidden="true"></em>
                                                                 <span class="action-list-box">
                                                                     <span class="list-actions" id="action-list">
                                                                         <a href="javascript:void(0)" class="actions-onclick ViewDetails" :id="item.Id" 
                                                                         :type="item.Type" :title="$t('View')" v-on:click="ViewNotificationDetail(item)">
                                                                             <em class="fa fa-eye text-info action-icon"></em>
                                                                             <span class="text">{{$t('View')}}</span>
                                                                         </a>
                                                                         <a href="javascript:void(0)"
                                                                         :class="{'actions-onclick markAsread':true,'d-none':item.IsRead}" :id="item.Id" :type="item.Type" :category="item.Catageory" :title= "$t('MarkasRead')" value=""
                                                                         v-on:click="MarkAsReadUnread(item.Id,'unread','')">
                                                                             <em class="fa fa-check-square-o text-info action-icon" :title="$t('MarkasRead')" value=""></em>
                                                                             <span class="text">{{$t('MarkasRead')}}</span>
                                                                         </a>
                                                                         <a href="javascript:void(0)"
                                                                         :class="{'actions-onclick markAsUnread':true,'d-none':!item.IsRead}" :id="item.Id" :type="item.Type" :category="item.Catageory" :title= "$t('Unread')" value=""
                                                                         v-on:click="MarkAsReadUnread(item.Id,'read','')">
                                                                             <em class="fa fa-check-square-o text-info action-icon" :title="$t('Unread')" value=""></em>
                                                                             <span class="text">{{$t('Unread')}}</span>
                                                                         </a>
                                                                         <a href="javascript:void(0)" class="actions-onclick deleteById"
                                                                             :id="item.Id" :type="item.Type" :category="item.Catageory" :title="$t('Delete')" value="" v-on:click="DeleteByID(item.Id)">
                                                                             <em class="fa fa-trash text-danger action-icon"></em>
                                                                             <span class="text">{{$t('Delete')}}</span>
                                                                         </a>
                                                                         <em class="fa fa-times close close-action" aria-hidden="true"></em>
                                                                     </span>
                                                                 </span>
                                                             </span>
                                                         </div>
                                                     </div>
                                                 </div>
                                             </div>
                                         <div v-if="inboxList.length==0" class="col-12 text-center text-danger">No record found</div>                                
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </section>
             </div>
         </div>
     </div>
 </div>
 <ViewNotificationDetail v-if="isViewNotification" @close="isViewNotification = false" :id="id" :type="type"></ViewNotificationDetail>
 </div>
 </template>
 <script>
 import DataService from '../../services/DataService';
 import ViewNotificationDetail from '../Notification/NotificationDetail.vue'; 
 export default {
     components: {
          ViewNotificationDetail
     },
     data: function () {
         return {
             isLoading: false,
             moduleList: [],
             moduleId: 0,
             fromDate: null,
             toDate: null,
             inboxList:[],
             keyword:null,
             totalRecords:0,
             isViewNotification:false,
             id:0,
             type:''
         }
     },
     async created() {
         await this.GetNotifications();
        await this.GetModuleList();      
     },
     updated(){
         this.BindActionButtonEvent();
     },
     methods: {
 
         closePopup: function () {
             this.$emit('close');
         },
         GetCssClassName(item){  
                 var css = "newmessage";
                 switch (item.Type) {
 
                     case "Comment":
                         css = "fa fa-comment";
                         break;
                     case "CRM":
                         css = "fa fa-user";
                         break;
                     case "PRJASSIGN":
                         css = "fa fa-folder-open";
                         break;
                     case "TSKASSIGN":
                         css = "fa fa-tasks";
                         break;
                     case "EXPAPPR":
                         css = "fa fa-money";
                         break;
                     case "EXPREJ":
                         css = "fa fa-times-circle-o";
                         break;
                     case "EXPSENTFORCORREC":
                         css = "fa fa-usd";
                         break;
                     case "TIMEAPPR":
                         css = "fa fa-clock-o";
                         break;
                     case "TIMEREJ":
                         css = "fa fa-history";
                         break;
                     case "TIMESHEETAPPR":
                         css = "fa fa-clock-o";
                         break;
                     case "TIMESHEETREJ":
                         css = "fa fa-history";
                         break;
                     case "LEAVEAPPLY":
                         css = "fa fa-user-times";
                         break;
                     case "LEAVEUPDATE":
                         css = "fa fa-user-circle";
                         break;
                     case "LEAVEAPPR":
                         css = "fa fa-user-circle";
                         break;
                     case "LEAVEREJ":
                         css = "fa fa-times-circle-o";
                         break;
                     case "LEAVEAPPLY_APPROVER":
                         css = "fa fa-user-times";
                         break;
                     case "LEAVEUPDATE_APPROVER":
                         css = "fa fa-check-square-o";
                         break;
                     case "TICKET_CREATED":
                         css = "fa fa-ticket";
                         break;
                     case "TICKET_DELETED":
                         css = "fa fa-trash";
                         break;
                     case "TICKET_REPLY":
                         css = "fa fa-reply";
                         break;
                     case "TICKET_TRANSFER":
                         css = "fa fa-code-fork";
                         break;
                     case "TICKET_STATUS_CHANGE":
                         css = "fa fa-exclamation-circle";
                         break;
                     case "FEEDBACK_FROM":
                         css = "fa fa-comments-o";
                         break;
                     case "TSKADDED":
                         css = "fa fa-tasks";
                         break;
                     case "TOTAL_FLAGGED_RECORDS":
                         css = "fa fa-flag";
                         break;
                     default:
                         css = "fa fa-bullhorn";
                         break;
                 }
                 return css;
             
         },
         GetModuleList:async function(){
             var vm = this;
            await DataService.GetModuleList().then(response => {
             vm.moduleList= response.data;
      });
     },
     GetNotifications: async  function(type){
     debugger;
        if(typeof(type)==='undefined'||type===''||type===null){
            type='all'
        }
         var vm = this;
         vm.isLoading=true;
         var isUnreadTabselected = $("#st2").hasClass("active");
         if(type=='unread'){
             isUnreadTabselected=true;
         }
         else if(type=='all'){
             isUnreadTabselected=false;
             $('#st1').addClass('active');
             $('#st2').removeClass('active');
         }
         if (isUnreadTabselected) {                    
             $('#st1').removeClass('active');
             $('#st2').addClass('active');
         }
         var selectedTab = isUnreadTabselected ? 'unread' :'All'
         vm.fromDate=vm.fromDate==null?vm.fromDate :vm.$options.filters.formatDate(vm.fromDate);
         vm.toDate=vm.toDate==null?vm.toDate :vm.$options.filters.formatDate(vm.toDate);
         var url = `dateFrom=${vm.fromDate}&dateTo=${vm.toDate}&keyword=${vm.keyword}&moduleId=${vm.moduleId}&tab=${selectedTab}`;
         await  DataService.GetNotifications(url).then(response => {
         vm.isLoading=false; 
         debugger       
         vm.inboxList= response.data.inboxList;
         vm.totalRecords=response.data.totalRecords;        
      });
     },
     ResetNotification: async  function(){
            var vm = this;
            vm.isLoading=true;
            vm.fromDate=null;
            vm.toDate=null;
            vm.keyword=null;
            vm.moduleId=0;
            var isUnreadTabselected = $("#st2").hasClass("active");
            if (isUnreadTabselected) {                    
                $('#st1').removeClass('active');
                $('#st2').addClass('active');
            }
            var selectedTab = isUnreadTabselected ? 'unread' :'All'
            var url = `dateFrom=&dateTo=&keyword=&moduleId=&tab=${selectedTab}`;
            await DataService.GetNotifications(url).then(response => {
            vm.isLoading=false;
            vm.inboxList= response.data.inboxList;
            vm.totalRecords=response.data.totalRecords;
        })
     },
    async MarkAsReadUnread(id,action,reqFrom){
         var vm = this;
         vm.isLoading=true;
         var url = `id=${id}&action=${action}&reqFrom=${reqFrom}`;
         await DataService.UnreadAllItems(url).then(response=>{
             if(response.data==true){
                 vm.GetNotifications();
             }
             else{
                 vm.isLoading=false;
             }
         })
     },
     async DeleteByID(id){
         var vm = this;
         vm.isLoading=true;
         var url = `id=${id}`;
         await DataService.DeleteByID(url).then(response=>{
             if(response.data==true){
                 vm.GetNotifications();
             }
             else{
                 vm.isLoading=false;
             }
         })
     },
     ViewNotificationDetail(item){
         var vm = this;
         vm.id=item.Id;
         vm.type=item.Type;
         vm.isViewNotification=true;
     }
 }
 }
 </script>
 <style>
 .list_rw .detail p {
     margin-bottom: 0;
 }
 span.action-list-box.is-visible {
     display: inline-block !important;
 }
 </style>