<template>
        <div class="my-popups">
                <!-- The Modal -->
                <div class="modal start-timer cust_timer animated-from-top show" id="DivTourPlay" v-if="DivTourPlay"
                        aria-modal="true" role="dialog" style="display: block;">
                        <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content" id="DivTourPlayContent">
                                        <!-- Modal Header -->
                                        <div class="modal-header">
                                                <h4 class="modal-title text-white">Tour guides</h4>
                                                <button type="button" style="background: none;color: #fff !important;"
                                                        class="close" v-on:click="Close" data-dismiss="modal"><i
                                                                class="fa fa-times" aria-hidden="true"></i></button>
                                        </div>
                                        <!-- Modal body -->
                                        <PerfectScrollbar>
                                        <div class="modal-body" id="divModalTourPlay">
                                                <div class="div_stepcount" v-if="showStepCount"
                                                        style="line-height: 0px; display: inline; padding-bottom: 16px;">
                                                        Step {{ lastStep }}-{{ totalSteps }}</div>
                                                <div class="row defuse-navbar filterscrollAssigned mCustomScrollbar _mCS_5 mCS_no_scrollbar"
                                                        style="max-height:500px">
                                                        <div id="mCSB_5"
                                                                class="mCustomScrollBox mCS-dark-thick mCSB_vertical mCSB_inside"
                                                                style="max-height: none;" tabindex="0">
                                                                <div id="mCSB_5_container"
                                                                        class="mCSB_container mCS_y_hidden mCS_no_scrollbar_y"
                                                                        style="position:relative; top:0; left:0;"
                                                                        dir="ltr">
                                                                        <div class="col-md-12 p-0">
                                                                                <div class="h-tour-guide">                                                                               
                                                                                        <ul id="ul_tourguide_modules" v-if="showTourGuides">
                                                                                                        <li v-for="module in subscribedModules" :key="module.ModuleName">
                                                                                                                <a>{{ module.moduleName }}<i class="fa fa-angle-down rotate-icon" @click="toggleModule(module)"
                                                                                                                                aria-hidden="true" style="float: right;"></i></a>
                                                                                                                <ul v-if="module.showSubmenu && module.tourKeys.length > 0">
                                                                                                                        <PerfectScrollbar>
                                                                                                                                <li v-for="key in module.tourKeys" :key="key.path" v-if="key.path">
                                                                                                                                        <a :href="`/${key.path}?startPageTour=Yes`">{{ key.displayName }}</a>
                                                                                                                                </li>
                                                                                                                        </PerfectScrollbar>
                                                                                                                </ul>
                                                                                                        </li>
                                                                                                </ul> 
                                                                                        <ul id="ul_tourguide" v-if="showUlTourGuides">
                                                                                                <template v-if="tourGuides.items.length > 0">
                                                                                                        <li v-for="item in tourGuides.items" :key="item.step">
                                                                                                        <a href="javascript:void(0)">{{ item.title }}
                                                                                                                <span v-on:click="tourProgress(this, item.tourStep)" class="replay-tour">
                                                                                                                <i :class="item.isLastStep ? 'fa fa-undo' : (item.step <= lastStep ? 'fa fa-undo' : 'fa fa-play-circle font-20 text-primary')"
                                                                                                                        aria-hidden="true"></i>
                                                                                                                </span>
                                                                                                        </a>
                                                                                                        </li>
                                                                                                </template>
                                                                                                <template v-else>
                                                                                                        <li>No Step Found</li>
                                                                                                </template>
                                                                                        </ul>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div id="mCSB_5_scrollbar_vertical"
                                                                        class="mCSB_scrollTools mCSB_5_scrollbar mCS-dark-thick mCSB_scrollTools_vertical">
                                                                        <div class="mCSB_draggerContainer">
                                                                                <div id="mCSB_5_dragger_vertical"
                                                                                        class="mCSB_dragger"
                                                                                        style="position: absolute; min-height: 30px; top: 0px; height: 0px;">
                                                                                        <div class="mCSB_dragger_bar"
                                                                                                style="line-height: 30px;">
                                                                                        </div>
                                                                                </div>
                                                                                <div class="mCSB_draggerRail"></div>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                </PerfectScrollbar>
                                        <div class="modal-footer">
                                                <button type="button" class="btn btn-primary"
                                                        v-on:click="switchTourLayout">More</button>
                                        </div>
                                </div>
                        </div>
                </div>
        </div>
</template>
<style>
/*  div.module-name {
        padding: 10px 14px;
        background: #eee;
    }*/
.mCSB_inside>.mCSB_container {
        margin-right: 8px !important;
}
</style>
<script>
const currentAppRootURL = process.env.VUE_APP_CURRENT_SITE_ROOT_URL;
const defaultAppURL = process.env.VUE_APP_DEFAULT_APP_URL;
const fullMenuRedirectionApplied = process.env.VUE_APP_FULL_MENU_APP_REDIRECTION;
// const currentUrlValue = window.location.pathname.substring(1);
// const pathSegments = currentUrlValue.split('/');
// const currentUrl = pathSegments.slice(0, 2).join('/');
import DataService from '../../services/DataService'
export default {
        props: {
        menuData: {
            type: Array,
            required: true
        },        
    },
        data() {
                return {
                        MenuData: [],
                        MenuDataList: [],
                        DivTourPlay: true,
                        MaxLoop: 5,
                        ModuleName: "",
                        MenuDataGroupBy: [],
                        tourGuideStr: '',
                        MenuCountmodule: 0,
                        TotalCount: 0,
                        TotalMenuCount: 0,
                        notifications: null,
                        showWizard: false,
                        showBookmark: false,
                        CompanyLogo: null,
                        Avatar: null,
                        ProfileUrl: null,
                        UserType: null,
                        showTimetrackerModal: false,
                        startTimerShow: false,
                        searchMenu: '',
                        subscribedModules: [],
                        tourData: tourData,
                        current_Url: '', // Define the current URL if required
                        tourGuides: { items: [] }, // Initialize with an empty array
                        lastStep: 1, // Define lastStep if required
                        totalSteps: 0,
                        showStepCount: false,
                        showTourGuides: false,
                        showUlTourGuides: false,
                        startStep: 0
                };
        },

        created() {
                this.showStepCount = false;
                this.showUlTourGuides = false;
                this.showTourGuides = false;
                if(this.menuData.data.result.DATA.length > 0)
                {
                        this.MenuData = this.menuData;    
                }
                this.GetFULLMenuData();

        },
        methods: {
                returnChildTourKeys: function (k) {
                        return
                },
                Close: function () {
                        this.$parent.tourGuideShow = false;
                },

                async tourProgress(d, step) {
                        debugger
                        const currentUrlValue = window.location.pathname.substring(1);
                        const pathSegments = currentUrlValue.split('/');
                        const currentUrl = pathSegments.slice(0, 2).join('/');
                        var vm = this;
                        if(currentUrlValue=='CRM/InwardListing' && step==5){
                                $('.fa-pencil-square-o').trigger('click');
                        }
                        else if(currentUrlValue=='CRM/index' && step==2 || step==3){
                                $('.fa-pencil-square-o').trigger('click');
                        }
                        else if(currentUrlValue=='CRM/ContractListing' && step==2 || step==3){
                                $('.fa-pencil-square-o').trigger('click');
                        }
                        else if(currentUrlValue=='CRM/ItemListing' && step==2 || step==3){
                                $('.fa-pencil-square-o').trigger('click');
                        }
                        else if(currentUrlValue=='CRM/QuotationListing' && step==2 || step==3){
                                $('.fa-pencil-square-o').trigger('click');
                        }
                        else if(currentUrlValue=='CRM/VendorListing' && step==2 || step==3){
                                $('.fa-pencil-square-o').trigger('click');
                        }
                        else{
                                $('#Close').trigger('click');
                        }
                        vm.DivTourPlay = false;
                        vm.$parent.closePopup();
                        vm.setTourGuideSteps(step, null, currentUrl);
                },

                async GetFULLMenuData() {
                        //debugger
                        try {
                                var vm = this;
                                const currentUrlValue = window.location.pathname.substring(1);
                                const pathSegments = currentUrlValue.split('/');
                                const currentUrl = pathSegments.slice(0, 2).join('/');
                                var params = `url=${currentUrl}`;
                                const result = await DataService.GetWalkThroughViewOrNot(params);
                                vm.startStep = result.data.result == null ? 0 : result.data.result.LastStep - 1;                               
                                if (this.menuData.data.result.DATA && this.menuData.data.result.DATA.length > 0) { 
                                                this.menuData = this.menuData.data.result;
                                                const subscribedModule = {};
                                                let tourGuideStr = '';                                               
                                                const tourDataMap = tourData[0];
                                                this.menuData.DATA.forEach(item => {
                                                        const touritem = tourDataMap[item.CONTROLER_NAME + '/' + item.ACTION_NAME];
                                                        if (touritem && touritem.ModuleDisplayName) {
                                                                if (subscribedModule[touritem.ModuleDisplayName]) {
                                                                subscribedModule[touritem.ModuleDisplayName].tourKeys.push({
                                                                        path: item.CONTROLER_NAME + '/' + item.ACTION_NAME,
                                                                        displayName: touritem.displayName
                                                                });
                                                                } else {
                                                                   subscribedModule[touritem.ModuleDisplayName] = {
                                                                        showSubmenu: false,
                                                                        ModuleName: touritem.ModuleName,
                                                                        displayName: touritem.displayName,
                                                                        moduleName: touritem.ModuleDisplayName,
                                                                        tourKeys: [{ path: item.CONTROLER_NAME + '/' + item.ACTION_NAME, displayName: touritem.displayName }]
                                                                };
                                                        }
                                                   }
                                                });

                                                this.subscribedModules = Object.values(subscribedModule);
                                                const subscribedPaths = this.subscribedModules.flatMap(module => module.tourKeys.map(tourKey => tourKey.path));

                                                if(subscribedPaths.includes(currentUrl))
                                                {
                                                        const tourSteps = JSON.stringify(tourDataMap[currentUrl]);
                                                        const tourGuides = tourSteps ? JSON.parse(tourSteps) : null;
                                                        if (!tourGuides || tourGuides === '') {
                                                                this.showStepCount = false;
                                                                this.showUlTourGuides = true;
                                                        } else {
                                                                const lastStep = this.menuData.result ? (this.menuData.data.LastStep || 1) : 1;
                                                                this.totalSteps = tourGuides.items.length;
                                                                this.lastStep = lastStep;

                                                                const tourGuidesList = tourGuides.items.map(item => {
                                                                        const isLastStep = item.step - 1 <= vm.startStep;
                                                                        return {
                                                                                title: item.title,
                                                                                tourStep: item.step - 1,
                                                                                isLastStep: isLastStep || item.step <= lastStep
                                                                        };
                                                                });
                                                                this.tourGuides = { items: tourGuidesList };
                                                                this.showStepCount = true;
                                                                this.showUlTourGuides = true;
                                                        }
                                                }    
                                                else{
                                                        this.showStepCount = false;
                                                        this.showUlTourGuides = true;
                                                }                                           
                                }

                        } catch (error) {
                                console.error('Error fetching menu data:', error);
                        }
                },


                async switchTourLayout() {
                        //debugger
                        this.showStepCount = false;
                        this.showUlTourGuides = false;
                        this.showTourGuides = true;
                        await this.$nextTick();
                        document.querySelectorAll('#ul_tourguide_modules li.module').forEach(module => {
                                module.addEventListener('click', () => {                                      
                                        const submenu = module.querySelector('ul');
                                        if (submenu && !submenu.classList.contains('show')) {
                                                document.querySelectorAll('#ul_tourguide_modules li.module i').forEach(icon => {
                                                        icon.classList.remove('fa-angle-up');
                                                        icon.classList.add('fa-angle-down');
                                                });
                                                module.querySelector('i').classList.remove('fa-angle-down');
                                                module.querySelector('i').classList.add('fa-angle-up');
                                                submenu.classList.add('show');
                                        } else {
                                                module.querySelector('i').classList.remove('fa-angle-up');
                                                module.querySelector('i').classList.add('fa-angle-down');
                                                submenu.classList.remove('show');
                                        }
                                });
                        });
                },


                async toggleModule(module) {
                        module.showSubmenu = !module.showSubmenu;
                },
        },


};




</script>